define([
  'jquery',
  'underscore',
  'backbone',
  'core/backbone/models/BaseModel',
  'core/backbone/collections/Versions'
], function($, _, Backbone, BaseModel, Versions) {
  return BaseModel.extend({
    getId: function () {
      return this.id;
    },

    parse: function(result) {

      // add summaries for report
      if (result && result.record) {
        return result.record;
      }
      return result;

    },

    fetchVersions: function(options) {
      options || (options = {});
      this.versions = new Versions();
      this.versions.url = this.url() + '/history?page=' + options.page;
      this.versions.fetch(options);
    }
  });
});
