define([
  'jquery',
  'moment',
  'underscore',
  'lodash',
  'vue',
  'vuex',
  'core/store',
  'core/core',
  'core/lib/jquery-ui',
  'core/Knack.Navigation',
  'core/Knack.UI',
  'core/Knack.User',
  'core/backbone/collections/Objects',
  'core/backbone/collections/Scenes',
  'core/backbone/collections/Distributions',
  'core/backbone/models/App',
  'core/backbone/models/User',
  'core/backbone/views/LoginView',
  'core/backbone/views/SupportLoginView',
  'core/backbone/views/MaintenanceView',
  'layout/templates/knack-layout.html',
  'layout_v2/templates/v2/knack-layout-v2.html',
  'core/components/AppHeader/AppHeader.vue',
  'core/ui/helpers/app-header-helper',
  '@knack/rules-helper',
  '@knack/time-helper',
  '@knack/format-helper',
  'core/lib/inactivity_timeout',
  'core/lib/easyxdm/easyXDM.min',
  'core/lib/poll',
  'core/lib/lazyload',
  'core/utility/utility-cookies',
  'highcharts',
  '@knack/style-engine'
], function($, moment, underscore, lodash, Vue, Vuex, $store, core, ui, KnackNavigation, KnackUI, KnackUser, Objects, Scenes, Distributions, App, User, LoginView, SupportLoginView, MaintenanceView, knack_layout, knack_layout_v2, AppHeader, AppHeaderHelper, commonRuleHelper, commonTimeHelper, commonFormatHelper, InactivityTimeout, easyXDM, Poll, LazyLoad, CookieUtil, Highcharts, { generateDesignStylesFromApplicationSchema, convertV2Schema, getDesignSchemaVersion, convertV3SchemaToLegacy, v3DesignSchemaDefault, sanitizeV3DesignSettings }) {

  return Backbone.Model.extend({

    uid: 1, // useful global incrementer

    app: {},
    views: {
    },
    objects: {
    },
    scenes: {
    },
    fields: {
    }, // used to store references to field objects....this is used because table-filters needs a list of fields, but the parent object doesn't have fields that are connected
    models: {
    },
    session: {
    },
    distributions: {
    },
    profiles: [], // not a backbone collection

    router: null, // global router object that's routing the url
    poll: null, // used to check for updates from the server
    user: null, // global user object, tracking logged in user

    parsedClientSubdomainMap: null,

    xdm_sockets_read: [],
    xdm_sockets_write: [],
    xdm_api_call_queue: [],
    xdm_api_call_count: 0,

    initialized: false,

    // app vars
    domain: '',
    api: '',
    application_id: null,
    url_suffix: '', // use for universal var, like preview=1

    // scene vars
    current_scene: null,
    hash: '',
    hash_vars: {
    }, // all the vars in the hash query string, in an object {var:val}.
    query_string: '', // all the vars in the hash query string, in a string.
    hash_scenes: [],
    hash_id: null, // the id in the hash of the current scene
    url_base: '', // the domain and any URL parts before the hash token
    hash_token: '#', // renderer updates this to #! for google
    scene_id: 'slug', // slug or key, builder uses key, Knack uses slug.  Scene object uses this for get()
    el: (window.distribution_key) ? 'knack-' + window.distribution_key : 'knack-dist_1', // the main ID for this app

    mode: 'renderer',
    server: 'node',
    is_embedded: false, // if renderer

    render_cache: {
    },

    modals: [], // render modals
    top_z_index: 2000,

    // config
    config: {
      fields: {
      },
      SHORT_TEXT: 'short_text',
      PARAGRAPH_TEXT: 'paragraph_text',
      BOOLEAN: 'boolean',
      MULTIPLE_CHOICE: 'multiple_choice',
      NUMBER: 'number',
      DATE_TIME: 'date_time',
      IMAGE: 'image',
      FILE: 'file',
      CONNECTION: 'connection',
      ADDRESS: 'address',
      NAME: 'name',
      PHONE: 'phone',
      LINK: 'link',
      EMAIL: 'email',
      RICH_TEXT: 'rich_text',
      CURRENCY: 'currency',
      USER: 'user',
      SUM: 'sum',
      MIN: 'min',
      MAX: 'max',
      AVERAGE: 'average',
      EQUATION: 'equation',
      CONCATENATION: 'concatenation',
      NAME: 'name',
      PASSWORD: 'password',
      VIDEO: 'video',
      AUTO_INCREMENT: 'auto_increment',
      COUNT: 'count',
      SECTION_BREAK: 'section_break',
      TIMER: 'timer',
      RATING: 'rating',
      SIGNATURE: 'signature',
      USER_ROLES: 'user_roles',
      PASSWORD: 'password'
    },
    rule_operators: null,
    sort_operators: null,

    init: function(options) {
      // Show maintenance page and prevent loading the rest of the
      // application if the app is currently being migrated
      // Note: This is only for Live Apps and not embed apps.
      // For embed apps we will check in handleLoadApp() the migration status
      // of the app in the data returned by the initial ajax request in loadApp()
      if (options && options.beingMigrated === 'true') {
        if (options.mode) {
          this.mode = options.mode;
        }
        this.renderMaintenancePage();
        return;
      }

      // Set options
      if (options) {
        this.scene_id = options.scene_id || 'slug';
      }
      if (options && options.application_id) {
        this.application_id = options.application_id;
      }
      if (options && options.mode) {
        this.mode = options.mode;
      }

      if (options && options.router) {

        this.router = options.router;
      }

      // instantiate extensions
      this.Navigation = new KnackNavigation();
      this.UI = new KnackUI();
      this.User = new KnackUser();

      if ($.cookie(`third-party-blocked`)) {

        this.third_party_cookies_blocked = $.cookie(`third-party-blocked`) === `true`
      }

      if (this.mode === 'renderer') {

        // required to work with MooTools (Joomla sites)
        this.$ = $.noConflict(true);
      }

      if (!window.knack_production_mode) {

        window.knack_production_mode = 'production';
      }

      // Set the API url prefix and domain
      this.protocol = 'https://';

      if (window.knack_production_mode === 'development') {

        this.developmentFlags = new URLSearchParams(window.location.search)

        if (!['knackinspector.com', 'knackcrm.com'].some(domain => window.location.href.includes(domain))) {

          this.protocol = 'http://';
        }
      }

      if (!window.api_domain) {

        window.api_domain = 'knack.com';
      }

      this.domain = window.api_domain;

      if (!window.socket_url) {

        window.socket_url = `https://sockets.${this.domain}`;
      }

      this.socket_url = window.socket_url;

      if (!window.cdn_url) {

        window.cdn_url = 'https://cdn1.cloud-database.co';
      }

      this.cdn_url = window.cdn_url;

      if (!window.api_subdomain) {

        window.api_subdomain = 'us-api';
      }

      this.subdomain = window.api_subdomain;

      this.use_multiple_api_subdomains = (_.has(window, `use_multiple_api_subdomains`) && window.use_multiple_api_subdomains === `true`) ? true : false

      this.setAPIURLS();

      log('Knack.api: ' + this.api);
      log('knack appID: ' + this.application_id);

      // Set Config
      this.initConfig();

      // Dispatcher
      this.dispatcher = _.extend({
      }, Backbone.Events);

      this.loadApp();
    },

    setAPIURLS: function() {

      Object.defineProperty(this, `api_url`, {

        get: () => {

          this.switchSubdomain = !this.switchSubdomain

          if (!this.use_multiple_api_subdomains || !this.switchSubdomain) {

            return `${this.protocol}${this.subdomain}.${this.domain}`
          }

          return `${this.protocol}${this.subdomain}1.${this.domain}`
        }
      })

      Object.defineProperty(this, `api`, {

        get: () => {

          if (this.mode === 'dashboard') {

            // we might not know the account yet (dashboard login)
            if (window.account_id) {

              return `${this.api_url}/v1/accounts/${window.account_id}`
            }

            return `${this.api_url}/v1/accounts`
          } else if (this.mode == 'crm') {

            return `${this.api_url}/crm`
          }

          return `${this.api_url}/v1/applications/${this.application_id}`
        }
      })

      Object.defineProperty(this, `api_dev`, {

        get: () => {

          if (this.mode === 'dashboard') {

            if (window.account_id) {

              return `${this.api_url}/v1/accounts/${window.account_id}`
            }

            return `${this.api_url}/v1/accounts`
          }

          return `${this.api_url}/v1`
        }
      })

      this.loader_url = this.protocol + 'loader.' + this.domain;

      log('LOADER URL: ' + this.loader_url);

      this.loader_api = this.api.replace(this.api_url, this.loader_url);

      log('LOADER API: ' + this.loader_api);

      // jobrunner url
      this.job_dev = this.protocol + 'api.' + this.domain + '/v1';

      log('api_url for production_mode: ' + window.knack_production_mode + ' is: ' + this.api_url + '; cdn_url is: ' + this.cdn_url + '; cluster: ' + this.cluster);
    },

    initConfig: function() {
      // group: how they are grouped in the field picker
      // input_type: what input type they have in data>add/edit forms and icons in the form input options (none=not included)
      // Knack.objects: if true it has format options to include in field picker and field editor
      this.config.fields[this.config.SHORT_TEXT] = {
        label: 'Short Text',
        input_type: 'text_field',
        group: 'basic',
        field_type: 'text'
      };
      this.config.fields[this.config.PARAGRAPH_TEXT] = {
        label: 'Paragraph Text',
        input_type: 'text_area',
        group: 'basic',
        field_type: 'text'
      };
      this.config.fields[this.config.BOOLEAN] = {
        label: 'Yes/No',
        input_type: 'boolean',
        group: 'basic',
        format: true
      };
      this.config.fields[this.config.MULTIPLE_CHOICE] = {
        label: 'Multiple Choice',
        input_type: 'combo_box',
        group: 'basic',
        format: true,
        field_type: 'text'
      };
      this.config.fields[this.config.DATE_TIME] = {
        label: 'Date/Time',
        input_type: 'text_field',
        group: 'basic',
        format: true,
        field_type: 'date'
      };
      this.config.fields[this.config.NUMBER] = {
        label: 'Number',
        input_type: 'text_field',
        group: 'basic',
        format: true,
        numeric: true
      };
      this.config.fields[this.config.IMAGE] = {
        label: 'Image',
        input_type: 'upload',
        group: 'basic',
        format: true
      };
      this.config.fields[this.config.FILE] = {
        label: 'File',
        input_type: 'upload',
        group: 'basic'
      };

      this.config.fields[this.config.ADDRESS] = {
        label: 'Address',
        input_type: 'text_area',
        group: 'special',
        field_type: 'text'
      };
      this.config.fields[this.config.NAME] = {
        label: 'Name',
        input_type: 'text_field',
        group: 'special',
        format: true,
        field_type: 'text'
      };
      this.config.fields[this.config.LINK] = {
        label: 'Link',
        input_type: 'text_field',
        group: 'special',
        format: true,
        field_type: 'text'
      };
      this.config.fields[this.config.EMAIL] = {
        label: 'Email',
        input_type: 'text_field',
        group: 'special',
        format: true,
        field_type: 'text'
      };
      this.config.fields[this.config.PHONE] = {
        label: 'Phone',
        input_type: 'text_field',
        group: 'special',
        format: true
      };
      this.config.fields[this.config.RICH_TEXT] = {
        label: 'Rich Text',
        input_type: 'text_area',
        group: 'special',
        field_type: 'text'
      };
      this.config.fields[this.config.CURRENCY] = {
        label: 'Currency',
        input_type: 'text_field',
        group: 'special',
        numeric: true
      };
      this.config.fields[this.config.AUTO_INCREMENT] = {
        label: 'Auto Increment',
        input_type: 'none',
        group: 'special',
        numeric: true
      };
      this.config.fields[this.config.TIMER] = {
        label: 'Timer',
        input_type: 'special',
        group: 'special',
        numeric: true
      };
      this.config.fields[this.config.RATING] = {
        label: 'Rating',
        input_type: 'special',
        group: 'special',
        numeric: true
      };
      this.config.fields[this.config.SIGNATURE] = {
        label: 'Signature',
        input_type: 'special',
        group: 'special',
        numeric: false
      };

      this.config.fields[this.config.SUM] = {
        label: 'Sum',
        input_type: 'none',
        group: 'formula',
        numeric: true
      };
      this.config.fields[this.config.MIN] = {
        label: 'Minimum',
        input_type: 'none',
        group: 'formula',
        numeric: true
      };
      this.config.fields[this.config.MAX] = {
        label: 'Maximum',
        input_type: 'none',
        group: 'formula',
        numeric: true
      };
      this.config.fields[this.config.AVERAGE] = {
        label: 'Average',
        input_type: 'none',
        group: 'formula',
        numeric: true
      };
      this.config.fields[this.config.COUNT] = {
        label: 'Count',
        input_type: 'none',
        group: 'formula',
        numeric: true
      };
      this.config.fields[this.config.EQUATION] = {
        label: 'Equation',
        input_type: 'display',
        group: 'formula',
        numeric: true
      };
      this.config.fields[this.config.CONCATENATION] = {
        label: 'Text Formula',
        input_type: 'none',
        group: 'formula',
        field_type: 'text'
      };

      this.config.fields[this.config.PASSWORD] = {
        label: 'Password',
        input_type: 'password',
        group: 'misc'
      };
      this.config.fields[this.config.CONNECTION] = {
        label: 'Connection',
        input_type: 'text_field',
        group: 'connection'
      };
      this.config.fields[this.config.USER] = {
        label: 'User',
        input_type: 'none',
        group: 'user'
      };
      this.config.fields[this.config.USER_ROLES] = {
        label: 'User Roles',
        input_type: 'user_roles',
        group: 'user'
      };

      this.config.fields[this.config.SECTION_BREAK] = {
        label: 'Section Break',
        input_type: 'section_break',
        group: 'internal'
      };

      var filter_default = '<option>is</option><option>is not</option><option>is blank</option><option>is not blank</option>';
      var filter_text = '<option>contains</option><option>does not contain</option><option>is</option><option>is not</option><option>starts with</option><option>ends with</option><option>is blank</option><option>is not blank</option>';
      this.rule_operators = {
      };
      this.rule_operators[this.config.BOOLEAN] = '<option>is</option><option>is not</option><option>is blank</option><option>is not blank</option>';
      this.rule_operators[this.config.MULTIPLE_CHOICE] = this.rule_operators[this.config.USER_ROLES] = this.rule_operators[this.config.CONNECTION] = '<option>is</option><option>is not</option><option>contains</option><option>does not contain</option><option>is any</option><option>is blank</option><option>is not blank</option>';
      this.rule_operators[this.config.NUMBER] = '<option>is</option><option>is not</option><option>higher than</option><option>lower than</option><option>is blank</option><option>is not blank</option>';
      this.rule_operators[this.config.DATE_TIME] = '<option>is</option><option>is not</option>'
        + '<option>is during the current</option>'
        + '<option>is during the previous</option>'
        + '<option>is during the next</option>'
        + '<option>is before the previous</option>'
        + '<option>is after the next</option>'
        + '<option>is before</option>'
        + '<option>is after</option>'
        + '<option>is today</option>'
        + '<option>is today or before</option>'
        + '<option>is today or after</option>'
        + '<option>is before today</option>'
        + '<option>is after today</option>'
        + '<option>is before current time</option>'
        + '<option>is after current time</option>'
        + '<option>is blank</option>'
        + '<option>is not blank</option>';
      // for date_times that are ignoring date and just storing times
      this.rule_operators.time = '<option>is</option><option>is not</option>'
        + '<option>is before</option>'
        + '<option>is after</option>'
        + '<option>is before current time</option>'
        + '<option>is after current time</option>'
        + '<option>is blank</option>'
        + '<option>is not blank</option>';
      this.rule_operators[this.config.IMAGE] = '<option>is blank</option><option>is not blank</option>';
      this.rule_operators[this.config.FILE] = this.rule_operators[this.config.SIGNATURE] = '<option>is blank</option><option>is not blank</option>';
      this.rule_operators[this.config.LINK] = filter_default;
      this.rule_operators[this.config.PHONE] = filter_text;
      this.rule_operators[this.config.SHORT_TEXT] = this.rule_operators[this.config.PARAGRAPH_TEXT] = this.rule_operators[this.config.RICH_TEXT] = this.rule_operators[this.config.EMAIL] = this.rule_operators[this.config.ADDRESS] = this.rule_operators[this.config.NAME] = this.rule_operators[this.config.CONCATENATION] = filter_text;
      this.rule_operators[this.config.CURRENCY] = this.rule_operators[this.config.NUMBER];
      this.rule_operators[this.config.RATING] = this.rule_operators[this.config.TIMER] = this.rule_operators[this.config.SUM] = this.rule_operators[this.config.MIN] = this.rule_operators[this.config.MAX] = this.rule_operators[this.config.AVERAGE] = this.rule_operators[this.config.EQUATION] = this.rule_operators[this.config.COUNT] = this.rule_operators[this.config.AUTO_INCREMENT] = this.rule_operators[this.config.NUMBER];
      this.rule_operators[this.config.PASSWORD] = '<option>contains</option><option>does not contain</option><option>starts with</option><option>ends with</option>';

      this.validation_operators = {
      };
      this.validation_operators[this.config.BOOLEAN] = this.rule_operators[this.config.BOOLEAN];
      this.validation_operators[this.config.MULTIPLE_CHOICE] = this.rule_operators[this.config.MULTIPLE_CHOICE];
      this.validation_operators[this.config.USER_ROLES] = this.rule_operators[this.config.USER_ROLES];
      this.validation_operators[this.config.CONNECTION] = this.rule_operators[this.config.CONNECTION];
      this.validation_operators[this.config.NUMBER] = this.rule_operators[this.config.NUMBER] + '<option>is one of</option><option>is not one of</option>';
      this.validation_operators[this.config.DATE_TIME] = this.rule_operators[this.config.DATE_TIME] + '<option>is a day of the week</option><option>is between days of the week</option><option>is between dates</option><option>is a future date</option><option>is not a future date</option>';
      this.validation_operators[this.config.IMAGE] = this.rule_operators[this.config.IMAGE] + '<option>size is less than</option><option>size is greater than</option><option>file type is</option><option>file type is not</option>';
      this.validation_operators[this.config.FILE] = this.rule_operators[this.config.FILE] + '<option>size is less than</option><option>size is greater than</option><option>file type is</option><option>file type is not</option>';
      this.validation_operators[this.config.SIGNATURE] = this.rule_operators[this.config.SIGNATURE];
      this.validation_operators[this.config.LINK] = this.rule_operators[this.config.LINK];
      this.validation_operators[this.config.PHONE] = this.rule_operators[this.config.PHONE];
      this.validation_operators[this.config.SHORT_TEXT] = this.rule_operators[this.config.SHORT_TEXT] + '<option>character count is not</option><option>character count is</option><option>character count is higher than</option><option>character count is lower than</option><option>matches regular expression</option><option>does not match regular expression</option>';
      this.validation_operators[this.config.PARAGRAPH_TEXT] = this.rule_operators[this.config.PARAGRAPH_TEXT] + '<option>character count is not</option><option>character count is</option><option>character count is higher than</option><option>character count is lower than</option>';
      this.validation_operators[this.config.RICH_TEXT] = this.rule_operators[this.config.RICH_TEXT] + '<option>character count is not</option><option>character count is</option><option>character count is higher than</option><option>character count is lower than</option>';
      this.validation_operators[this.config.EMAIL] = this.rule_operators[this.config.EMAIL] + '<option>domain must equal</option><option>domain must not equal</option>';
      this.validation_operators[this.config.ADDRESS] = this.rule_operators[this.config.ADDRESS];
      this.validation_operators[this.config.NAME] = this.rule_operators[this.config.NAME];
      this.validation_operators[this.config.CONCATENATION] = this.rule_operators[this.config.CONCATENATION];
      this.validation_operators[this.config.CURRENCY] = this.rule_operators[this.config.CURRENCY] + '<option>is one of</option>';
      this.validation_operators[this.config.RATING] = this.rule_operators[this.config.RATING];
      this.validation_operators[this.config.TIMER] = this.rule_operators[this.config.TIMER];
      this.validation_operators[this.config.SUM] = this.rule_operators[this.config.SUM];
      this.validation_operators[this.config.MIN] = this.rule_operators[this.config.MIN];
      this.validation_operators[this.config.MAX] = this.rule_operators[this.config.MAX];
      this.validation_operators[this.config.AVERAGE] = this.rule_operators[this.config.AVERAGE];
      this.validation_operators[this.config.EQUATION] = this.rule_operators[this.config.EQUATION];
      this.validation_operators[this.config.COUNT] = this.rule_operators[this.config.COUNT];
      this.validation_operators[this.config.AUTO_INCREMENT] = this.rule_operators[this.config.AUTO_INCREMENT];
      this.validation_operators[this.config.PASSWORD] = this.rule_operators[this.config.PASSWORD] + '<option>character count is not</option><option>character count is</option><option>character count is higher than</option><option>character count is lower than</option><option>matches regular expression</option><option>does not match regular expression</option>';
      this.validation_operators.time = this.rule_operators.time;

      var sort_text = '<option value="asc">alphabetically from A to Z</option><option value="desc">alphabetically from Z to A</option>';
      this.sort_operators = {
      };
      this.sort_operators[this.config.BOOLEAN] = '<option value="desc">in order from true to false</option><option value="asc">in order from false to true</option>';
      this.sort_operators[this.config.SIGNATURE] = '<option value="asc">in order from exists to blank</option><option value="desc">in order from blank to exists</option>';
      this.sort_operators[this.config.NUMBER] = '<option value="asc">numerically from low to high</option><option value="desc">numerically from high to low</option>';
      this.sort_operators[this.config.DATE_TIME] = '<option value="desc">chronologically from newest to oldest</option><option value="asc">chronologically from oldest to newest</option>';
      // sort text
      this.sort_operators[this.config.SHORT_TEXT] = this.sort_operators[this.config.MULTIPLE_CHOICE] = this.sort_operators[this.config.USER_ROLES] = this.sort_operators[this.config.IMAGE] = this.sort_operators[this.config.PARAGRAPH_TEXT] = this.sort_operators[this.config.CONNECTION] = this.sort_operators[this.config.FILE] = this.sort_operators[this.config.LINK] = this.sort_operators[this.config.RICH_TEXT] = this.sort_operators[this.config.EMAIL] = this.sort_operators[this.config.ADDRESS] = this.sort_operators[this.config.NAME] = this.sort_operators[this.config.CONCATENATION] = this.sort_operators[this.config.PHONE] = sort_text;
      // sort number
      this.sort_operators[this.config.RATING] = this.sort_operators[this.config.TIMER] = this.sort_operators[this.config.CURRENCY] = this.sort_operators[this.config.AUTO_INCREMENT] = this.sort_operators[this.config.SUM] = this.sort_operators[this.config.MIN] = this.sort_operators[this.config.MAX] = this.sort_operators[this.config.AVERAGE] = this.sort_operators[this.config.EQUATION] = this.sort_operators[this.config.COUNT] = this.sort_operators[this.config.NUMBER];
    },

    showLoginModal: function(logoutOnClose) {

      if ($('#kn-loading-spinner:visible').length == 0) {
        this.modal_login_hideloader = true;
      }

      // hide loader
      $('#kn-loading-spinner').hide();

      var html = '<div id="knack-login" style="padding: 20px; width: 425px; height: 300px"></div>';

      $.fancybox(
        html,
        {
          autoDimensions: true,
          width: 'auto',
          height: 'auto',
          scrolling: 'no',
          transitionIn: 'none',
          transitionOut: 'none',
          onClosed: () => {

            if (logoutOnClose) {

              window.location = '/'
            }
          }
        }
      );

      var view = {
        title: 'Login',
        description: 'Enter your email address and password to login.',
        registration_type: 'closed'
      };

      var view_model = Knack.user;
      view_model.setView(view);
      var view_view = new LoginView({
        model: view_model,
        el: '#knack-login'
      });
      view_view.render();

      $('#knack-login h2').text('Your session has expired').css('margin-bottom', '6px');
      $('#knack-login p').text('Please log in to continue.').css('margin-bottom', '10px');

      $('#fancybox-content').css('border-width', 0)

      // hide loader

      $('#content-wrapper').show();

      // show first input
      $('#email').val(this.user.get('email'));
      $('#password').focus();
    },

    exchangeRefreshTokenForAuthenticationToken: function(callback) {

      const userRefreshToken = this.getUserRefreshToken()

      let RefreshTokenModel = new Backbone.Model();
      RefreshTokenModel.url = `${Knack.api}/refresh-token/verify`

      return RefreshTokenModel.fetch({
        headers: {
          refresh: userRefreshToken
        },
        success: (model, result) => {

          this.user.attributes.token = result.authorizationToken

          return callback()
        },
        error: (model, result) => {

          // remove everything from localStorage and callback, this will result in the subsequent request
          // failing with a 401 error and bringing up the authentication page for the page being requested
          localStorage.removeItem(`refreshToken-user-${Knack.app.id}`)
          localStorage.removeItem(`refreshToken-${Knack.app.id}`)

          return callback()
        }
      });
    },

    getHTTPHeaderParameters: function(params, callback) {

      if (this.application_id) {

        params.headers = {
          'X-Knack-Application-Id': this.application_id,
          'X-Knack-REST-API-Key': this.mode,
          'x-knack-new-builder': true
        };

        const userToken = this.getUserToken()

        if (this.isOnNonKnackDomain() && userToken) {

          params.headers['Authorization'] = `Bearer ${this.user.attributes.token}`
          params.headers[`X-Knack-REST-API-Key`] = `renderer-session`

          return callback(params)
        }

        const userRefreshToken = this.getUserRefreshToken()

        // if we are embedded, have an available refresh token, and are not already calling through to a
        // refresh token route, set ourselves up to retrieve a new authorization token from the server
        if (this.isOnNonKnackDomain() && userRefreshToken && !params.url.includes(`/refresh-token/`)) {

          return this.exchangeRefreshTokenForAuthenticationToken(() => {

            params.headers['Authorization'] = `Bearer ${this.user.attributes.token}`
            params.headers[`X-Knack-REST-API-Key`] = `renderer-session`

            return callback(params)
          })
        }

        return callback(params)
      }

      params.headers = {
        'X-Knack-Application-Id': 'dashboard',
        'X-Knack-REST-API-Key': 'dashboard',
        'x-knack-new-builder': true
      };

      return callback(params)
    },

    initXDM: function() {

      // XDM SETUP
      var _this = this;

      // Needs to keep `function` keyword because `this` is used to refer to either xdm socket caller
      const onXdmSocketMessage = function (message, origin) {

        message = JSON.parse(message);

        // If a callback has been set for this api call
        if (_this.xdm_api_call_queue[message.message_id]) {

          // SUCCESS
          if (message.success) {
            _this.xdm_api_call_queue[message.message_id].success(message.data, message.status, message.xhr);
            _this.xdm_api_call_queue[message.message_id] = null;

          // FAIL
          } else {

            log('XDM calling error...');
            log(arguments);

            // call error function
            _this.xdm_api_call_queue[message.message_id].error(message.data, message.status, message.xhr);

            // if login fail?
            if (_this.mode == 'builder' && message.data.responseText && (message.data.responseText == 'Access Error: Account Expired' || message.data.responseText.indexOf('Knack account is not active. Status') > -1)) {

              // hide loader
              $('#kn-loading-spinner').hide();

              var html = '<h1>Account Issue<a class="close-modal">close</a></h1><div class="wrapper"><p>Uh-oh, your account has expired. Don\'t worry, it\s easy to activate!</p><p><a href="/#billing" style="text-decoration: underline;"><b>View your billing dashboard</b></a> and activate your account.<p></div>';
              _this.renderModal(html, {
                class: 'small'
              });

            } else if (_this.mode == 'builder' && message.data.responseText && message.data.responseText == 'Access Error: No User Registered') {

              // show login
              _this.modal_login = true;
              _this.modal_login_msg = _this.xdm_api_call_queue[message.message_id].msg;
              _this.modal_login_id = message.message_id;

              CookieUtil.checkCookie({
                cookie_key: 'knack-builder',
                success: function() {},
                failure: _this.showLoginModal,
                no_cookie: _this.showLoginModal
              });

              // handle login success
              _this.user.off('login');
              _this.user.on('login', function() {

                _this.handleLogin();

                // kill this message/login
                $.fancybox.close();
                if (_this.modal_login_hideloader) {
                  $('#kn-loading-spinner').hide();
                }

                // call the message again!
                this.postMessage(_this.modal_login_msg);
              });

            } else if (Knack.getProductionMode() === 'development' && message.data.status === 401 && message.data.responseText === 'unauthorized support access') {
              $('body').html('<div id="knack-login" style="padding: 20px; width: 425px; height: 300px"></div>');
              var view_view = new SupportLoginView({
                model: {
                },
                el: '#knack-login'
              });
              view_view.render();

              return;

            // if the app is currently being migrated
            } else if (message.data.status === 503 && message.data.responseText === 'Application unavailable, please try again later') {
              _this.renderMaintenancePage();
              _this.xdm_api_call_queue[message.message_id] = null;

            } else {
              $(document).trigger('knack-error', arguments);
              _this.xdm_api_call_queue[message.message_id] = null;
            }
          }
        }
      }

      // Reset these because there is a chance we call initXDM again (reason unknown, suspected to be embed related)
      this.xdm_sockets_read = []
      this.xdm_sockets_write = []

      const requestMap = {
        GET: `read`,
        PUT: `write`,
        POST: `write`,
        DELETE: `write`
      }

      if (this.parsedClientSubdomainMap) {

        for (const clientSubdomainMapKey in this.parsedClientSubdomainMap) {

          for (clientSubdomain of this.parsedClientSubdomainMap[clientSubdomainMapKey]) {

            this[`xdm_sockets_${clientSubdomainMapKey}`].push(new easyXDM.Socket({

              remote: `${this.protocol}${clientSubdomain}.${this.domain}/api/xdc.html`,
              onMessage: onXdmSocketMessage,
              onReady: function() {},
            }))
          }
        }
      } else {

        let firstSocket = new easyXDM.Socket({
          remote: this.api_url + '/api/xdc.html',
          onMessage: onXdmSocketMessage,
          onReady: function() {},
        })

        this.xdm_sockets_read.push(firstSocket)

        this.xdm_sockets_write.push(firstSocket)

        if (this.use_multiple_api_subdomains) {

          // initialization is the same but api_url will flip flop since it's a property
          let secondSocket = new easyXDM.Socket({
            remote: this.api_url + '/api/xdc.html',
            onMessage: onXdmSocketMessage,
            onReady: function() {},
          })

          this.xdm_sockets_write.push(secondSocket)

          this.xdm_sockets_read.push(secondSocket)
        }
      }

      var methodMap = {
        'create': 'POST',
        'update': 'PUT',
        'delete': 'DELETE',
        'read': 'GET'
      };

      // Helper function to get a URL from a Model or Collection as a property
      // or as a function.
      var getUrl = function(object) {
        if (!(object && object.url)) {
          return null;
        }
        return _.isFunction(object.url) ? object.url() : object.url;
      };

      // Throw an error when a URL is needed, and none is supplied.
      var urlError = function() {
        throw new Error('A "url" property or function must be specified');
      };

      Backbone.sync = function(method, model, options) {
        var type = methodMap[method];

        // Default JSON-request options.
        var params = {
          type: type,
          dataType: 'jsonp'
        };

        if (options.dataType) {
          params.dataType = options.dataType;
          delete options.dataType;
        }

        // Ensure that we have a URL.
        if (!options.url) {
          params.url = getUrl(model) || urlError();
        }

        // Ensure that we have the appropriate request data.
        if (!options.data && model && (method == 'create' || method == 'update')) {
          params.contentType = 'application/json';
          params.data = JSON.stringify(model.toJSON());
        }

        // For older servers, emulate JSON by encoding the request into an HTML-form.
        if (Backbone.emulateJSON) {
          params.contentType = 'application/x-www-form-urlencoded';
          params.data = params.data ? {
            model: params.data
          } : {
          };
        }

        // For older servers, emulate HTTP by mimicking the HTTP method with `_method`
        // And an `X-HTTP-Method-Override` header.
        if (Backbone.emulateHTTP) {
          if (type === 'PUT' || type === 'DELETE') {
            if (Backbone.emulateJSON) {
              params.data._method = type;
            }
            params.type = 'POST';
            params.beforeSend = function(xhr) {
              xhr.setRequestHeader('X-HTTP-Method-Override', type);
            };
          }
        }

        // Don't process data on a non-GET request.
        if (params.type !== 'GET' && !Backbone.emulateJSON) {
          params.processData = false;
        }

        _this.getHTTPHeaderParameters(params, (params) => {

          // this should allow crossdomain cookies?
          params.xhrFields = {
            withCredentials: !_this.isOnNonKnackDomain() || !_this.user || !_this.user.attributes.token
          };

          // Only perform this replacement logic if we received parsedClientSubdomainMap from the server
          // embeds won't have this information for example
          if (_this.parsedClientSubdomainMap) {

            const urlReplacementValue = `${_this.parsedClientSubdomainMap[requestMap[type]][_this.multipleSubdomainFlipper === true ? 1: 0]}`

            if (options.url) {

              options.url = `${_this.protocol}${options.url.replace(options.url.split(`.`)[0], urlReplacementValue)}`
            }

            if (params.url) {

              params.url = `${_this.protocol}${params.url.replace(params.url.split(`.`)[0], urlReplacementValue)}`
            }

            // We only flip this if the app has been flagged to use multiple api subdomains
            if (_this.use_multiple_api_subdomains) {

              _this.multipleSubdomainFlipper = !_this.multipleSubdomainFlipper
            }
          }

          _this.xdm_api_call_count++;

          var msg = JSON.stringify({
            method: method,
           // model: model, // this sometimes create a circular reference error on stringify, so removing to see if makes a difference.
            options: options,
            params: params,
            message_id: _this.xdm_api_call_count
          });

          // Make the request, allowing the user to override any Ajax options.
          _this.xdm_api_call_queue[_this.xdm_api_call_count] = {
            success: options.success,
            error: options.error,
            msg: msg
          };

          const url = (options.url) ? options.url : params.url

          const xdmSocket = _this[`xdm_sockets_${requestMap[type]}`].find((socket) => url.includes(socket.origin)) || _this.xdm_sockets_read[0]

          xdmSocket.postMessage(msg)
        })
      }
    },

    // ================================================================================================================
    // LOAD FUNCTIONS
    // ================================================================================================================
    addLogRocket: function(projectName) {

      if (!this.session || !this.session.user || !window.LogRocket || window.Cypress) {

        return;
      }

      if (Knack.isHIPAA()) {

        return;
      }

      window.LogRocket.init(`knack/${projectName}`);

      window.LogRocket.identify(this.session.user.id, {
        name: this.session.user.first_name + ' ' + this.session.user.last_name,
        email: this.session.user.email,
        plan_id: Knack.account.product_plan ? Knack.account.product_plan.id : ''
      });

      return window.LogRocket.getSessionURL(function(sessionURL) {

        if (!sessionURL) {

          return;
        } else if (typeof Intercom !== 'undefined') {

          Intercom('trackEvent', 'LogRocket', {
            sessionURL: sessionURL
          });
        }
      });
    },

    addSegment: function() {
      if ((typeof analytics === 'undefined') || !this.session.user || !this.session.user.email || !(this.mode === 'builder' || this.mode === 'dashboard') || window.Cypress) {
        return;
      }

      try {

        var plan = this.account.product_plan;

        // we are using the userID to identify all activities since that doesn't ever change.
        analytics.identify(Knack.user.id, {
          email: this.session.user.email,
          name: this.session.user.first_name + ' ' + this.session.user.last_name,
          company: {
            id: this.account.id,
            name: this.account.name,
            plan: plan.name,
            monthly_spend: plan.price,
            plan_records: plan.records,
            plan_apps: plan.apps,
            referral: this.account.referral
          },
          traits: {
            email: this.session.user.email,
            plan: plan.name,
            monthly_spend: plan.price,
            plan_records: plan.records,
            plan_apps: plan.apps,
          }
        });
      } catch (error) {}
    },

    loadApp: function() {

      log('loadApp, loader_api: ' + this.loader_api);

      // Load from server
      if (this.mode === 'dashboard') {

        // load the account for dashboard
        $.ajax({
          context: this,
          success: this.handleLoadAccount,
          dataType: 'jsonp',
          crossDomain: true,
          url: this.loader_api + '/dashboard/schemas'
        });
      } else if (this.mode === 'crm') {

        this.handleLoadApp();
      } else {
        var url = this.loader_api;

        if (this.mode === 'builder') {
          url += '/builder';
        }

        if (window.distribution_key) {
          url += '?isEmbed=true';
        }

        // load the app for renderer/builder
        if (window.app_json) {
          this.handleLoadApp(window.app_json);
        } else {
          $.ajax({
            context: this,
            success: this.handleLoadApp,
            dataType: 'jsonp',
            crossDomain: true,
            url: url
          });
        }
      }
    },

    setKnackPropertiesFromDataPayload: function(data) {

      // Parse application
      var app = {
        name: data.application.name,
        description: data.application.description,
        id: data.application.id,
        users: data.application.users,
        home_scene: data.application.home_scene,
        slug: data.application.slug,
        account: data.application.account,
        layout: data.application.layout,
        status: data.application.status,
        pending_reason: data.application.pending_reason,
        settings: data.application.settings,
        counts: data.application.counts,
        payment_processors: data.application.payment_processors,
        ecommerce: data.application.ecommerce,
        logo_url: data.application.logo_url,
        feature_flags: data.application.feature_flags,
        https_redirect: data.application.https_redirect,
        design_version: getDesignSchemaVersion(data.application),
        showBillingAddressPrompt: data.application.showBillingAddressPrompt
      };

      log(`design_version is::: ${app.design_version}`)

      // cluster!
      if (data.application.subdomain && this.subdomain !== data.application.subdomain) {

        // if the schema sent in a subdomain different from the local, then reset teh URLs to use that
        // embedded apps use this to set cluster subdomains
        this.subdomain = data.application.subdomain;
      }

      // All payloads should have this now, just being safe
      // primarily for deployment purposes
      if (data.application.clientSubdomainMap) {

        // Payload is already parsed json so no need for JSON.parse here
        this.parsedClientSubdomainMap = data.application.clientSubdomainMap
      }

      const hasV3Design = app.design_version === `v3`

      // This is needed to support the v2 Builder. The v3 Builder never calls this file
      if (Knack.mode === 'builder') {

        // Have v3 settings saved, convert to v2 and sync back to app
        if (hasV3Design) {
          // Ensure app design has correct defaults before converting to v2 schema
          app.design = lodash.merge({}, v3DesignSchemaDefault, data.application.design);

          // Sanitize design settings
          app.design = sanitizeV3DesignSettings(app.design);

          // Convert schema from v3 to v2
          const convertedSchema = convertV3SchemaToLegacy(app.design);

          app.design = convertedSchema.design
          app.layout = Object.assign({}, app.layout, convertedSchema.layout)
          app.settings = Object.assign({}, app.settings, convertedSchema.settings)
        }

        if (!app.design) {
          app.design = {}
        }

        // This is to prevent errors when sending app.design to getViewDesignClasses().
        if (!app.design.general) {
          app.design.general = v3DesignSchemaDefault.general
        }
      } else {
        app.design = data.application.design

        // Convert schema from v2 to v3
        if (!hasV3Design) {
          app = convertV2Schema(app);
        }

        // Ensure app design has correct defaults before sanitizing
        app.design = lodash.merge({}, v3DesignSchemaDefault, app.design);

        // Sanitize design settings
        app.design = sanitizeV3DesignSettings(app.design);

        // Reset the design version since we've now converted it
        app.design_version = 'v3';
      }

      this.app = new App(app);
      this.account = app.account;
      this.objects = new Objects(data.application.objects);
      this.scenes = new Scenes(data.application.scenes);
      this.scenes.setProperties(); // for authentication
      this.profiles = data.application.profiles;
      this.distributions = new Distributions(data.application.distributions);

      // distributions
      if (window.distribution_key) {
        var dist = this.distribution = this.distributions.get(window.distribution_key);
        if (dist.get('scene')) {
          var scene = this.scenes.getByKey(dist.get('scene'));
          if (!scene) {
            scene = this.scenes.getBySlug(dist.get('scene'));
          }
          if (scene) {
            this.app.set('home_scene', {
              key: scene.get('key'),
              slug: scene.get('slug')
            });
          }
        }
      }

      // setup user
      this.session = data.application.session;

      this.s3_application_cdn_domain = data.application.s3_application_cdn_domain

      return app
    },

    handleLoadApp: function(data, msg, xhr) {
      // Only relevant for embed apps on page load.
      if (data && data.beingMigrated) {
        this.renderMaintenancePage();
        return;
      }

      // init app data
      if (data) {

        if (data.message && Knack.getProductionMode() === 'development') {
          setTimeout(function() {
            $('body').html('<div id="knack-login" style="padding: 20px; width: 425px; height: 300px"></div>');
            var view_view = new SupportLoginView({
              model: {
              },
              el: '#knack-login'
            });
            view_view.render();
          }, 100);

          return;
        }

        // Create Poller
        this.poll = new Poll();

        let app = this.setKnackPropertiesFromDataPayload(data)

        this.user = new User(this.session.user);
        this.user.on('login', this.handleLogin, this);
        this.user.on('destroy', this.handleLogout, this);

        log('new app is: ');
        log(app);

        const logRocketAppId = (app.settings && app.settings.sql) ? 'builder-sql' : 'knack-builder'

        this.addSegment();
        this.addLogRocket(logRocketAppId);

        // add near filters if settings.geo
        var plan = Knack.account.product_plan;

        if ((app.settings && app.settings.geo) || plan.level > 1 || plan.id === 'trial') {

          this.rule_operators[this.config.ADDRESS] = this.rule_operators[this.config.ADDRESS] + '<option value="near">is near (a zip code)</option>';
        }

        if (Knack.mode === 'builder' && Knack.isHIPAA()) {

          new InactivityTimeout({
            id: this.mode,
            settings: {
              inactivity_timeout: 15,
              inactivity_timeout_enabled: true
            }
          });
        } else if (Knack.mode === 'builder') {

          new InactivityTimeout(app, true);
        } else {

          new InactivityTimeout(app)
        }
      }

      // Add MSIE-specific style
      if (this.isInternetExplorer()) {

        $(`body`).addClass(`msie`)
      }

      this.initXDM();

      if (this.mode === `crm`) {

        const model = new Backbone.Model()

        model.url = `/crm/users`

        model.fetch()
      }

      this.loadJS();
    },

    loadJS: function() {

      if (this.mode == 'renderer' && this.app.get && this.app.get('settings') && this.app.get('settings').language) {

        const translationLanguage = this.app.get('settings').language

        if (translationLanguage.toLowerCase() === `english`) {

          this.loadStyles();

          return
        }

        // root
        var js_root = `${this.cdn_url}/languages/build`;
        var lang_js = `${js_root}/${translationLanguage}_${this.getVersion()}.js`;

        // load
        var _this = this;
        LazyLoad.js([lang_js], function() {

          if (window.knack_translations) {
            _this.translations = window.knack_translations;
          }
          _this.loadStyles();

        });

      } else {

        this.loadStyles();
      }
    },

    getTheme: function () {

      return _.get(this.app.attributes, [`design`, `general`, `theme`], _.get(this.app.attributes, [`layout`, `theme`], `kn-beta`))
    },

    getStyleEngine: function() {
      /**
      * @returns {String} Returns the style engine type of current knack app.
      * Can return 'v1' || 'v2' (should be enum?)
      * This function might not be necessary after refactor of loadStyles
      */

      // ALWAYS return v1 styles/templates for builder
      if (this.mode !== 'renderer') {
        return 'v1';
      }

      // First check for the theme from a v3 schema
      const theme = this.getTheme()

      // If we're using an old theme and no layout is detected
      if (theme === 'flat' || theme === 'basic') {
        return 'v1';
      }

      return 'v2';
    },

    isInternetExplorer: function() {

      // via https://stackoverflow.com/a/24861307/2836568
      return navigator.appName === 'Microsoft Internet Explorer' ||  !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/)) || $.browser.msie
    },

    loadStyles: function() {

      var timestamp = '160905';

      // Load style sheets:
      var css_end = (window.knack_production_mode === 'development') ? '-dev' : '';
      var css_root = `${this.cdn_url}/renderer${css_end}`;

      // The styles everyone needs
      var stylesheets = [
        `${css_root}/css/jquery.fancybox-1.3.4.css`
      ];

      // browser specific style sheet includes
      if (this.isInternetExplorer() && parseInt($.browser.version) < 9) {
        stylesheets.push(css_root + '/css/ie-lt9.css');
      }

      // renderer styles
      if (this.mode === 'renderer') {

        var style_engine = this.getStyleEngine();

        // Grab the proper compiled css ('v1' || 'v2' currently)
        stylesheets.push(`${css_root}/css/${style_engine}/renderer_${this.getVersion()}.${style_engine}.min.css`);

        // Fonts
        //if (this.app.get('settings').icons) {
        stylesheets.push(`${css_root}/css/fonts.css`);
        //}

        var theme = this.getTheme()

        stylesheets.push(`${css_root}/css/${style_engine}/themes/theme-${theme}_${this.getVersion()}.min.css`);

        // Hosted vs. embed
        if (this.distribution && this.distribution.get('mode') === 'embed') {

          // embed style by theme
          this.is_embedded = true;
        } else {

          // hosted style
          if (style_engine === 'v1') {

            stylesheets.push(`${css_root}/css/hosted_${this.getVersion()}.min.css`);
          }
        }

      // non-renderer
      } else if (this.mode === 'dashboard' || this.mode === 'builder' || this.mode === 'crm') {

        css_root = `${this.cdn_url}/builder${css_end}`;

        stylesheets.push(`${css_root}/css/builder_${this.getVersion()}.min.css`);
      }

      // dashboard
      if (this.mode === 'dashboard') {

        css_root = `${this.cdn_url}/dashboard${css_end}`;

        stylesheets.push(`${css_root}/css/dashboard_${this.getVersion()}.min.css`);
      }

      // crm
      if (this.mode === 'crm') {

        css_root = `${this.cdn_url}/crm${css_end}`;

        stylesheets.push(`${css_root}/css/crm_${this.getVersion()}.min.css`);
        stylesheets.push('//netdna.bootstrapcdn.com/bootstrap/3.0.0/css/bootstrap.min.css');
      }

      const disableCustomCss = (!_.isUndefined(Knack.developmentFlags) && Knack.developmentFlags.has(`disableCSS`));

      if (Knack.mode === 'renderer' && Knack.account.settings.load_custom_scripts_from_schema !== true && !disableCustomCss) {

        stylesheets.push(`https://${this.s3_application_cdn_domain}/${this.application_id}/custom/main.css?${Date.now()}`)
      }

      // merge any
      if (window.knack_styles) {

        stylesheets = _.union(stylesheets, window.knack_styles);
      }

      // load
      var _this = this;

      LazyLoad.css(stylesheets, function() {

        // render layout and continue
        if (_this.mode === 'dashboard') {

          _this.renderAccount();
        } else if (_this.mode === 'crm') {

          LazyLoad.js(['//netdna.bootstrapcdn.com/bootstrap/3.0.0/js/bootstrap.min.js'], function() {

            _this.renderApp();
          });

        } else {

          _this.renderApp();
        }
      });
    },

    renderApp: async function() {
      var _this = this;

      const appDesignRegions = _.isEmpty(Knack.app) ? null : _.get(Knack.app.get('design'), ['regions'], null);
      const hasLegacySettings = appDesignRegions ? typeof appDesignRegions.header.legacySettings !== 'undefined' : false;
      const isLegacyHeader = !hasLegacySettings || (appDesignRegions && appDesignRegions.header.isLegacy);

      if (this.mode === 'renderer') {
        // format wrapper ID & class
        var dist_id = window.distribution_key || 'dist_1';

        log('DIST ID: ' + dist_id);

        this.el = 'knack-' + dist_id;

        if ($('#knack-content').length) {
          $('#knack-content').attr('id', this.el);
        }
        this.el = '#' + this.el;
      } else {
        this.el = '#knack-content';
      }
      $(this.el).addClass('kn-content');

      // render layout
      if (Knack.getStyleEngine() === 'v2') {
        $(this.el).html(this.render('knack_layout', knack_layout_v2, {
          isLegacyHeader: _.get(Knack.app.get('design'), ['regions', 'header', 'isLegacy'], true),
        }));
      } else {
        $(this.el).html(this.render('knack_layout', knack_layout));
      }

      // Support Access Tool banner
      if (this.mode === `renderer`) {

        new Vue({
          el: `#kn-support-access-banner`,
          data: {},
          computed: {
            message: {
              get () {

                return $store.getters[`supportAccessTool/message`]
              },
              set (newValue) {

                $store.commit(`supportAccessTool/setMessage`, newValue)
              }
            },
            showBanner () {

              return Knack.getProductionMode() === `development` && Knack.hasCrmUrl()
            }
          },
          methods: {},
          mounted () {

            $store.dispatch(`supportAccessTool/mounted`)
          }
        })
      }

      // Distribution Key
      if (this.distribution) {
        var dist = this.distribution;
        if (dist.get('scene')) {
          var scene = this.scenes.getByKey(dist.get('scene'));
          if (!scene) {
            scene = this.scenes.getBySlug(dist.get('scene'));
          }
          if (scene) {
            this.app.set('home_scene', {
              key: scene.get('key'),
              slug: scene.get('slug')
            });
          }
        }

        if (dist.get('mode') == 'embed') {
          hosted = false;

          const embedDesign = dist.get('design');

           // Fonts setting
           const shouldRenderAppFonts = _.get(embedDesign, ['general', 'fonts', 'show'], true);

           if (shouldRenderAppFonts) {
             $('.kn-content').addClass('kn-content--embedded-with-fonts');
           }

          // Embedded legacy app header design settings
          if (isLegacyHeader) {
            // Header & page menu setting
            const embedHeaderDesign = _.get(embedDesign, ['header', 'display'], 'menu'); // 'menu' | 'menuWithBackgroundColor' | 'fullHeader'

            // Header title/logo area
            if (embedHeaderDesign === 'fullHeader') {
              $('#knack-logo').show();
            } else {
              $('#knack-logo').remove();
            }

            // Header background color
            if (['fullHeader', 'menuWithBackgroundColor'].includes(embedHeaderDesign)) {
              $('.kn-content').addClass('kn-content--embedded-with-header-background');
            }
          }
        } else {

          $('#knack-logo').show();
        }
      } else {

        $('#knack-logo').show();
      }

      // add ID based on class

      // update title and load
      if (this.mode === 'renderer') {
        const hash = location.href.split('#')[1] || '';
        const urlScenes = hash.split('/');
        this.home_slug = urlScenes[0];

        if (isLegacyHeader) {
          // Determines if the user has chosen to display an image or just text for the "logo" area of the header.
          // This is set in the live app design settings in the builder.
          let showLogo = this.app.get('settings').logo;

          if (Knack.app.get('design_version') === 'v3') {
            if (hasLegacySettings) {
              showLogo = appDesignRegions.header.legacySettings.title.show_logo;
            } else {
              showLogo = appDesignRegions.header.title.show_logo;
            }
          }

          if (showLogo && Knack.app.get('logo_url')) {
            $('#knack-logo').addClass('logo-img');
            $('#knack-logo a').html(`<img src="${Knack.app.get('logo_url')}" alt="${Knack.app.get('name')}" style="border: 0" />`);
          } else {
            $('#knack-logo a').text(this.app.get('name'));
          }

          $('#knack-logo a').attr('href', '#' + urlScenes[0]);
        }
      }

      if (!this.is_embedded && !_.isEmpty(this.app)) {

        const app_name = this.app.get('name');
        document.title = (this.mode === 'builder') ? `${app_name} - builder` : app_name;
      }

      // handlers
      $('body').on('click', '.trigger-load', $.proxy(this.handleTriggerLoadForm, this));

      // trigger loaded
      this.trigger('load');

      // any javascript?
      if (this.mode == 'renderer') {

        if (!Knack.developmentFlags || (Knack.developmentFlags && !Knack.developmentFlags.has('disableJS'))) {

          if (this.account.settings.load_custom_scripts_from_schema !== true) {

            // Put selected includes in global scope
            window.$ = $;
            window.Highcharts = Highcharts;
            window.LazyLoad = LazyLoad;
            window.moment = moment;

            var customJsUrl = `https://${this.s3_application_cdn_domain}/${this.app.id}/custom/main.js?${Date.now()}`;

            await new Promise((resolve) => {

              $.getScript(customJsUrl, () => {

                // provide Async function to run after lazyload type stuff
                if (window.KnackInitAsync) {

                  window.KnackInitAsync(this.$, function() {

                    // start up
                    if (!Backbone.History.started) {
                      Backbone.history.start();
                    }

                    _this.initialized = true;

                    resolve()
                  });

                } else {

                  if (window.KnackInit) {
                    window.KnackInit(this.$);
                  }

                  // start up
                  if (!Backbone.History.started) {
                    Backbone.history.start();
                  }

                  this.initialized = true;

                  resolve();
                }
              });
            })

          } else {

            var js = decodeURIComponent(this.app.get('settings').javascript);

            // Invalid JS should not block rest of app from loading.
            try {

              eval(js);
            } catch (error) {

              console.log(`Error evaluating custom code:`, error)
            }
          }

        }
      }

      if (this.mode === 'renderer') {
        const disableCustomCss = (!_.isUndefined(Knack.developmentFlags) && Knack.developmentFlags.has('disableCSS')) || this.account.settings.load_custom_scripts_from_schema !== true;

        // Custom style appending
        generateDesignStylesFromApplicationSchema({
            design: this.app.get(`design`),
            settings: this.app.get(`settings`),
            layout: this.app.get(`layout`)
          }, [], this.mode, disableCustomCss);

        // Set necessary Vuex Store values during app initialization
        $store.commit('setIsEmbedded', this.is_embedded);
        $store.commit('setHomeSlug', this.home_slug);
        $store.commit('setEntryPages', AppHeaderHelper.getEntryPages());
        $store.commit('setUserAccountPages', AppHeaderHelper.getUserAccountPages());

        // Set distribution data in the Vuex store if it's an embedded app
        if (this.distribution && this.distribution.attributes) {
          $store.commit('setDistribution', this.distribution.attributes);
        }

        // Only set user object in Vuex Store if there is one
        if (Knack.user && Knack.user.id) {
          $store.commit('setUser', {
            id: Knack.user.id,
            name: _.get(Knack.user, ['attributes', 'values', 'name'], ''),
            email: _.get(Knack.user, ['attributes', 'values', 'email', 'email'], ''),
          });
        }

        // Create new Vue instance for the app header and render on DOM element
        // only if the active header style is not legacy
        const isLegacyHeader = _.get(Knack.app.get('design'), ['regions', 'header', 'isLegacy'], true);
        if (!isLegacyHeader) {
          new Vue({
            el: '#vue-app-header',
            components: {
              'app-header': AppHeader.default,
            },
            render: function (createElement) {
              return createElement('app-header');
            },
            store: $store,
          });
        }
      }

      // provide Async function to run after lazyload type stuff for schema loaded code
      // leaving this in place in case loading order matters
      if (!this.initialized) {

        if (window.KnackInitAsync) {

          window.KnackInitAsync(this.$, function() {

            // start up
            if (!Backbone.History.started) {
              Backbone.history.start();
            }

            _this.initialized = true;
          });

        } else {

          if (window.KnackInit) {
            window.KnackInit(this.$);
          }

          // start up
          if (!Backbone.History.started) {
            Backbone.history.start();
          }

          this.initialized = true;
        }
      }
    },

    renderAccount: function() {

      // render layout
      $('#knack-content').html(this.render('knack_layout', knack_layout));
      $('#knack-content').addClass('kn-content');

      this.trigger('load');

      // handlers
      $('.trigger-load').live('click', $.proxy(this.handleTriggerLoadForm, this));

      if (!Backbone.History.started) {
        Backbone.history.start();
      }
    },

    renderMaintenancePage: function() {
      let el = '#knack-body';

      // for v2 builder
      if (this.mode === 'builder') {
        el = '#knack-builder';
      }

      // for embed apps
      if (window.distribution_key && this.el) {
        // check if this.el has '#' already since it gets added during app render
        if (this.el.indexOf('#') === 0) {
          el = this.el;
        } else {
          el = `#${this.el}`;
        }
      }

      // Delay rendering the view to ensure the DOM has finished loading
      setTimeout(function() {
        const maintenanceView = new MaintenanceView({ model: {}, el });
        maintenanceView.render();
      });
    },

    handleLoadAccount: function(data) {

      this.initXDM();
      this.loadStyles();
      this.loadAccount(data);
      this.addSegment();
      this.addLogRocket(`knack-dashboard`);
    },

    loadAccount: function(data) {

      if (data && data.message && data.message === 'support user re-authenticate' && Knack.getProductionMode() === 'development') {
        setTimeout(function() {
          var view_view = new SupportLoginView({
            model: {
            },
            el: '#knack-login'
          });
          view_view.render();
        }, 100);
        return;
      }


      // update api
      if (this.mode == 'dashboard' && !window.account_id) {
        if (data.account.id) {
          this.api += '/' + data.account.id;
          this.api_dev += '/' + data.account.id;
        } else {
          this.api = this.api_url + '/v1/accounts';
          this.api_dev = this.api_url + '/v1/accounts';
        }
      }

      this.account = data.account;
      this.session = data.account.session;
      this.user = new User(this.session.user);

      if (Knack.isHIPAA()) {

        new InactivityTimeout({
          id: this.mode,
          settings: {
            inactivity_timeout: 15,
            inactivity_timeout_enabled: true
          }
        });
      }

      log('loadAccount:'); log(this.account);

      // setup user
      this.user.on('login', this.handleLogin, this);
    },

    // these ensure all the data is in place before views handle them
    handleLogin: function() {

      // this is necessary because in new browsers you aren't actually 'logged out' but the sid cookie is gone so we must refresh upon login
      var last_event_key = (this.mode === 'renderer') ? this.app.id + '-app-last_event' : this.user ? this.user.id + '-user-last_event' : '-dashboard-last_event'

      localStorage.setItem(last_event_key, new Date().getTime())

      // Update Vuex store to reflect logged in user
      if (this.user && this.user.id && this.mode === 'renderer') {
        $store.commit('setUser', {
          id: this.user.id,
          name: _.get(this.user, ['attributes', 'values', 'name'], ''),
          email: _.get(this.user, ['attributes', 'values', 'email', 'email'], ''),
        });
        $store.commit('setEntryPages', AppHeaderHelper.getEntryPages());
        $store.commit('setUserAccountPages', AppHeaderHelper.getUserAccountPages());
      }

      this.session.user = this.user.toJSON()

      if (!this.session.user) {

        this.registerLogin()
      }
    },

    registerLogin: function() {

      if (this.mode != 'dashboard') {
        this.addSegment();
      }
    },

    handleClickLogout: function(event) {
      if (event) {
        event.preventDefault();
      }

      $('#kn-loading-spinner').show();

      localStorage.removeItem(`refreshToken-user-${Knack.app.id}`);
      localStorage.removeItem(`refreshToken-${Knack.app.id}`);

      // Destroy user session
      Knack.user.destroy({
        success: function(model, response) {
          if (response && response.redirect_url) {
            const redirect_url = response.redirect_url + '&return=' + window.location.href;
            window.location = redirect_url;
          }

          // Needed for legacy header
          $('#kn-mobile-menu').removeClass('is-visible');
          $('#menu-show-dropdown').attr('checked', false);
        },
        wait: true,
      });
    },

    handleLogout: function() {
      var last_event_key = (this.mode === 'renderer') ? this.app.id + '-app-last_event' : this.user ? this.user.id + '-user-last_event' : '-dashboard-last_event';
      localStorage.removeItem(last_event_key)

      this.session.user = null;
      this.user.clear();
      this.user.id = null;

      // clear cookie previously set if user logged in with "remember me" option checked
      CookieUtil.destroyCookie({
        key: this.app.id + '-remember-me'
      });

      // Update Vuex Store
      if (Knack.mode === 'renderer') {
        $store.dispatch('supportAccessTool/logout');
        $store.commit('setUser', null);
        $store.commit('setEntryPages', AppHeaderHelper.getEntryPages());
        $store.commit('setUserAccountPages', AppHeaderHelper.getUserAccountPages());
      }

      this.user.trigger('logout');
    },

    // ================================================================================================================
    // UTILITY FUNCTIONS
    // ================================================================================================================
    getVersion: function() {
      // Embeds will not have a window.client_sha so we rely
      // on our Jenkins build to replace this with the SHA being released
      if (!window.client_sha) {
        window.client_sha = `{{CLIENT_SHA_PLACEHOLDER}}`;
      }

      return window.client_sha;
    },

    getLogins: function(slug) {

      if (this.mode == 'renderer') {

        // get login scene
        var login_scene;
        var login_view;
        var par = slug;
        while (par != null) {
          var parent = Knack.scenes.getBySlug(par);
          if (parent.get('type') == 'authentication') {
            login_scene = parent;
            break;
          }
          par = parent.get('parent');
        }

        // get view
        if (login_scene) {
          login_scene.views.each(function(view) {
            if (view.get('type') == 'login') {
              login_view = view;
            }
          });
        }

      } else {
        login_scene = new Backbone.Model({
          slug: this.mode
        });
        login_view = '';
      }

      return {
        'scene': login_scene,
        'view': login_view
      };
    },

    hasUsers: function() {
      return this.app.get('users').enabled;
    },

    hasProfiles: function() {
      var profiles = this.objects.filter(function(object) {
        return object.get('profile_key');
      });
      return (profiles.length > 1) ? true : false;
    },

    hasFeature: function(feature) {

      var feature_flags = this.app.get('feature_flags');

      if (!feature_flags || !feature_flags.length) {

        return false;
      }

      return feature_flags.indexOf(feature) !== -1;
    },

    parseQueryVars: function(query_string) {
      var vars = {
      };
      if (!query_string) {
        return vars;
      }
      var var_parts = query_string.split('&');
      _.each(var_parts, function(var_part) {
        var var_part = var_part.split('=');
        if (var_part[0] == 'ref') {
          this.url_ref = var_part[1];
        } else {
          vars[var_part[0]] = var_part[1];
        }
      }, this);

      return vars;
    },

    setHashVars: function() {
      // hash
      hash_token = '#';
      if (location.href.indexOf('#!') > -1) {
        hash_token = '#!';
      }
      this.hash_token = hash_token;
      var hash = location.href.split(hash_token)[1] || this.app.get && this.app.get('home_scene').slug + ((location.href.split('?')[1]) ? '?' + location.href.split('?')[1] : '');
      var url_parts = hash ? hash.split('?') : '';
      var url_hash = url_parts[0];
      if (url_hash && url_hash.charAt(url_hash.length-1) == '/') {
        url_hash = url_hash.slice(0, url_hash.length-1);
      }

      this.url_base = location.href.split(hash_token)[0];

      // hash vars
      this.hash_vars = {
      };
      this.url_ref = '';
      this.query_string = url_parts[1]; // move this below if we need to test var before adding to string, like not including draft=1
      if (this.query_string) {
        this.hash_vars = this.parseQueryVars(this.query_string);
      }

      if (this.hash_vars.snapshot) {
        this.snapshot = true;
      }

      // hash scenes
      if (url_hash) {
        this.setHashScenes(url_hash);
      }
    },

    // return a var located in the has
    setHashScenes: function(url_hash) {

      // knack scenes
      var knack_scenes = {
        'knack-password': true,
        'knack-register': true,
        'ang-account': true,
        'knack-account': true,
        'kn-asset': true
      };

      // add entry scene
      this.hash_scenes = [];
      this.scene_hash = this.hash_token;// + url_parts[0];
      var scene_parts = this.hash_parts = url_hash.split('/');

      if (scene_parts.length) {
        for (var i = 0; i<scene_parts.length; i++) {

          if (scene_parts[i] && !knack_scenes[scene_parts[i]]) {
            var new_scene = {
              'slug': scene_parts[i]
            };

            this.scene_hash += scene_parts[i] + '/';

            // check if next part is an ID or a scene
            if (this.scene_id == 'slug' && scene_parts[i+1] && !this.scenes.get(scene_parts[i+1]) && !knack_scenes[scene_parts[i+1]]) {
              new_scene.key = scene_parts[i+1];
              this.scene_hash += scene_parts[i+1] + '/';
              i++;
            }
            this.hash_scenes.push(new_scene);
          } else if (knack_scenes[scene_parts[i]]) {
            if (scene_parts[i] == 'knack-account') {
              this.scene_hash += 'knack-account/';
            }
            break; // need to break here because url parts follow for certain knack scenes (like registration for profile keys)
          }
        }
        if (new_scene) {
          this.hash_id = new_scene.key;
        }

      } else {

        // get default scene
        this.hash_scenes.push({
          'slug': this.app.get('home_scene').slug
        });
        this.scene_hash += this.app.get('home_scene').slug;
      }
    },

    // save the current hash to the active scenes dom
    // currently for use in restoring the URL when modals close
    setCurrentSceneHash: function() {
      if (this.router.scene_view) {
        // log('getting current_scene: ' + this.router.current_scene);
        this.router.scene_view.setSceneHash();
      }
    },

    // return a var located in the hash query string
    getHashVar: function(which) {
      return this.hash_vars[which];
    },

    getSceneHash: function() {
      return this.scene_hash;
    },

    getUrlSuffix: function() {
      return this.url_suffix;
    },

    // get the current scene in the hash
    getCurrentScene: function() {
      if (this.hash_scenes.length) {
        return this.hash_scenes[Knack.hash_scenes.length-1];
      }
        // get the entry scene
      return this.app.get('home_scene');

    },

    // get all scenes identified in the hash
    getHashScenes: function() {
      return this.hash_scenes;
    },

    // determine if a slug is in the has
    getSceneInHash: function(slug) {

      var in_hash = _.filter(this.hash_scenes, function(scene) {
        return scene.slug == slug;
      });

      return (in_hash.length > 0);

    },

    getQueryString: function(replace_vars, ignore_vars) {
      ignore_vars = ignore_vars || [];
      if (replace_vars) {
        this.hash_vars = _.extend(this.hash_vars, replace_vars);
      }

      // compile string
      var string = '';
      for (var i in this.hash_vars) {
        if (String(ignore_vars).indexOf(i) == -1) {
          if (string) {
            string += '&';
          }
          string += i + '=' + this.hash_vars[i];
        }
      }
      return string;
    },

    // get data from a parent model's collection.  So if you click on a table details that data is potentially available here.
    getSceneData: function() {
      var view_id = this.hash_vars.v;
      var current_scene = this.getCurrentScene();
      this.hash_id = current_scene.key;
      if (this.hash_id && this.models[view_id] && this.models[view_id].data) {
        return this.models[view_id].data.get(this.hash_id);
      }
      return false;

    },

    getPreviousScene: function() {
      return this.getSceneLink(-1);
    },

    getSceneLink: function(back) {
      back || (back = 0);
      var hash_scenes = this.getHashScenes();
      var current_hash = this.hash_token;

      if (this.snapshot) {
        current_hash = this.url_base + current_hash;
      }

      var scene = {
        'link': current_hash,
        'name': '',
        'slug': ''
      };
      if (hash_scenes.length) {
        for (var i=0; i<=hash_scenes.length-1+back; i++) {
          var hash_scene = hash_scenes[i];
          if (i>0) {
            current_hash += '/';
          }
          log(hash_scene);
          current_hash += hash_scene.slug;
          if (hash_scene.key) {
            current_hash += '/' + hash_scene.key;
          }
        }
        if (hash_scene) {
          var scene_name = (Knack.scene_id == 'slug') ? Knack.scenes.get(hash_scene.slug).get('name') : Knack.scenes.getBySlug(hash_scene.slug).get('name');
          scene = {
            'link': current_hash,
            'name': scene_name,
            'slug': hash_scene.slug
          };
        }
      }
      return scene;
    },

    // navigate back one scene (slash)...useful for handling cancel/close/back buttons
    navigateBack: function(step_count, navigate) {
      if (navigate == null) {
        navigate = true;
      }

      this.setHashVars();

      var hash_scenes = this.getHashScenes();
      var current_hash = this.hash_token;
      log('starting hash: ');
      log(hash_scenes);
      if (this.snapshot) {
        current_hash = this.url_base + current_hash;
      }
      for (var i=0; i<hash_scenes.length-step_count; i++) {
        var hash_scene = hash_scenes[i];
        current_hash += hash_scene.slug + '/';
        if (hash_scene.key) {
          current_hash += hash_scene.key + '/';
        }
      }

      current_hash = current_hash.substr(0, current_hash.length-1);
      log('navigating back to : ' + current_hash);
      this.router.navigate(current_hash, navigate);

      log(this.getHashScenes());
    },

    clearOldScene: function() {
      if (this.current_scene) {
        this.current_scene.remove();
        delete this.current_scene;
      }
    },

    setCurrentScene: function(current_scene) {
      this.current_scene = current_scene;
    },

    generateAppLink: function(hash, options) {

      var base = this.scene_hash;

      if (this.snapshot) {

        base = this.url_base + base;
      } else if (options && options.ignore_base) {

        base = '';
      }

      var link = base + hash;

      if (hash.indexOf('/') > -1) {

        hash = hash.split('/')[0];
      }

      var link_scene = Knack.scenes.getBySlug(hash);

      if (!link_scene) {

        return link;
      }

      var link_scene_views = link_scene.get('views');

      if (!link_scene_views) {

        return link;
      }

      var replace_vars = {
      };

      // search for preset filters on views on the linked scene and append them to replace_vars
      _.each(link_scene_views, function(link_scene_view) {

        if (!link_scene_view) {

          return;
        }

        var preset_filters = link_scene_view.preset_filters;

        if (link_scene_view.type === 'report') {

          var report_index = 0;

          _.each(link_scene_view.rows, function(report_row) {

            _.each(report_row.reports, function(report) {

              if (report.filters && report.filters.preset_filters && report.filters.allow_preset_filters) {

                replace_vars[link_scene_view.key + '_' + report_index + '_filters'] = encodeURIComponent(JSON.stringify(report.filters.preset_filters));
              }

              report_index++;

              return;
            });
          });

          return;
        }

        if (link_scene_view.type === 'calendar') {

          if (link_scene_view.events && link_scene_view.events.view) {

            replace_vars[link_scene_view.key + '_view'] = encodeURIComponent(link_scene_view.events.view);
          }

          replace_vars[link_scene_view.key + '_date'] = encodeURIComponent(JSON.stringify(new Date()));
        }

        if (!link_scene_view.filter_type || link_scene_view.filter_type !== 'fields') {

          return;
        }

        if (!link_scene_view.allow_preset_filters) {

          return;
        }

        if (!preset_filters) {

          return;
        }

        replace_vars[link_scene_view.key + '_filters'] = encodeURIComponent(JSON.stringify(preset_filters));
      });

      if (_.isEmpty(replace_vars)) {

        return link;
      }

      // if we did have preset filters, append them to the link
      var filter_string = '';

      _.each(replace_vars, function(value, key) {

        if (filter_string) {

          filter_string += '&';
        }

        filter_string += key + '=' + value;
      });

      link += '?' + filter_string;

      return link;
    },

    handleChanges: function(changes) {

      log('knack.js :: handleChanges()');
      log(changes);

      // deletes
      if (changes.deletes) {

        if (changes.deletes.scenes.length) {

          _.each(changes.deletes.scenes, function(del) {

            this.scenes.remove(del.key, {
              silent: true
            });
          }, this);
        }
        if (changes.deletes.views.length) {

          _.each(changes.deletes.views, function(del) {

            var scene = this.scenes.getByKey(del.scene.key);

            scene.views.remove(del.view.key, {
              silent: true
            });
          }, this);
        }

        if (changes.deletes.objects.length) {

          _.each(changes.deletes.objects, function(del) {

            this.objects.remove(del.key, {
              silent: true
            });
          }, this);
        }

        if (changes.deletes.fields.length) {

          _.each(changes.deletes.fields, function(del) {

            var obj = this.objects.get(del.object.key);

            if (!obj || !obj.fields) {
              return;
            }

            obj.fields.remove(del.field.key, {
              silent: true
            });

            // we need to update the conns/connections with any reference to this field
            obj.attributes.connections.inbound = _.reject(obj.attributes.connections.inbound, function(conn) {

              return conn.key == del.field.key;
            });

            obj.attributes.connections.outbound = _.reject(obj.attributes.connections.outbound, function(conn) {

              return conn.key == del.field.key;
            });

            obj.attributes.conns = _.reject(obj.attributes.conns, function(conn) {

              return conn.key == del.field.key;
            });
          }, this);
        }
      }

      // inserts
      if (changes.inserts) {
        if (changes.inserts.scenes.length) {
          _.each(changes.inserts.scenes, function(insert) {

            // check if login
            if (insert.type == 'authentication' && changes.inserts.scenes.length == 1) { // checking length because copying scenes would also use this
              var index = -1;
              this.scenes.find(function(scene) {
                index++; return scene.get('key') == Knack.router.current_scene;
              });
              this.scenes.add(insert, {
                silent: true,
                at: index
              });
            } else {
              this.scenes.add(insert, {
                silent: true
              });
            }
          }, this);
        }
        if (changes.inserts.objects.length) {
          _.each(changes.inserts.objects, function(insert) {
            Knack.objects.add(insert, {
              silent: true
            }); // no silent, broadcast to imports
          });
        }
        if (changes.inserts.views.length) {
          _.each(changes.inserts.views, function(insert) {
            var scene = this.scenes.get(insert.scene.key);
            scene.views.add(insert.view);
          }, this);
        }
      }

      // updates
      if (changes.updates) {
        if (changes.updates.objects.length) {
          _.each(changes.updates.objects, function(update) {
            var object = this.objects.get(update.key);
            object.set(update, {
              silent: true
            });
            if (update.fields) {
              object.setFields(update.fields, update.key);
            }
            if (update.tasks) {
              object.updateTasks();
            }
            object.setConnections(update.connections);
          }, this);
        }
        if (changes.updates.fields.length) {
          _.each(changes.updates.fields, function(update) {
            var field = this.objects.get(update.object.key).fields.get(update.field.key);
            field.set(update.field, {
              silent: true
            });

            Knack.fields[update.field.key] = field
          }, this);
        }
        if (changes.updates.scenes.length) {
          _.each(changes.updates.scenes, function(update) {
            if (!update) {
              return;
            }

            var scene = this.scenes.getByKey(update.key);
            scene.set(update, {
              silent: true
            });
          }, this);
          this.scenes.setProperties();
        }
        if (changes.updates.views.length) {
          _.each(changes.updates.views, function(update) {
            var view = this.scenes.get(update.scene.key).views.get(update.view.key);
            view.set(update.view, {
              silent: true
            });
          }, this);
        }
      }
    },

    resetConnections: function(type, object, field) {
    },

    parseText: function(text) {
      return text.replace(/\n/gi, '<br />');
    },

    escape: function(text) {
      if (!text || typeof text != 'string') {
        return text;
      }
      text = text.replace(/\"/gi, '&quot;');
      return text;
    },

    handleTriggerLoadForm: function(event) {
      log('knack.js :: handleTriggerLoadForm()!');
      $(event.currentTarget).siblings('.cancel, .back').hide();
      $(event.currentTarget).addClass('disabled');
    },

    // HANDLERS FOR POPOVERS/MODAL FORMS
    error_callback: function() {
      log('knack.js :: error_callback()');
    },

    // used when saving models from a modal to close or alert of error
    modal_callbacks: {
      success: function(model, response) {

        // close modal/popover
        $('#modal').trigger('reveal:close');
        $('body').click();

        // handle changes
        if (response.changes) {
          Knack.handleChanges(response.changes);
        }
      },
      error: this.error_callback,
      wait: true
    },

    render: function(name, tmpl, data) {

      if (!this.render_cache[name]) {
        this.render_cache[name] = _.template(
          tmpl
        );
      }
      return this.render_cache[name](data);
    },

    formatNumberWithCommas: function(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },

    formatNumberForStorage: function(x) {
      if (x > 0) {
        var kilobytes = x / 1024;
        if (kilobytes > 1000) {
          var megabytes = kilobytes / 1000;
          if (megabytes > 1000) {
            return Knack.formatNumber((megabytes/1000), {
              precision: 1
            }) + ' GB';
          }
          return Knack.formatNumber(megabytes, {
            precision: 1
          }) + ' MB';

        }
        return Knack.formatNumber(kilobytes, {
          precision: 1
        }) + ' KB';

      }
      return '0 KB';

    },

    formatCurrency: function(x) {
      return Knack.formatNumberWithCommas(Number(x).toFixed(2));
    },

    pad: function(a,b) {
      return (String(1e15+a)).slice(-b);
    },

    getDateForInput: function(dateString, format, respectFormat) {

      if (respectFormat && format === 'dd/mm/yyyy') {

        return moment(dateString, format.toUpperCase()).toDate()
      }

      return new Date(dateString)
    },

    getDate: function(d) {
      return this.pad((d.getMonth()+1),2) + '/' + this.pad(d.getDate(),2) + '/' + d.getFullYear();
    },

    getDateEuro: function(d) {
      return this.pad(d.getDate(),2) + '/' + this.pad((d.getMonth()+1),2) + '/' + d.getFullYear();
    },

    convertDateEuroString: function(d) {
      if (String(d).indexOf('/') == -1) {
        return d;
      }

      var parts = String(d).split('/');
      return parts[1] + '/' + parts[0] + '/' + parts[2];
    },

    toNumber: function(val) {
      return Number(String(val).replace(/[^0-9\.\-]+/g,''));
    },

    addCommas: function(nStr, mark_thousands) {

      var decimal_place = '.';

      mark_thousands || (mark_thousands = ',');

      switch (mark_thousands) {
        case 'comma':
          mark_thousands = ',';
          break;
        case 'space':
          mark_thousands = ' ';
          break;
        case 'period':
          mark_thousands = '.';
          break;
      }

      nStr = String(nStr);

      var x = nStr.split(decimal_place);
      var x1 = x[0];
      var x2 = x.length > 1 ? decimal_place + x[1] : '';
      var rgx = /(\d+)(\d{3})/;

      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + mark_thousands + '$2');
      }

      return x1 + x2;
    },

    formatNumber: function(val, format) {

      return commonFormatHelper.formatNumber(val, format);
    },

    bindEvent: function(selector, event, callback) {
      $(selector).on(event, callback);
    },

    killEvent: function(selector, event, callback) {
      $(selector).off(event, callback);
    },

    showSpinner: function(options) {
      options || (options = {
        animated: true
      });
      if (options.animated && $('#kn-loading-spinner').fadeIn) {
        $('#kn-loading-spinner').fadeIn();
      } else {
        $('#kn-loading-spinner').show();
      }
    },

    hideSpinner: function(options) {
      options || (options = {
        animated: true
      });
      if (options.animated) {
        $('#kn-loading-spinner').fadeOut();
      } else {
        $('#kn-loading-spinner').hide();
      }
    },

    getCurrentTimeStamp: function() {
      return commonTimeHelper.getCurrentTimeStamp(Knack.app);
    },

    getTimeZone: function() {
      return commonTimeHelper.getTimeZone(Knack.app.toJSON());
    },

    checkRule: function(rule, record, val) {
      var field = Knack.fields[rule.field];

      if (!field || !field.toJSON) {
        return false;
      }

      field = field.toJSON();

      if (!val || typeof val == 'undefined') {
        var val = null;

        // check if the raw
        var is_date_equation = (field.type == Knack.config.TIMER || (field.type == Knack.config.EQUATION && field.format && field.format.equation_type && field.format.equation_type == 'date' && field.format.date_result && field.format.date_result == 'number'));

        // check for field types where raw is stored in seconds or a format that won't work with comparables.
        if (is_date_equation) {
          if (record && typeof record[rule.field] != 'undefined') {
            val = record[rule.field];
          }
        } else {
          if (record && typeof record[rule.field + '_raw'] != 'undefined') {
            val = record[rule.field + '_raw'];
          }
        }
      }

      // ensure that boolean values are convertedd from strings to actual booleans for evaluation by rule-helper
      if (field.type === `boolean`) {

        // standardize values to boolean types. these are the potential options that a boolean field can have its values set to
        const trueValues = [
          `yes`,
          `true`,
          `on`
        ]

        if (!_.isBoolean(val)) {

          val = trueValues.includes(String(val).toLowerCase()) ? true : false
        }

        if (!_.isBoolean(rule.value)) {

          rule.value = trueValues.includes(String(rule.value).toLowerCase()) ? true : false
        }
      } else if (val && val[0] && val[0].id) {
        // connection values
        val = _.filter(_.map(val, function(_val) {

          if (!_val.id || _val.id.length === 1) {

            return;
          }

          return _val.id;
        }), function(_val) {

          return _val;
        }).join(',');
      }

      // Provide the app to call this the same as on the server, as rule-helper uses it to get the app timezone
      return commonRuleHelper.checkRule(rule, val, field, Knack.app.toJSON());
    },

    formatTimer: function(timer_val, field_format) {

      log(`Knack.formatTimer()`)
      log(`timer_val:`)
      log(timer_val)
      log(`field_format`)
      log(field_format)

      var date_format = 'MM/DD/YYYY hh:mm a';

      if (!timer_val) {
        return 0;
      }

      timer_val = Number(timer_val)

      var seconds = timer_val / 1000
      var minutes = (seconds / 60)
      var hours = minutes / 60
      var days = hours / 24
      var weeks = days / 7

      var time = '';

      switch (field_format.total_format) {

        case 'hours':

          time = this.formatNumber(hours, field_format);

          break;
        case 'seconds':

          time = this.formatNumber(seconds, field_format);

          break;
        case 'minutes':

          time = this.formatNumber(minutes, field_format);

          break;
        case 'days':

          time = this.formatNumber(days, field_format)

          break
        case 'weeks':

          time = this.formatNumber(weeks, field_format)

          break
        default:
          days = Math.floor(days);
          hours = Math.floor(hours);
          minutes = (timer_val - (hours * 60 * 60 * 1000)) / 60000;
          if (hours == 0) {
            time += minutes + ' minutes';
          } else if (hours < 24) {
            if (minutes < 10) {
              minutes = '0' + minutes;
            }
            time += hours + ':' + minutes + ' hours';
          } else {
            if (minutes < 10) {
              minutes = '0' + minutes;
            }
            time += days;
            time += (days > 1) ? ' days, ' : ' day, ';
            time += (hours%24) + ':' + minutes + ' hours';
          }
          break;
      }

      log(`knack.formatTimer returning time variable with value: ${time}`)
      log(`knack.formatTimer returning time variable as typeof: ${typeof time}`)

      return time;
    },

    // this handles international commas and decimals and strips those out.
    // should only be used handling
    stripNumber: function(field, val, raw) {
      if (!val || val == 0) {
        return val;
      } // return zeros and blanks

      if (field && field.type && field.type == 'timer' && raw) {
        return raw; // timer could have a formatted value like 0.5 hours
      }

      val = String(val);
      var format = (field && field.format) ? field.format : {
      };

      var mark_decimal = '.';
      if (format.mark_decimal && format.mark_decimal == 'comma' && format.precision) {
        var mark_decimal = ',';
      }
      var mark_thousands = ',';
      if (format.mark_thousands && format.mark_thousands != 'none' && format.mark_thousands != 'comma') {
        mark_thousands = (format.mark_thousands == 'space') ? ' ' : '\\.';
      }

      if (mark_thousands != ',') {
        val = val.replace(new RegExp(mark_thousands, 'g'), '');
      }
      if (mark_decimal != '.') {
        val = val.replace(new RegExp(mark_decimal, 'g'), '.');
      }
      val = Number(val.replace(/[^0-9\.\-]+/g, ''));

      return val;
    },

    trans: function(phrase) {

      var defaults = {
        'export_format': 'Select the format to export the data in',
        'forgot_pass': 'Forgot your Password?',
        'pass_copy': ' Enter your email address below and we will send you a link to reset your password',
        'pass_email': ' An email containing a link to reset your password has been sent.',
        'pass_general_response': 'An email will be sent with further instructions on how to reset your password if an account was found.',
        'pass_reset_1': 'Please click on the following link to reset the password for your ',
        'pass_reset_2': ' account. If clicking the above link does not work, please copy and paste the URL into a new browser window.',
        'reg_confirm': 'Your registration is complete! Click the back link to log in.',
        'reg_pending': 'Your registration has been submitted for approval. Once approved you will receive an email with further instructions.',
        'account_update_copy': 'Use these forms to update your account information or change your password.',
        'account_confirm': 'Your account information has successfully been updated.',
        'pass_confirm': 'Your password has been successfully updated.',
        'pass_reset_confirm': 'Your password has been reset.',
        'pass_reset_copy': 'Enter your email address below and we will send you a link to reset your password.',
        'form_submit_confirm': 'Form successfully submitted',
        'reload_form': 'Reload form',
        'login_permission': 'doesn\'t have permission to access this page',
        'pass_reset_exceeded': 'Too many password reset requests. Please try again later.'
      };

      if (this.translations && this.translations[phrase]) {
        return this.translations[phrase];

      }
      return defaults[phrase] || phrase;

    },

    // GET METHODS ====================================
    getProductionMode: function() {
      return window.knack_production_mode;
    },

    getBuilderUrl: function(accountSlug, appSlug) {
      let builderPrefix = 'builder';
      let domain = Knack.domain;

      if (domain === 'knackdev.com:3000' && this.getProductionMode() === 'development') {
        // local dev mode, point to v3builder on different port
        builderPrefix = 'v3builder';
        domain = domain.replace(':3000', ':8080');
      }

      return `${Knack.protocol}${builderPrefix}.${domain}/${accountSlug}/${appSlug}` ;
    },

    getUserAttributes: function() {
      if (this.user) {
        var vals = this.user.toJSON();

        if (!vals || !vals.values || !vals.values.email) {
          return 'No user found';
        }

        var user = {
          id: vals.id,
          values: vals.values,
          roles: this.getUserRoles(),
          //, approval_status: vals.approval_status
          email: vals.values.email.email,
          name: vals.values.name.first + ' ' + vals.values.name.last
        };

        return user;
      }

      return 'No user found';
    },

    getUserRoles: function(object_key) {
      if (this.user) {

        var roles = this.user.get('profile_objects');

        if (!object_key) {
          return _.pluck(roles, 'object');
        }
        var role = _.find(roles, function(role) {
          return role.object == object_key;
        });
        return (role) ? true : false;

      }
      return 'No user found';

    },

    getUserRoleNames: function() {

      var userRoles = []

      _.each(this.user.getProfileKeys(), function(profileKey) {

        if (!profileKey) {

          return
        }

        if (Knack.objects.getByProfile(profileKey)) {

          userRoles.push(Knack.objects.getByProfile(profileKey).get('name'))
        }
      })

      return userRoles.join(', ')
    },

    getUserToken: function() {
      if (this.user) {
        return this.user.get('token');
      }

      return null;
    },

    getUserRefreshToken: function() {

      const refreshToken = localStorage.getItem(`refreshToken-${Knack.app.id}`)

      return refreshToken
    },

    logEvent: function(event, metadata) {

      if (typeof analytics === 'undefined') {

        return;
      }

      if (this.application_id) {

        metadata.application_id = this.application_id;
      }

      // let's add account id and plan for better conversion tracking here
      if (this.account && this.account.product_plan) {

        var plan = this.account.product_plan;

        metadata.account_id = this.account.id;
        metadata.plan = plan ? plan.name : 'Trial';
        metadata.monthly_spend = plan ? plan.price : 0;
      }

      try {

        analytics.track(event, metadata);
      } catch (error) {
      }
    },

    renderOverlayTemplate: function(template_str, template, options, data) {
      var $html = Knack.render(template_str, template, data);
      this.renderOverlay($html, options);
    },

    renderOverlay: function(html, options) {
      options || (options = {
      });

      // append to kn-Overlay
      $('#kn-overlay').html(html);

      var additional_height = 0;

      $('.kn-notify-top').each(function() {

        var $el = $(this);

        additional_height += $el.height();
      });

      $('#kn-overlay').css('top', additional_height + 'px');

      this.showOverlay(options);
    },

    showOverlay: function(options) {
      var $overlay = $('#kn-overlay');

      $overlay.css({
        'z-index': this.top_z_index++
      });

      options.show ? $overlay.show() : $overlay.fadeIn('fast');

      // add / remove dark theme
      options.dark ? $overlay.addClass('dark') : $overlay.removeClass('dark');

      // modal bind
      $overlay.find('.close-overlay, .cancel').off().on('click', $.proxy(this.handleClickCloseOverlay, this));

      $(document).trigger('knack-overlay-render');
    },

    handleClickCloseOverlay: function(event) {
      this.closeOverlay();

      if (event) {
        $(document).trigger('knack-overlay-close', event);
      }

      event.preventDefault();
      event.stopImmediatePropagation();
    },

    closeOverlay: function(options) {
      var $overlay = $('#kn-overlay');
      $overlay.fadeOut();

      // read ignore navigate option from HTML
      var ignore_navigate = $overlay.find('.cancel').attr('data-ignore-navigation');
      if (options && typeof options.ignore_navigate != 'undefined') {
        ignore_navigate = options.ignore_navigate;
      }

      var hash = $('#kn-overlay').data('hash');
      if (hash) {
        $('#kn-overlay').data('hash', null);
        Knack.router.navigate(hash, false);

      } else if (!ignore_navigate) {
        this.navigateBack(1, false);
      }
    },

    renderModalTemplate: function(template_str, template, options, data) {
      var $html = Knack.render(template_str, template, data);
      this.renderModal($html, options);
    },

    renderModal: function(html, options) {

      options || (options = {
      });

      var modal_id = 'kn-modal-bg-' + this.modals.length;
      var modal_html = '<div id="' + modal_id + '" class="kn-modal-bg">' +
                         '<div class="kn-modal">' + html + '</div>' +
                       '</div>';

      // append to knack-content
      $('.kn-content').append(modal_html);

      var $modal = $('#' + modal_id);

      // add ID for pages
      if ($modal.find('.kn-page-modal').length) {
        var page_id = 'kn-page-modal-' + this.modals.length;
        $modal.find('.kn-page-modal').prop('id', page_id);
      }

      // add class
      var class_size = options.class || 'default';
      $modal.find('.kn-modal').addClass(class_size);

      if (options.top) {
        $modal.addClass('top');
      }


      var additional_height = 0;

      $('.kn-notify-top').each(function() {

        var $el = $(this);

        additional_height += $el.height();
      });

      $modal.css('top', additional_height + 'px');

      this.modals.push($modal);
      this.showModal(options);

      $('.kn-modal-bg').scrollTop(0); // scroll top
    },

    showModal: function(options) {
      var $modal = this.modals[this.modals.length -1];
      $modal.css({
        'z-index': this.top_z_index
      });

      $modal.fadeIn('fast');
      $modal.find('.kn-modal').show();

      // modal bind
      $modal.find('.close-modal, div.submit .cancel, a.continue').off().on('click', $.proxy(this.handleClickCloseModal, this));
      $modal.off().on('click', (event) => {
        this.handleClickCloseModal(event, options);
      });
      $modal.find('.kn-modal-print').off().on('click', $.proxy(this.handleClickPrintModal, this));

      $(document).trigger('knack-modal-render', {
        modal: $modal
      });
    },

    handleClickPrintModal: function(event) {

      event.preventDefault();
      $('.kn-scenes').addClass('is-print-hidden');
      window.print();
    },

    handleClickCloseModal: function(event, options) {
      options || (options = {
      });

      var $modal = this.modals[this.modals.length-1];

      if ($modal && !options.modal_prevent_background_click_close && (!event || ($(event.target).prop('id').indexOf('kn-modal-bg') > -1 || $(event.target).hasClass('close-modal') || $(event.target).hasClass('cancel') || $(event.target).hasClass('continue')))) {

        $modal = this.modals.pop();

        this.closeModal($modal);

        if (event) {

          $(document).trigger('knack-modal-close', event);
        }

        $('.kn-scenes').removeClass('is-print-hidden');

        $('.kn-popover').removeClass('kn-popover-lower');
      }

    },

    closeModal: function($modal, options) {
      log('Knack.closeModal() this.modals: ' + this.modals.length);
      var options = options || {
      };

      $modal = $modal || this.modals.pop();
      log($modal);

      if ($modal) {

        // read navigate back from close link HTML
        var navigate_back = $modal.find('.close-modal').attr('data-back-navigation');
        if (navigate_back) {
          options.navigate_back = Number(navigate_back);
        }

        // if this is a page modal
        if ($modal.find('.kn-page-modal').length || options.navigate_back) {

          // get hash of visible page
          // check if any modals still exist
          if (this.modals.length > 0) {

            // this is a secondary modal on top of another page modal
            var hash = $('#kn-page-modal-' + (this.modals.length-1) + ' .kn-scene').data('hash');

          } else {
            var hash = $('.kn-scenes > .kn-scene').data('hash');
          }

          log('hash: ' + hash);

          if (hash && !_.isNull(Knack.url_ref) && !_.isEmpty(Knack.url_ref)) {

            // Prevents url_ref from appending onto a url with existing params
            if (typeof hash === 'string' && hash.includes('?')) {
              hash = hash.split('?')[0];
            }
            hash = `${hash}?${decodeURIComponent(Knack.url_ref)}`;
         }

          if (hash) {
            Knack.router.navigate(hash, false);

          } else {
            var navigate_back = options.navigate_back || 1;
            this.navigateBack(navigate_back, false);
          }

          // reset everything
          // the router will use ignore_page_reset if it is closing modals from back buttons
          if (navigate_back) {
            this.setHashVars();
          } else if (this.mode == 'renderer' && !options.ignore_page_reset) {
            this.setHashVars();
            this.router.setScene();
          }
        }

        $modal.remove();
      }
    },

    // utility display function for handling icons
    displayIconValue: function(value, icon) {

      // If no icon
      var has_icon = (icon && icon.icon);
      if (!has_icon) {
        return value;
      }

      var val = '';
      var style = '';
      var icon_val = '';

      if (Knack.getStyleEngine() === 'v2') {
        var link_level = $('<span>');
        link_level.addClass('level is-compact');

        var icon_element = $('<span class="icon">');

        // Icon alignment
        if (icon.align) {
          icon_element.addClass(`is-${icon.align}`);
        }

        // Font-awesome icon
        if (icon.icon) {
          icon_i = $(`<i class="fa ${icon.icon}">`);
          icon_element.append(icon_i);
        }

        if (icon.align === 'right') {
          link_level.append(`<span>${value}</span>`);
          link_level.append(icon_element);
        } else {
          link_level.append(icon_element);
          link_level.append(`<span>${value}</span>`);
        }

        val = link_level.prop('outerHTML');

      } else {
        if (value) {
          style += (icon && icon.align == 'right') ? 'margin-left: 0.4em' : 'margin-right: 0.4em';
        }

        if (icon && icon.icon) {
          var icon_val = '<i class="fa ' + icon.icon + '" style="' + style + '"></i>';
        }

        var val = '<span>';

        // left align
        if (icon_val && icon.align == 'left') {
          val += icon_val;//+ '&nbsp;&nbsp;';
        }

        // value
        val += value;

        // right align
        if (icon_val && icon.align == 'right') {
          val += icon_val;//'&nbsp;&nbsp;' + link_icon;
        }

        val += '</span>';
      }

      return val;
    },

    formatFileSize: function(bytes, decimals) {
      if (bytes== 0) {
        return '0 Bytes';
      }
      var k = 1000, //1024 or 1 kilo = 1000
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
    },

    isMobile: function() {

      if (_.isUndefined(this.mobile)) {

        var a = navigator.userAgent || navigator.vendor || window.opera;
        this.mobile = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od|ad)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4));
      }

      return this.mobile;
    },

    getCurrentViewKey: function(model) {

      if (this.mode === 'renderer' && model && model.view && model.view.key) {
        return model.view.key;
      }

      return this.router.active_view.model ? this.router.active_view.model.id : null;
    },

    clearPopovers: function() {

      // popovers
      $('.kn-popover.drop-target.drop-enabled').each(function() {
        $(this).data('knPopover').drop.remove();
      });

      // dropdowns
      $('.kn-dropdown-list.tether-enabled').hide();

      // tooltips
      $('.tooltip-element').hide();
    },

    isTrial: function() {

      return Knack.account.product_plan && Knack.account.product_plan.id === `trial`;
    },

    isKnackInternal: function() {

      return Knack.account.product_plan && Knack.account.product_plan.id === `knack_internal`;
    },

    isHIPAA: function() {

      if (!Knack.account || !Knack.account.settings || !Knack.account.settings.hipaa || !Knack.account.settings.hipaa.enabled) {

        return false
      }

      return true
    },

    isSharedBuilder: function() {

      return Knack.user.id !== Knack.account.user_id
    },

    isCustom: function () {

      return Knack.app.get(`settings`).cluster === `custom`
    },

    checkIfThirdPartyCookiesAreBlocked(callback) {

      const thirdPartyCookieCheck = new Backbone.Model()

      thirdPartyCookieCheck.url = `${this.api_dev}/third-party-cookie-check`

      thirdPartyCookieCheck.fetch({
        success: (model, response) => {

          this.third_party_cookies_blocked = response.blocked

          // We need to set a cookie because Safari's status of blocking
          // third-party cookies changes during a session
          $.cookie(`third-party-blocked`, response.blocked)

          return callback()
        },
        error: () => {

          this.third_party_cookies_blocked = true

          $.cookie(`third-party-blocked`, `true`)

          return callback()
        }
      })
    },
    isOnNonKnackDomain: function () {
      let {
        api_domain: apiDomain,
        location: { hostname }
      } = window;

      apiDomain = apiDomain.split(`:`)[0];

      if (!hostname.endsWith(apiDomain)) {
        return true;
      }

      if (window.distribution_key && _.get(this.distributions._byId, window.distribution_key) && (this.distribution && this.distribution.get('mode') === 'embed')) {
        return true;
      }

      return false;
    },

    isGovCloud: function () {

      return Knack.app.get(`settings`).cluster === `us-govcloud`
    },

    hasCrmUrl: function () {

      return window.location.hostname.includes(`knackcrm.com`)
    },

    isSupportAccessible: function () {
      // not available in Production or Builder
      if (Knack.getProductionMode() !== 'development' || Knack.mode !== 'renderer') {
        return false
      }

      // HIPAA / GovCloud: always disabled
      if (Knack.isHIPAA() || Knack.isGovCloud()) {

        return false
      }

      // Trial / Starter / Pro / Corporate: always enabled
      if (Knack.account.product_plan.level && Knack.account.product_plan.level < 4) {

        return true
      }

      // Enterprise or higher, but doesn't have property set yet: always enabled
      if (!_.has(Knack.app.get(`settings`), `support_access`)) {

        return true
      }

      // Enterprise or higher: has option to enable/disable
      return _.has(Knack.app.get(`settings`), `support_access`) && Knack.app.get(`settings`).support_access
    },

    useKnackMaps: function() {

      if (!Knack.app || !Knack.app.get(`settings`)) {

        return false
      }

      // Ensure provider is set to google and an api key has been set
      return !(Knack.app.get(`settings`).mapsAndGeocoderProvider === `google` && Knack.app.get(`settings`).googleMapsApiKey !== ``)
    },

    sunsetV2Builder: function () {

      return moment().isAfter(moment(`2021-05-01 00:00:00-0400`)) && (Knack.account && Knack.account.settings && !Knack.account.settings.hasV2Access)
    }
  });
});
