define([
  'jquery',
  'underscore',
  `moment`,
  'vue',
  'core/lib/backbone',
  'core/backbone/views/BaseView',
  'core/backbone/views/LoginView',
  'core/backbone/models/Form',
  'core/backbone/views/FormView',
  'core/backbone/models/Account',
  'core/backbone/views/AccountView',
  'dashboard/backbone/views/BuildersView',
  'dashboard/components/MigrationApps/Dashboard.vue',
  'dashboard/templates/apps.html',
  'dashboard/templates/app-list.html',
  'dashboard/templates/app-templates.html',
  'dashboard/templates/app-complete.html',
  'dashboard/templates/app-storage.html',
  'dashboard/templates/app-records.html',
  'dashboard/templates/app-tasks.html',
  'dashboard/templates/account.html',
  'dashboard/templates/billing.html',
  'dashboard/templates/billing-invoices.html',
  'dashboard/templates/invoice.html',
  'dashboard/templates/billing-payment.html',
  'dashboard/templates/billing-settings.html',
  'dashboard/templates/billing-plan.html',
  'dashboard/templates/billing-plan-downgrade.html',
  'dashboard/templates/billing-unfreeze.html',
  'dashboard/templates/user.html',
  'dashboard/templates/user-2fa-enable.html',
  'dashboard/templates/user-2fa-disable.html',
  'dashboard/templates/user-2fa-reset-confirm.html',
  'dashboard/templates/account-delete.html',
  'dashboard/templates/account-delete-confirm.html',
  'dashboard/templates/app-delete.html',
  'dashboard/templates/app-add.html',
  'dashboard/templates/app-settings.html',
  'dashboard/templates/app-add-properties.html',
  'dashboard/templates/knack-layout-goodbye.html',
  'dashboard/templates/app-options.html',
  'dashboard/templates/app-copy.html',
  'dashboard/templates/app-copy-complete.html',
  `dashboard/templates/app-copy-v3.html`,
  'dashboard/templates/account-freeze.html',
  'dashboard/templates/settings-security.html',
  'dashboard/templates/update-billing.html',
  `dashboard/templates/payment-updated.html`,
  '@knack/sanitization-helper',
  'builder/ui/tooltip',
  'core/utility/utility-forms',
  'core/ui/popover',
  'builder/ui/kn-search-input',
  'builder/ui/kn-dropdown',
  'builder/ui/slug-input',
  'dashboard/ui/widgets/menus/tab-menu',
  'builder/ui/accordion',
  'core/ui/kn-popover',
  'core/ui/helpers/captcha-helper',
  'core/lib/lazyload',
  'core/lib/cookie',
  `core/ui/helpers/address-helper`
], function($, _, moment, Vue, Backbone, BaseView, LoginView, Form, FormView, Account, AccountView, BuildersView, MigrationDashboard, template_apps, appList_template, appTemplates_template, appComplete_template, template_appStorage, template_appRecords, template_appTasks, template_account, billing_template, invoices_template, invoice_template, payment_template, template_billingSettings, plan_template, template_billingDowngrade, template_unfreeze, user_template, template_enable2fa, template_disable2fa, template_reset2faSecretKey, account_delete, accountConfirm_template, template_deleteApp, template_addApp, template_appSettings, template_addAppProperties, goodbye_template, template_appOptions, template_copyApp, template_copyAppComplete, templateCopyAppToV3, template_confirmFreeze, securitySettingsTemplate, updateBillingTemplate, paymentUpdatedTemplate, sanitization_helper, ToolTip, UtilityForms, Popover, SearchInput, KnDropdown, SlugInput, TabMenu, Accordion, KnPopover, CaptchaHelper, LazyLoad, cookie, AddressHelper) {

  return BaseView.extend({

    initialize: function() {

      // bind load
      Knack.off('load', $.proxy(this.initialize, this));
      Knack.on('load', $.proxy(this.initialize, this));

      this.initializeUsers();
      this.clearLastEvents();

      log('BuildersView:');
      log(BuildersView);

      $('.delete-app').unbind().live('click', $.proxy(this.renderDeleteApp, this));
      //$('.app-options').unbind().live('click', $.proxy(this.handleClickAppOptions, this));
    },

    initializeUsers: function() {

      // bind user
      Knack.user.off(null, null, this);
      Knack.user.on('login', function(model, response) {
        log('Dashboard.login:');
        log(arguments);
        Knack.loadAccount(response);
        Knack.registerLogin();
        Builder.render();
        this.renderYourApps();
        Knack.hideSpinner();
      }, this);
      Knack.user.on('destroy', function(model, response) {
        window.location.href = '/';
      }, this);
    },

    /**
     * Clears the inactivity timeout local storage values for all the user's apps on dashboard load.
     * This ensures that the user won't have to login again (due to inactivity) after opening one of their apps.
     */
    clearLastEvents() {
      const eventTypesToClear = [
        `-user-last_event`,
        `-dashboard-last_event`
      ]

      for (let localStorageItemIndex = 0; localStorageItemIndex < localStorage.length; localStorageItemIndex += 1) {
        const localStorageKeyAtCurrentIndex = localStorage.key(localStorageItemIndex)

        const shouldClear = eventTypesToClear.some((eventTypeToClear) => {
          return localStorageKeyAtCurrentIndex.includes(eventTypeToClear)
        })

        if (shouldClear) {
          localStorage.removeItem(localStorageKeyAtCurrentIndex)
        }
      }
    },

    render: function() {

      log('DashboardView.render()!');

      // deactivate all links
      $('#app-menu .active').removeClass('active');
      $('#body-menu-pill-links').remove();

      // check auth
      if (!this.checkAuthentication()) {
        return false;
      }

      // hide links for shared builders
      if (Knack.account.non_account) {
        $('.dashboard-menu a[href=#billing]').closest('li').remove();
        $('.dashboard-menu a[href=#delete]').closest('li').remove();
        $('.dashboard-menu a[href=#account]').closest('li').remove();
        $('.dashboard-menu a[href=#apps]').prop('href', '#apps/shared');
      }

      return this;
    },

    // called from shared builder
    renderAccountSignup: function() {

      $('#content-wrapper').html('<div id="new-account" class="kn-view gray-box padded"></div>');//('GET YOUR OWN ACCOUNT!');

      // account form
      var view_model = new Form();
      //view_model.id = Knack.account.id;

      view_model.setView({
        title: 'Get your own Knack account!',
        description: 'Fill out the form below to start building your own Knack apps. Start with a 14 day free trial.',
        confirmation: {
          type: 'show_text',
          confirmation_text: 'Your account has been created.'
        },
        inputs: [
          {
            type: 'short_text',
            id: 'name',
            field: {
              'key': 'name'
            },
            label: 'Account Name'
          },
          {
            type: 'short_text',
            id: 'slug',
            field: {
              'key': 'slug'
            },
            label: 'URL'
          }
        ]
      });
      view_model.url = Knack.api + '/from_user';

      this.form_view = new FormView({
        'model': view_model,
        'el': '#new-account'
      });//, 'listener': $.proxy(this.handleSubmitForm, this)});
      this.form_view.render();
      this.form_view.model.on('sync', function() {
        window.location.reload(true);
      });

      $('input[name=name]').keyup(function() {
        $('input[name=slug]').val(sanitization_helper.sanitizeSlug($('input[name=name]').val()));
      });

      // add text
      $('#slug').after('<span>&nbsp;.' + Knack.domain + '</span>');

      Knack.hideSpinner();
    },

    renderApps: function() {

      // show and activate apps
      $('#app-menu .active').removeClass('active');
      $('#app-menu a[href="#apps"]').addClass('active');

      $('#body').html(Knack.render('template_apps', template_apps, {
        v3: Knack.user.get('v3_beta')
      }));

      // Show the billing address collection banner if we don't have their address in stripe. Don't show if trial or internal plan.
      if (Knack.isKnackInternal() || Knack.isTrial() || Knack.isSharedBuilder()) {

        return
      }

      if (_.isEmpty(Knack.account.billing.billing_address)) {

        $('#billing-address-notice').removeClass('display-none')
      }
    },

    renderYourApps: function() {

      if (this.render()) {
        var _this = this;
        log('renderApps!');

        const v2Applications = Knack.account.applications.filter(app => app.settings.v3_beta !== true)

        log(v2Applications);

        Knack.showSpinner();

        // show add app and plan usage in case it was hidden from shared apps
        $('#add-app-link, .settings-column').hide();

        this.renderApps();
        this.searchApps(v2Applications);

        // menu
        $('#body-menu-pill-links .active').removeClass('active');
        $('#apps-owner').closest('li').addClass('active');

        // non accounts have shared apps but not a personal account
        if (Knack.account.non_account) {
          return this.renderAccountSignup();
        }

        // get API usage
        var usage = new Backbone.Model();
        usage.url = Knack.job_dev + '/account/usage/api/today';

        usage.fetch({
          success: function(model, response) {
            Knack.hideSpinner();

            var plan = Knack.account.product_plan;

            if (!response.results || !plan) {

              // render app list without API usage
              $('#content-wrapper').html(Knack.render('appList_template', appList_template, {
                account: Knack.account.slug,
                apps: v2Applications,
                shared: false,
                v3: false
              }));

            } else {

              usage.total = response.results.count || 0;
              usage.extra = Knack.account.api_limit_extra.quantity;
              usage.limit = plan.api_limit + (usage.extra * 25000);

              //render app list with API usage
              $('#content-wrapper').html(Knack.render('appList_template', appList_template, {
                account: Knack.account.slug,
                apps: v2Applications,
                api: usage,
                shared: false,
                v3: false
              }));
            }

            $('.settings-warning').each(function() {
              new ToolTip({
                target: this,
                position: 'left middle'
              });
            });

            $('ul#app-list').sortable({
              distance: 5,
              update: $.proxy(_this.handleAppSort, _this),
              handle: '.sort-bg'
            });

            // heights
            $(window).off(`knackResize`).on(`knackResize`, _.partial(_this.renderHeights, false));

            _this.renderHeights();

            $('.kn-dropdown').knDropdown({
              attachment: 'top center',
              targetAttachment: 'bottom center',
              targetOffset: '5px 0',
              constrainToWindow: true,
              linkHandler: $.proxy(_this.handleClickAppOptions, _this)
            });

            // button handler
            $('#add-app').off().click(function(event) {

              var max = Knack.account.plan_limits.applications;

              log('comparing max: ' + max + '; apps: ' + $('#app-list').children().length);

              if ($('#app-list').children().length >= max && Knack.getProductionMode() == 'production') {

                event.preventDefault();

                //var content = '<h1>App limit</h1><div style="padding:12px;"><p>Uh oh, you have reached the maximum number of apps allowed';
                var content = 'Uh oh, you have reached the maximum number of apps allowed';
                if (plan && plan.id && plan.id == 'trial') {
                  content += ' during the free trial.  You can subscribe to a plan ';
                } else {
                  content += ' with your plan.  You can upgrade your plan ';
                }
                content += 'by clicking the Billing link from the left menu.';
                alert(content);
              }

              if (Knack.user.getAccountStatus() == 'frozen') {

                event.preventDefault();
                content = 'Your account is frozen. Click on the "Billing" link to select a plan and unfreeze your account.';
                alert(content);
              }

            });

            $('.app-records').off().click(function(event) {
              event.preventDefault();

              var app_id = $(event.currentTarget).closest('li').prop('id');

              // show top
              Knack.renderModalTemplate('template_appRecords', template_appRecords, {
              });

              $('#submit-refresh-records').off().click(function(event) {

                event.preventDefault();

                var model = new Backbone.Model();
                model.url = Knack.api_url + '/v1/applications/' + app_id + '/records/count';

                Knack.showSpinner();

                model.fetch({
                  success: function(model, result, response) {
                    log(arguments);
                    Knack.hideSpinner();
                    Knack.closeModal();

                    $('#' + app_id + ' .app-records').text(result.count);
                  }
                });
              });

            });

            $('.app-storage').off().click(function(event) {

              event.preventDefault();

              var app_id = $(event.currentTarget).closest('li').prop('id');

              // show top
              Knack.renderModalTemplate('template_appStorage', template_appStorage, {
              });

              $('#submit-delete-assets').off().click(function(event) {

                event.preventDefault();

                var model = new Backbone.Model();
                model.url = Knack.api_url + '/v1/applications/' + app_id + '/assets/delete';

                Knack.showSpinner();

                model.fetch({
                  success: function(model, result, response) {

                    log(arguments);

                    Knack.hideSpinner();
                    Knack.closeModal();

                    $('#' + app_id + ' .app-storage').text(Knack.formatNumberForStorage(result.storage));
                  }
                });
              });
            });

            $('.app-tasks').off().click(function(event) {

              event.preventDefault();

              var app_id = $(event.currentTarget).data('app-id');
              var app_model = _.find(Knack.account.applications, function(app) {

                return app._id === app_id;
              });

              Knack.renderModalTemplate('template_appTasks', template_appTasks, {}, {app: app_model});
            });

            $(`.use-new-builder a.toggle-on, .use-new-builder a.toggle-off`).off().click(function(event) {

              event.preventDefault()

              const elem = $(this)

              const id = elem.closest(`li`).attr(`id`)

              const app = Knack.account.applications.find(({_id}) => _id === id)

              const model = new Backbone.Model({
                id
              })

              model.url = `${Knack.api}/apps/${id}`

              app.settings.v3_open_beta = !app.settings.v3_open_beta

              model.save({
                settings: {
                  v3_open_beta: app.settings.v3_open_beta
                }
              }, {
                success: () => _this.renderYourApps()
              })
            })
          }
        });
        return this;
      }
    },

    handleAppSort: function(event, object) {
      var sort_model = new Backbone.Model();
      var new_order = $('ul#app-list').sortable('toArray');
      var _this = this;

      sort_model.url = Knack.api_url + '/v1/accounts/'+ Knack.account.id + '/apps/sort';
      sort_model.save({
        order: new_order
      }, {
        success: function(model, response) {
          log('saved');
          log(response.apps);
        }
      });
    },

    renderSharedApps: function() {

      log('renderSharedApps!');

      var _this = this;

      if (this.render()) {

        var model = new Backbone.Model();
        model.url = Knack.loader_url + '/applications/shared';

        Knack.showSpinner();

        _this.renderApps();

        // hide add app and plan usage
        $('#add-app-link, .settings-column').hide();

        // menu
        $('#body-menu-pill-links .active').removeClass('active');
        $('#apps-shared').closest('li').addClass('active');

        model.fetch({
          success: function(model, result, response) {
            log(arguments);
            Knack.hideSpinner();

            $('#content-wrapper').html(Knack.render('appList_template', appList_template, {
              account: Knack.account.slug,
              apps: result.apps,
              shared: true,
              v3: false
            }));

            // heights
            $(window).off(`knackResize`).on(`knackResize`, _.partial(_this.renderHeights, false));
            _this.renderHeights();

            $('#add-app').remove();
            $('#app-list .kn-dropdown-list, #app-list .app-options').remove();
            _this.searchApps(result.apps);
          }
        });

      }
    },

    renderV3Apps: function() {

      if (!this.render()) {

        return
      }

      Knack.showSpinner()

      this.renderApps()

      $(`#context-nav`).hide()

      $(`#context-nav-v3`).show()

      $(`#body-menu-pill-links .active`).removeClass(`active`)

      $(`#apps-v3`).closest(`li`).addClass(`active`)

      const apps = Knack.account.applications.filter(app => app.settings.v3_beta === true)

      $('#content-wrapper').html(Knack.render('appList_template', appList_template, {
        account: Knack.account.slug,
        apps,
        shared: false,
        v3: true
      }))

      $(`.settings-column`).hide()

      $(`.kn-dropdown`).knDropdown({
        attachment: `top center`,
        targetAttachment: `bottom center`,
        targetOffset: `5px 0`,
        constrainToWindow: true,
        linkHandler: $.proxy(this.handleClickAppOptions, this)
      })

      $(window).off(`knackResize`).on(`knackResize`, _.partial(this.renderHeights, true))

      this.renderHeights(true)

      Knack.hideSpinner()

      this.searchApps(apps, `-v3`)

      $('.app-records').off().click(function(event) {
        event.preventDefault();

        var app_id = $(event.currentTarget).closest('li').prop('id');

        // show top
        Knack.renderModalTemplate('template_appRecords', template_appRecords, {
        });

        $('#submit-refresh-records').off().click(function(event) {

          event.preventDefault();

          var model = new Backbone.Model();
          model.url = Knack.api_url + '/v1/applications/' + app_id + '/records/count';

          Knack.showSpinner();

          model.fetch({
            success: function(model, result, response) {
              log(arguments);
              Knack.hideSpinner();
              Knack.closeModal();

              $('#' + app_id + ' .app-records').text(result.count);
            }
          });
        });

      });

      $('.app-storage').off().click(function(event) {

        event.preventDefault();

        var app_id = $(event.currentTarget).closest('li').prop('id');

        // show top
        Knack.renderModalTemplate('template_appStorage', template_appStorage, {
        });

        $('#submit-delete-assets').off().click(function(event) {

          event.preventDefault();

          var model = new Backbone.Model();
          model.url = Knack.api_url + '/v1/applications/' + app_id + '/assets/delete';

          Knack.showSpinner();

          model.fetch({
            success: function(model, result, response) {

              log(arguments);

              Knack.hideSpinner();
              Knack.closeModal();

              $('#' + app_id + ' .app-storage').text(Knack.formatNumberForStorage(result.storage));
            }
          });
        });
      });

      $('.app-tasks').off().click(function(event) {

        event.preventDefault();

        var app_id = $(event.currentTarget).data('app-id');
        var app_model = _.find(Knack.account.applications, function(app) {

          return app._id === app_id;
        });

        Knack.renderModalTemplate('template_appTasks', template_appTasks, {}, {app: app_model});
      });
    },

    searchApps: function(apps, selectorSuffix = ``) {
      if (apps.length < 5) {

        $(`#app-search${selectorSuffix}`).hide();

      } else {

        $(`#app-search${selectorSuffix}`).knSearch({
          searchHandler: filterApps,
          cancelHandler: resetList
        });

        function filterApps(q) {
          q = q.toLowerCase();

          $.each($(`#app-list > li`), function() {
            var $this = $(this);
            var $app_title = $this.find('.app-title').text().toLowerCase();
            var $app_description = $this.find('.app-description').text().trim().toLowerCase();

            if ($app_title.indexOf(q) < 0 && $app_description.indexOf(q) < 0) {
              $this.hide();
            } else {
              $this.show();
            }

          });
        }

        function resetList() {
          $(`#app-list > li`).show();
        }
      }
    },

    renderHeights: function(v3 = false) {

      // max height
      var max_height = $(window).height() - (v3 ? 235 : 135)  // leave room for top labels/bottom buttons (for v3 and not)

      $('#app-list').css({
        'max-height': max_height
      });

      if (v3) {

        $(`#context-nav-v3`).css({
          height: `auto`
        })

        $(`#content-wrapper`).css({
          top: 80 + $(`#context-nav-v3`).height()
        })
      }
    },

    handleClickAppOptions: function(event) {

      event.preventDefault();

      const eventTarget = $(event.currentTarget)

      var app = eventTarget.attr('href').substr(1).split('/options/');
      var render_string = event.currentTarget.id;
      var app_id = app[1];
      var $container = $('#app-list').find('#' + app_id);
      var _this = this;

      const isV3 = eventTarget.attr(`data-v3-beta`) === `true`

      // set app
      app = {
        name: $container.find('.app-title a').text().trim(),
        slug: app[0],
        description: $container.find('.app-description').text().trim(),
        id: app_id
      };

      switch (render_string) {

        case 'app-settings-link':
          this.renderAppSettings(app);
          break;

        case 'app-copy-link':
          this.renderCopyApp(app, isV3);
          break;

        case 'app-share-link':
          var builders = new BuildersView({
            el: '#app-options',
            app: app
          });
          builders.render();
          builders.fetch();
          break;

        case 'app-delete-link':
          log('click delete...');
          this.renderDeleteApp(app, isV3);
          break;

        default:
          log('nothing bound to this..');
          break;
      }

    },

    renderAppSettings: function(app) {

      log('appsettings!');

      var _this = this;
      var APP_AND_SLUG_LENGTH_MIN = 1;
      var APP_AND_SLUG_LENGTH_MAX = 128;

      // render default template
      Knack.renderModalTemplate('template_appSettings', template_appSettings, {
        class: 'medium'
      }, {
        app: app,
        slug: app.slug,
        name: app.name,
        description: app.description
      });

      var $app_settings = $('#app-settings');

      var $slug = $app_settings.find('input[name=app_slug]');
      $slug.slugInput({
        type: 'app'
      });

      $app_settings.find('input[name=app_name]').focus();

      // save handler
      $('#submit-app-settings').off().on('click', $.proxy(function(event) {

        event.preventDefault();
        Knack.showSpinner();

        // does this name exist
        var vars = {
          account_id: Knack.account.id,
          app_name: $app_settings.find('input[name=app_name]').val().trim(),
          app_slug: $app_settings.find('input[name=app_slug]').val().trim(),
          app_description: $app_settings.find('textarea[name=app_description]').val().trim()
        };

        var errors = [];

        if (vars.app_name.length < APP_AND_SLUG_LENGTH_MIN) {
          errors.push({
            message: 'Your App Name must contain at least 1 character.'
          });
        }

        if (vars.app_name.length > APP_AND_SLUG_LENGTH_MAX) {
          errors.push({
            message: 'Your App Name cannot exceed 128 characters.'
          });
        }

        if (vars.app_slug.length < APP_AND_SLUG_LENGTH_MIN) {
          errors.push({
            message: 'Your App Slug must contain at least 1 character.'
          });
        }

        if (vars.app_slug.length > APP_AND_SLUG_LENGTH_MAX) {
          errors.push({
            message: 'Your App Slug cannot exceed 128 characters.'
          });
        }

        if (errors.length) {
          // hack to remove loading spinners
          $('#submit-app-settings').removeClass('trigger-load');

          var error_msg = '';
          _.each(errors, function(err) {
            error_msg += '<p><strong>' + err.message + '</strong></p>';
          });

          $.utility_forms.renderMessage($('#app-settings'), error_msg, 'error');
          $('#kn-loading-spinner').hide();

          return false;
        }

        log('vars:'); log(vars);

        // Update all relevant #app-links hrefs. handleClickAppOptions() relies on href to build the app variable
        var $app_link_group = $('#app-settings-link[href*="' + app.id + '"], #app-share-link[href*="' + app.id + '"], #app-copy-link[href*="' + app.id + '"], #app-delete-link[href*="' + app.id + '"]');
        var link = $app_link_group.attr('href');
        $app_link_group.attr('href', link.replace(/^#[^\/]+/i, '#' + vars.app_slug));

        // if no errors enable loading spinners again
        $('#submit-app-settings').addClass('trigger-load');

        var model = new Backbone.Model({
          id: app.id
        });

        model.url = Knack.api + '/apps/' + app.id;

        model.save(vars, {
          success: function(model, response) {
            var $container = $('#app-list').find('#' + app.id);
            $container.find('.app-title a').attr('href', Knack.protocol + 'builder.' + Knack.domain + '/' + Knack.account.slug + '/' + vars.app_slug);
            $container.find('.app-title a strong').text(vars.app_name);
            $container.find('.app-description p').text(vars.app_description);
            $('.close-modal').click();
            Knack.hideSpinner();
          },
          error: function(model, result) {
            $.utility_forms.errorCallback(model, result, $app_settings);
            $('.kn-spinner').hide();
            Knack.hideSpinner();
          }

        });
      }));

      // form submit
      $('#app-settings form').off().on('submit', function(event) {
        event.preventDefault();
        $('#submit-app-settings').click();
      });

    },

    renderCopyApp: function(app, v3 = false) {

      var _this = this;

      // render default template
      Knack.renderModalTemplate('template_copyApp', template_copyApp, {
        app_name: app.name
      }, {
        class: 'medium',
        v3
      });

      log('renderCopyApp()');

      $('#copy-app strong.app-name').text(app.name);
      $(`#copy-app input[name=app_name]`).val(`${app.name} Copy${v3 ? ` from ${moment().format(`MM-DD-YY [at] H-mm [GMT]ZZ`)}` : ``}`)

      var plan = Knack.account.product_plan;

      // don't worry about tasks with starter plans and below
      if (plan.level < 2) {
        $('#copy-app #copy_tasks_label').remove();
        $('#copy-app #pause_tasks_label').remove();
      }

      $('#copy-app [name=copy_tasks]').on('click', (event) => {
        if (event.target.checked) {
          $('#copy-app #pause_tasks_label').show();
          $('#copy-app [name=pause_tasks]').prop('checked', true);
        } else {
          $('#copy-app #pause_tasks_label').hide();
        }
      });

      var $submit_copy_app = $('#submit-copy-app');
      $submit_copy_app.off().on('click', $.proxy(function(event) {

        event.preventDefault();

        if ($submit_copy_app.hasClass('disabled')) {
          return false;
        }

        $submit_copy_app.addClass('disabled');

        Knack.showSpinner();

        let applicationsForCountPurposes = _.filter(Knack.account.applications, (app) => {

          return !app.settings.v3_beta
        })

        if (!v3 && applicationsForCountPurposes.length >= Knack.account.plan_limits.applications) {

          alert('Uh oh, you have reached the maximum number of apps allowed with your plan.  You can upgrade your plan by clicking the Billing link from the left menu.');

          $submit_copy_app.removeClass('disabled');
          $('#modal kn-spinner').hide();

          Knack.hideSpinner();

          return false;
        }

        // does this name exist
        var app_name = $.trim($('input[name=app_name]').val());
        var dupes = _.find(Knack.account.applications.filter(application => application.settings.v3_beta !== true), function(candidate_application) {
          return ($.trim(candidate_application.name).toLowerCase() === app_name.toLowerCase());
        });

        if (dupes) {

          alert('This app name is already being used. Please add a new name.');

          $submit_copy_app.removeClass('disabled');

          Knack.hideSpinner();

          return false;
        }

        // ensure valid app name
        if (!sanitization_helper.sanitizeSlug(app_name)) {

          alert('Please enter a valid app name.\nValid characters: a-z, 0-9, dashes, and underscores.');

          $submit_copy_app.removeClass('disabled');
          $('#modal kn-spinner').hide();

          Knack.hideSpinner();

          return false;
        }

        // check limits
        var max = (plan) ? Knack.account.plan_limits.applications : 3;

        log('comparing max: ' + max + '; apps: ' + $('#app-list').children().length);

        if (!v3 && Knack.account.applications.filter(app => app.settings.v3_beta !== true) >= max && Knack.getProductionMode() === 'production') {

          Knack.closeModal();

          var content = 'Uh oh, you have reached the maximum number of apps allowed';

          if (plan && plan.id && plan.id == 'trial') {
            content += ' during the free trial.  You can subscribe to a plan ';
          } else {
            content += ' with your plan.  You can upgrade your plan ';
          }
          content += 'by clicking the Billing link from the left menu.';

          alert(content);

          Knack.hideSpinner();

          return false;
        }

        var vars = {
          app_name: app_name,
          copy_records: ($('input[name=copy_records]').is(':checked')),
          v3
        };

        if ($('input[name=copy_tasks]').length) {
          vars.copy_tasks = $('input[name=copy_tasks]').is(':checked');
        }
        if ($('input[name=pause_tasks]').length) {
          vars.pause_tasks = $('input[name=pause_tasks]').is(':checked');
        }

        log('vars:');
        log(vars);

        var model = new Backbone.Model();
        model.url = Knack.api + '/apps/' + app.id + '/copy?origin=copy';
        log('url: ' + model.url);

        model.fetch({
          data: vars,
          success: function(model, response) {

            log('success!');
            log(arguments);

            response.app.copying = vars.copy_records;

            // The slim response on app creation doesn't include settings, so add v3_beta flag to local so it displays in list
            response.app.settings = {
              v3_beta: v3
            }

            // add to account
            Knack.account.applications.push(response.app);

            if (v3) {

              _this.renderV3Apps()
            } else {

              _this.renderYourApps();
            }

            Knack.hideSpinner();
            Knack.closeModal();

            Knack.renderModalTemplate('template_copyAppComplete', template_copyAppComplete, {
              copy_records: vars.copy_records
            }, {
              class: 'medium'
            });

            if (!vars.copy_records) {
              $('#records-warning').remove();
            }
          },
          error: function(model, response) {

            alert(`There was an error with your request. Please check to make sure the app name and slug are unique.`)

            $submit_copy_app.removeClass(`disabled`);

            Knack.hideSpinner();

            return false;
          }
        });
      }));

      // TODO: Determine if this is still being used. Brief search didn't reveal anything
      // form submit
      $('#copy-app form').off().on('submit', function(event) {
        event.preventDefault();
        $submit_copy_app .click();
      });

    },

    renderDeleteApp: function(app, v3) {

      var _this = this;

      // render
      Knack.renderModalTemplate('template_deleteApp', template_deleteApp, {
        class: 'small'
      }, {
        app: app
      });

      log('handleClickDeleteApp()');

      new Vue({
        el: '#kn-input-app-name',
        data: {
          appName: ''
        },
        watch: {
          appName: function(appName) {

            if (appName.toLowerCase().replace(/\s+/g, ' ') !== app.name.toLowerCase().replace(/\s+/g, ' ')) {

              return $('#submit-delete-app').addClass('disabled')
            }

            return $('#submit-delete-app').removeClass('disabled')
          }
        }
      });

      // save handler
      $('#submit-delete-app').unbind().bind('click', $.proxy(function(event) {

        event.preventDefault();

        if ($('#submit-delete-app').hasClass('disabled')) {

          return
        }

        log('deleting! app_id: ' + app.id);

        Knack.showSpinner();

        var model = new Backbone.Model();
        model.url = Knack.api + '/applications/' + app.id + '/delete';

        model.save(null, {
          success: function(model, response) {

            log('back from destroy()');
            log(arguments);

            Knack.hideSpinner();

            // close model
            Knack.closeModal();

            // remove from Account
            log('app count before: ' + Knack.account.applications.length);
            Knack.account.applications = _.reject(Knack.account.applications, function(a) {
              return a._id == app.id;
            });
            log('app count after: ' + Knack.account.applications.length);

            // scrolltop
            var top = $('#app-list').scrollTop();

            if (v3) {

              _this.renderV3Apps()
            } else {

              _this.renderYourApps();
            }

            $('#app-list').scrollTop(top);
          }
        });

      }, this));
    },

    renderSecuritySettings: function() {

      $('#body').html(Knack.render('securitySettingsTemplate', securitySettingsTemplate))

      $('#app-menu .active').removeClass('active');
      $('#app-menu a[href="#security"]').addClass('active');

      const settings = _.clone(Knack.account.settings)

      if (!settings.security) {

        settings.security = {}
      }

      if (settings.security.builder_inactivity_timeout_enabled) {

        $('input[name=inactive-minutes-checkbox]').attr('checked', 'checked')
      }

      if (settings.security.builder_inactivity_message && !_.isEmpty(settings.security.builder_inactivity_message)) {

        $('textarea[name=inactive-message]').val(settings.security.builder_inactivity_message)
      }

      if (settings.security.builder_inactivity_timeout) {

        $('select[name=inactive-minutes]').val(settings.security.builder_inactivity_timeout)
      }

      if (!settings.builder_inactivity_timeout_enabled) {

        $('#inactive-minutes-message-wrapper').hide()
      }

      $('input[name=inactive-minutes-checkbox]').change(() => {

        $('#inactive-minutes-message-wrapper').slideToggle()
      });

      $('#submit-update-auth').click((event) => {

        event.preventDefault()

        settings.security.builder_inactivity_timeout_enabled = $('input[name=inactive-minutes-checkbox]').attr('checked') === 'checked'
        settings.security.builder_inactivity_message = $('textarea[name=inactive-message]').val()
        settings.security.builder_inactivity_timeout = parseInt($('select[name=inactive-minutes]').val(), 10)

        const update = {
          settings: settings
        }

        log('saving updated settings')

        log(update)

        let model = new Backbone.Model()
        model.isNew = () => false
        model.url = `${Knack.api_url}/v1/accounts/${Knack.account.id}`

        model.save(update, {
          success: (model, response) => {

            log(response)

            Knack.hideSpinner()
          }
        })
      })
    },

    renderAccount: function() {
      var _this = this;

      this.render();

      var status = Knack.user.getAccountStatus();
      var plan = Knack.account.product_plan;

      // show menu
      $('#app-menu .active').removeClass('active');
      $('#app-menu a[href="#account"]').addClass('active');

      // render account
      $('#body').html(Knack.render('template_account', template_account, {
        account: Knack.account.slug,
        apps: Knack.account.applications
      }));

      // account form
      var view_model = new Form(Knack.account);

      view_model.setView({
        title: '',
        description: '',
        key: 'account_settings',
        confirmation: {
          type: 'show_text',
          confirmation_text: 'Your account information has been updated.'
        },
        submit_button_text: 'Save changes',
        inputs: [
          {
            type: 'short_text',
            id: 'name',
            field: {
              'key': 'name'
            },
            label: 'Account Name'
          },
          {
            type: 'short_text',
            id: 'slug',
            field: {
              'key': 'slug'
            },
            label: 'URL'
          }
        ]
      });
      view_model.url = Knack.api;

      this.form_view = new FormView({
        'model': view_model,
        'el': '#account-settings'
      });
      this.form_view.render();
      this.form_view.model.on('sync', this.handleUserUpdated, this);

      $(document).on('knack-view-render.account_settings', function(event, view) {
        $('input[name=slug]').slugInput();
      });

      // initial render
      $('input[name=slug]').slugInput();

      let freeze_date

      // freeze
      if (Knack.account.billing.last_freeze_date) {

        freeze_date = new Date(Knack.account.billing.last_freeze_date)
      }

      const year = new Date()

      year.setFullYear(year.getFullYear() - 1)

      if (plan && plan.id && plan.id !== 'trial' && status !== 'frozen' && (!freeze_date || freeze_date < year)) {

        // add freeze pricing
        const level = plan.level
        const freezes = [9,19,29,49,79,149]

        log('freeze plan: ' + level)
        log(plan)

        $('.freeze-price').text('$' + freezes[level-1])
      } else {

        $('#accordion-freeze').remove()
      }

      // accordions
      $('#body .accordion-section').accordion();

      // bind freeze
      $('#freeze-account-link').off().on('click', $.proxy(this.handleClickFreezeAccount, this));

      // add text
      //$('#slug').after('<span>&nbsp;.' + Knack.domain + '</span>');

      return this;
    },

    renderUser: function() {

      this.render();

      // show menu
      $('#app-menu .active').removeClass('active');
      $('#app-menu a[href="#user"]').addClass('active');

      // render account
      $('#body').html(Knack.render('user_template', user_template));

      // user obj
      var user = {
        id: Knack.user.id,
        email: {
          email: Knack.user.get('email')
        },
        name: {
          first: Knack.user.get('first_name'),
          last: Knack.user.get('last_name')
        }
      };

      // account form
      var view_model = new Form(user);
      view_model.url = Knack.api + '/session/' + Knack.user.id;
      view_model.setView({
        title: '',
        description: '',
        confirmation: {
          type: 'show_text',
          confirmation_text: 'Your user information has successfully been updated.'
        },
        submit_button_text: 'Save changes',
        inputs: [
          {
            type: 'name',
            id: 'name',
            field: {
              'key': 'name'
            },
            label: 'Name'
          },
          {
            type: 'password',
            ignore_confirmation: true,
            id: 'old_password',
            field: {
              'key': 'old_password'
            },
            label: 'Current Password'
          },
          {
            type: 'email',
            id: 'email',
            field: {
              'key': 'email'
            },
            label: 'Email Address'
          }
        ]
      });

      var form_view = new FormView({
        'model': view_model,
        'el': '#user-profile'
      });//, 'listener': $.proxy(this.handleSubmitForm, this)});
      form_view.render();
      form_view.model.on('sync', this.handleUserUpdated, this);

      // password form
      var view_model = new Form(user);
      view_model.url = Knack.api + '/session/' + Knack.user.id;
      view_model.setView({
        title: '',
        description: '',
        confirmation: {
          type: 'show_text',
          confirmation_text: 'Your password has successfully been updated.'
        },
        submit_button_text: 'Save changes',
        inputs: [
          {
            type: 'password',
            ignore_confirmation: true,
            id: 'old_password',
            field: {
              'key': 'old_password'
            },
            label: 'Current Password'
          },
          {
            type: 'password',
            id: 'password',
            field: {
              'key': 'password'
            },
            label: 'New Password'
          }
        ]
      });

      var form_view = new FormView({
        'model': view_model,
        'el': '#user-password'
      });//, 'listener': $.proxy(this.handleSubmitForm, this)});
      form_view.render();

      // get rid of hints
      $('#body .hint').remove();

      // accordions
      $('#body .accordion-section').accordion();

      // enable/disable/reset 2fa
      $('#enable-2fa-link').off().on('click', $.proxy(this.handleClickEnable2fa, this))
      $('#disable-2fa-link').off().on('click', $.proxy(this.handleClickDisable2fa, this))
      $('#reset-2fa-secret-key').off().on('click', $.proxy(this.handleClickReset2faSecretKey, this))

      return this;
    },

    renderBilling: function() {

      log('renderBilling, user:');
      log(Knack.user);
      var status = Knack.user.getAccountStatus();

      $('#app-menu .active').removeClass('active');
      $('#app-menu a[href="#billing"]').addClass('active');

      // if delinquent
      if (Knack.user.getBilling() && Knack.user.getBilling().status == 'delinquent' && Knack.user.getBillingID() && status != 'beta') {
        this.renderPayment('delinquent');

      // if beta
      } else if (status == 'beta' || status == 'pending') {
        this.renderPlan('beta');

      // frozen
      } else if (status == 'frozen') {
        this.renderBillingSummary();
        this.renderBillingFrozen();

      // if active display summary
      } else {
        this.renderBillingSummary();
      }
    },

    renderPlan: function(type) {

      const typesThatIgnoreHipaaRedirect = [`beta`, `pending`]

      // if the user is on a HIPAA plan
      // redirect to the main billing page
      if (Knack.isHIPAA() && !typesThatIgnoreHipaaRedirect.includes(type)) {

        this.renderBilling()

        return Knack.router.navigate(`billing`, false)
      }

      var type = type || 'active';
      var _this = this;

      this.render();
      Knack.showSpinner();

      $('#app-menu .active').removeClass('active');
      $('#app-menu a[href="#billing"]').addClass('active');

      var changePlan = function() {

        Knack.showSpinner();
        var model = new Backbone.Model();
        model.url = Knack.api + '/billing/plan';
        model.save({
          plan: _this.plan.id
        }, {
          success: function() {

            Knack.hideSpinner();

            // show success message
            $('#content-wrapper').html('<div class="kn-message success"><p>Your plan has successfully been updated.  The price difference will be included in your next invoice.</p></div>');
          }
        });
      };

      // show and activate apps
      $('#menu-content a[href="#billing"]').addClass('active');

      var model = new Backbone.Model();

      model.url = Knack.api + '/billing/plans';

      model.fetch({
        success: function(model, resp) {

          Knack.hideSpinner();

          _this.plans = resp.plans;

          $('#body').html(Knack.render('plan_template', plan_template, {
            plans: resp.plans
          }));

          if (type === 'beta') {

            $('#beta-warning').show();
            var deadline = new Date(Knack.user.getBetaDeadline());
            var today = new Date();
            var time_left = (deadline.getTime() - today.getTime())/(24*60*60*1000);
            var days_left = Math.floor(time_left);

            log('deadline: ' + deadline + '; today: ' + today);
            log('time_left: ' + time_left + '; days_left: ' + days_left);

            if (today.getHours()/24 + (time_left - days_left) > 1) {
              days_left++;
            }

            var left = '';
            if (days_left == NaN || days_left < 0) {
              $('#beta-warning').text('Your free trial has expired! Select a plan below to keep building your app.');
            } else {
              if (days_left === 0) {
                left = 'today';
              } else if (days_left == 1) {
                left = 'in only one day';
              } else {
                left = 'in only ' + days_left + ' days';
              }
              $('.days-left').text(left);
            }
          }

          // toggle enterprise/basic plans
          $('#show-enterprise-plans, #show-basic-plans').click(function(event) {
            $('li.enterprise, li.basic, .plan-toggle').toggle();
          });

          // click plan handler
          $('.plan-choose a').bind('click', $.proxy(function(event) {

            event.preventDefault();

            var plan_id = $(event.currentTarget).attr('id');
            _this.plan = _.find(_this.plans, function(plan) {
              return plan.id == plan_id;
            });
            var plan = _this.plan;

            log('click!, plan: ' + plan_id + '; type: ' + type); log(_this.plan);

            var max = Knack.account.plan_limits.applications;

            var account_apps = Knack.account.applications.filter(app => app.settings.v3_beta !== true).length;

            if (account_apps > max) {

              alert('You currently have more apps (' + account_apps + ') than this plan allows (' + max + '). Please remove some apps to get under this limit before selecting this plan.')

              return
            }

            // check shared buidlers
            if (Knack.account.counts && Knack.account.counts.builders && plan.builders && Knack.account.counts.builders > plan.builders) {
              alert('You currently have more shared builders (' + Knack.account.counts.builders + ') than this plan allows (' + plan.builders + '). Please remove some shared builders before selecting this plan.');
              return;
            }

            if (_this.plan.level < 3) {

              Knack.showSpinner();

              var model = new Backbone.Model();
              model.url = Knack.api + `/billing/downgrade/${_this.plan.id}`;
              model.fetch({

                success: function(model, res) {

                  log(arguments);
                  Knack.hideSpinner();

                  log('BACK, downgrades for plan level : ' + _this.plan.level); log(res.downgrades);

                  if (res.downgrades && res.downgrades.length) {

                    // if pro we only care about sso
                    if (_this.plan.level === 2) {
                      res.downgrades = _.filter(res.downgrades, function(downgrade) {
                        return downgrade.type === 'sso';
                      });
                    }

                    // see if any downgrades remain, if not render payment
                    if (!res.downgrades.length) {
                      if (type == 'active') {

                        changePlan();
                      } else {

                        _this.renderPayment(type);
                        Knack.router.navigate('billing/payment', false);
                      }
                      return;
                    }

                    Knack.renderModalTemplate('template_billingDowngrade', template_billingDowngrade, {
                      class: 'small'
                    }, {
                      downgrades: res.downgrades
                    });
                    $('#submit-downgrade-plan').click(function(event) {
                      event.preventDefault();
                      Knack.closeModal();
                      // if active
                      if (type == 'active') {
                        changePlan();
                      } else {
                        _this.renderPayment(type);
                        Knack.router.navigate('billing/payment', false);
                      }
                    });

                  } else {
                    // if active
                    if (type == 'active') {

                      changePlan();
                    } else {
                      _this.renderPayment(type);
                      Knack.router.navigate('billing/payment', false);
                    }
                  }
                }

              });

            } else {
              // if active
              if (type == 'active') {
                changePlan();
              } else {
                _this.renderPayment(type);
                Knack.router.navigate('billing/payment', false);
              }

            }

          }));
        }
      });
    },

    getBillingInfoAndEmail: function(view_model) {

      var billing_info = '';

      var billing = view_model.get('billing');

      var has_billing_info = !_.isUndefined(billing.custom_info) && !_.isNull(billing.custom_info) && typeof billing.custom_info === 'string' && billing.custom_info.trim().length !== 0;

      var has_billing_email = !_.isUndefined(billing.email) && !_.isNull(billing.email) && typeof billing.email === 'string' && billing.email.trim().length !== 0;

      var billing_email = has_billing_email ? billing.email : Knack.user.get('email');

      billing_info = has_billing_info ? billing.custom_info : Knack.user.get('first_name') + ' ' + Knack.user.get('last_name');

      billing_info = has_billing_email ? billing_info : billing_info + '\n' + Knack.user.get('email');

      return {
        billing_info: billing_info,
        billing_email: billing_email
      };

    },

    setBillingInfoAndEmail: function(view_model) {

      var billing_info_and_email = this.getBillingInfoAndEmail(view_model);

      view_model.set({
        billing_info: billing_info_and_email.billing_info
      });

      return view_model.set({
        billing_email: {
          email: billing_info_and_email.billing_email
        }
      });
    },

    renderBillingSettings: function() {

      var type = type || 'active';
      var _this = this;

      this.render();

      $('#app-menu .active').removeClass('active');
      $('#app-menu a[href="#billing"]').addClass('active');

      $('#body').html(Knack.render('template_billingSettings', template_billingSettings, {
      }));

      log('renderBillingSettings!!!!!!!!!!!');

      // billing form
      var view_model = new Form(Knack.account);
      view_model.setView({
        title: '',
        description: '',
        confirmation: {
          type: 'show_text',
          confirmation_text: 'Your billing information has been updated.'
        },
        submit_button_text: 'Save changes',
        inputs: [
          {
            type: 'email',
            id: 'billing_email',
            field: {
              'key': 'billing_email'
            },
            label: 'Billing Email',
            instructions: 'We\'ll send receipts to this email address'
          },
          {
            type: 'paragraph_text',
            id: 'billing_info',
            field: {
              'key': 'billing_info'
            },
            label: 'Billing Info',
            instructions: 'To be displayed in the receipts in billing history'
          }
        ]
      });

      _this.setBillingInfoAndEmail(view_model);

      view_model.url = Knack.api;

      var form_billing_view = new FormView({
        'model': view_model,
        'el': '#billing-settings'
      });

      form_billing_view.render();

    },

    renderBillingSummary: function() {

      var _this = this;

      this.render();
      Knack.showSpinner();

      $('#app-menu .active').removeClass('active');
      $('#app-menu a[href="#billing"]').addClass('active');

      var model = new Backbone.Model();
      model.url = Knack.api + '/billing/overview';
      model.fetch({
        success: function(model, resp) {
          log('success!!!! fetched customer');
          log(resp);
          Knack.hideSpinner();

          if (!_.has(resp.customer, `subscription`) && _.has(resp.customer, `subscriptions`)) {

            resp.customer.subscription = resp.customer.subscriptions.data[0]
          }

          $('#body').html(Knack.render('billing_template', billing_template, {
            customer: resp.customer,
            upcoming: resp.upcoming
          }));

          var status = Knack.user.getAccountStatus();
          if (status == 'frozen') {
            _this.renderBillingFrozen();
          }

          $(`.kn-dropdown`).knDropdown({
            attachment: `top left`,
            targetAttachment: `top right`,
            targetOffset: `-14px 12px`,
            hover: true
          })
        }
      });

      return this;
    },

    renderBillingFrozen: function() {

      var _this = this;

      $('#billing-plan').html(Knack.render('template_unfreeze', template_unfreeze));

      // save handler
      $('#submit-unfreeze').off().on('click', function(event) {
        event.preventDefault();
        Knack.showSpinner();

        var model = new Backbone.Model({
          id: Knack.account.session.user.id
        });
        model.urlRoot = Knack.api + '/unfreeze';//+ '/applications';

        model.save({
        }, {
          success: function(model, response) {

            log('back from freeze()');
            log(arguments);

            Knack.hideSpinner();

            Knack.account.status = 'active';
            _this.renderBilling();
            Knack.router.navigate('billing', false);
          }
        });

      });

      return this;
    },

    renderInvoices: function() {

      var _this = this;

      _this.render();
      Knack.showSpinner();

      // show and activate apps
      $('#app-menu .active').removeClass('active');
      $('#app-menu a[href="#billing"]').addClass('active');

      var model = new Backbone.Model();
      model.url = Knack.api + '/billing/invoices';
      model.fetch({
        success: function(model, resp) {


          Knack.hideSpinner();

          if (!resp) {

            return $('#body').html(Knack.render('invoices_template', invoices_template, {
              invoices: []
            }));
          }

          $('#body').html(Knack.render('invoices_template', invoices_template, {
            customer: resp.customer,
            invoices: resp.invoices.data
          }));

          // add data for printing
          var invoices = resp.invoices.data;
          _.each(invoices, function(invoice) {
            $('.' + invoice.id).data('invoice', invoice);
          });

          $('.invoice-email').click(function(event) {
            event.preventDefault();

            Knack.showSpinner();

            var invoice = $(event.currentTarget).closest('.invoice').data('invoice');
            var model = new Backbone.Model();
            model.url = Knack.api + '/billing/invoices/email';
            model.save({
              invoice: invoice
            }, {
              success: function(model, resp) {
                Knack.hideSpinner();
              }
            });

          });

          $('.invoice-pdf').click(function(event) {
            event.preventDefault();

            Knack.showSpinner();

            var invoice = $(event.currentTarget).closest('.invoice').data('invoice');

            var billing_info_and_email = _this.getBillingInfoAndEmail(new Backbone.Model(Knack.account));

            // send to template!

            var pdf_data = {
              billing_info: billing_info_and_email.billing_info.replace(/\n/g, '<br />'),
              user: {
                name: Knack.user.get('first_name') + ' ' + Knack.user.get('last_name'),
                email: Knack.user.get('email')
              },
              invoice: invoice
            };

            // render
            var pdf_html = Knack.render('invoice_template', invoice_template, pdf_data);

            // set vars to post
            var data_vars = {
              html: encodeURIComponent(pdf_html),
              key: '2012kpg',
              filename: 'invoice_' + invoice.id + '.pdf'
            };

            // post!
            $.ajax({
              type: 'POST',
              url: 'https://pdfgen.knack.com/',
              data: data_vars,
              dataType: 'json',
              success: function(response) {
                log('SUCCESS generating PDF: ');
                log(response);

                Knack.hideSpinner();
                //window.open(response.url, '_blank');
                window.location.href = response.url;
              },
              error: function() {
                log('ERROR generating PDF:'); log(arguments);
              }
            });
          });
        }
      });

      return _this;
    },

    renderAddressAutocomplete: function () {

      const addressHelper = new AddressHelper()

      addressHelper.renderAddressAutoCompleteEntity($(`.billing-payment`), {}, [{
        key: `billing`
      }], `account-billing`)
    },

    renderPayment: async function(type) {

      var type = type || 'active'; // beta, delinquent

      // handle a rerfresh on payment
      if (Knack.user.getAccountStatus() && Knack.user.getAccountStatus() == 'beta' && type == 'active') {
        Knack.router.navigate('billing', true);
        return false;
      }

      this.render();

      // show and activate apps
      $('#app-menu .active').removeClass('active');
      $('#app-menu a[href="#billing"]').addClass('active');

      // This can be null on an account object, so set defaults to make templating easier
      const paymentTemplateValues = Object.assign({
        account: Knack.account.slug,
        apps: Knack.account.applications,
        type: type,
        plan: this.plan
      }, {
        street: ``,
        city: ``,
        state: ``,
        zip: ``,
        country: ``
      }, Knack.account.billing.billing_address)

      $('#body').html(Knack.render('payment_template', payment_template, paymentTemplateValues));

      $(`#country`).val(paymentTemplateValues.country)

      await CaptchaHelper.renderCaptcha({increment: false, mode: CaptchaHelper.modeOptions.DASHBOARD})
      this.renderAddressAutocomplete()

      var stripe_key = (Knack.getProductionMode() != 'production') ? 'pk_test_SlEXJDSvCPjPSmRHtLlJMMta' : 'pk_iSR05mzpHIbLJHdapwSxqUOwddE6B';
      const stripe = Stripe(stripe_key)

      var _this = this;

      const stripeResponseHandler = async (response) => {

        await CaptchaHelper.renderCaptcha({ mode: CaptchaHelper.modeOptions.DASHBOARD })

        if (response.error) {

          Knack.hideSpinner();

          // re-enable the submit button
          $('.submit-button').removeAttr('disabled');

          // show the errors on the form
          $('.payment-errors').html('<div class="alert-message block-message error"><p><strong>' + response.error.message + '</strong></p></div>');

        } else {

          log('stripe response:');
          log(arguments);
          var form$ = $('#payment-form');

          var model = new Backbone.Model();
          model.url = Knack.api + '/billing/card';

          var data = {
            stripe_token: response.setupIntent.payment_method,
            address: {
              line1: form$.find(`#street`).val(),
              city: form$.find(`#city`).val(),
              state: form$.find(`#state`).val(),
              postal_code: form$.find(`#zip`).val(),
              country: form$.find(`#country`).val()
            }
          }

          const taxExempt = form$.find(`#sales-tax-exempt`).is(`:checked`)

          if (_this.plan) {
            data.plan = _this.plan.id;

            log('adding plan to data: ' + _this.plan.id);
            log(_this.plan);
          }

          model.save(data, {
            success: function(model, resp) {

              log('success:'); log(arguments);

              Knack.hideSpinner();

              // show success message
              $('#content-wrapper').html(Knack.render(`paymentUpdatedTemplate`, paymentUpdatedTemplate, {
                taxExempt
              }));

              const hasAdwordsCookie = $.cookie(`_gcl_aw`)
              const planPrice = _this.plan.price ? _this.plan.price : 0
              const annualRate = _.has(_this.plan, `id`) && _this.plan.id.includes(`_y`) ? planPrice : planPrice * 12

              // track Google Adwords GCLID cookie from beta user's successful payment
              if (type === `beta` && hasAdwordsCookie) {

                // dataLayer is attached to the window via Google Tag Manager if present
                window.dataLayer = window.dataLayer || []

                const dataObject = {
                  event: `subscription_started_adwords`,
                  value: annualRate
                }

                window.dataLayer.push(dataObject)
              }

              if (type === `beta`) {

                analytics.page('Subscription Started', {
                  path: '/billing/payment/success',
                  gclid: Knack.account.gclid,
                  clientid: Knack.account.clientid,
                  plan_name: _this.plan.name,
                  plan_id: _this.plan.id,
                  value: annualRate
                })
              }

              // update
              if (type == 'beta' || type == 'delinquent') {
                log('updating user!, setting type: ' + resp.account.status);
                //Knack.user.set('type', resp.user.type);
                //Knack.user.set('billing_id', resp.user.billing_id);

                Knack.account = resp.account;
              }
            },
            error: function(status, response) {

              log('error:')
              log(arguments)

              Knack.hideSpinner()

              $('.submit-button').removeAttr('disabled')

              $('.payment-errors').html('<div class="alert-message block-message error"><p><strong>It looks this charge was rejected by your bank. Unfortunately we are not provided with additional information on why. You may need to contact your bank to see if they are blocking the charge or you could try another card.</strong></p></div>')
            }
          });
        }
      }

      const elements = stripe.elements()

      const options = {
        style: {
          fontSize: '14px',
          iconStyle: 'solid',
          hideIcon: false
        }
      }

      const stripeElementChangeHandler = (elementId) => {

        return (event) => {

          const elem = $(elementId)

          if (event.error) {

            return elem.addClass(`error`)
          }

          elem.removeClass(`error`)
        }
      }

      const cardElement = elements.create(`cardNumber`)
      cardElement.mount(`#card-number`)
      cardElement.on(`change`, stripeElementChangeHandler(`#card-number`))

      const cardExpiration = elements.create(`cardExpiry`)
      cardExpiration.mount(`#card-expiration`)
      cardExpiration.on(`change`, stripeElementChangeHandler(`#card-expiration`))

      const cardCvc = elements.create(`cardCvc`)
      cardCvc.mount(`#card-cvc`)
      cardCvc.on(`change`, stripeElementChangeHandler(`#card-cvc`))

      $('#payment-form').submit(async function(event) {

        event.preventDefault()

        if (CaptchaHelper.captchaResponse === false) {

          return false
        }

        // clear any errors
        $('.payment-errors').empty()
        $('.error').removeClass('error')

        const street = $(`#street`).val()
        const city = $(`#city`).val()
        const state = $(`#state`).val()
        const zip = $(`#zip`).val()
        const country = $(`#country`).val()

        // All address parts are required
        if (!street || !city || !zip || !country || (country === `US` && !state)) {

          $('.payment-errors').html('<div class="alert-message block-message error"><p><strong>Billing Address is required.</strong></p></div>')

          return false
        }

        Knack.showSpinner()

        // disable the submit button to prevent repeated clicks
        $('.submit-button').attr('disabled', 'disabled');

        const secret = await new Promise((resolve, reject) => {

          const setupIntentModel = new Backbone.Model()

          setupIntentModel.url = Knack.api + `/billing/createSetupIntent`

          setupIntentModel.fetch({
            success: ({ attributes }) => resolve(attributes.secret),
            error: reject
          })
        })

        const stripeResponse = await stripe.confirmCardSetup(secret, {
          payment_method: {
            card: cardElement
          }
        })

        stripeResponseHandler(stripeResponse)
      });

      return this;
    },

    renderLoginScene: function(msg) {

      Builder.renderLoginScene(msg);
    },

    renderForgotPassword: function(msg) {

      log('Dasbhoard.renderForgotPassword()');

      Builder.renderForgotPassword(msg);
    },

    renderResetPassword: function(token) {

      log('Dasbhoard.renderResetPassword()');
      log(arguments);

      Builder.renderResetPassword(token);
    },

    checkAuthentication: function() {

      log('checkAuthentication(); user: ' + Knack.user.id + '; isNew: ' + Knack.user.isNew());
      log(Knack.user);

      // show login form if it doesn't exist or if the profile is no good
      //if (Knack.session.user == null || !Knack.session.user || typeof(Knack.session.user) == 'undefined') {
      if (!Knack.user.id || Knack.user.isNew()) {
        log('RENDER LOGIN SCENE');
        this.renderLoginScene();
        return false;
      }
      log('returning true!');
      return true;
    },

    renderCopyAppToV3: function() {

      if (!this.render()) {

        return
      }

      const _this = this

      if ($('#app-list').length === 0) {

        this.renderV3Apps()
      }

      Knack.renderModalTemplate(`templateCopyAppToV3`, templateCopyAppToV3, {}, {})

      window.v3Copy = new Vue({
        el: `#copy-app-v3`,
        data () {

          return {
            apps: Knack.account.applications.filter(app => app.settings.v3_beta !== true),
            selectedAppId: null
          }
        },
        computed: {

          selectedApp () {

            const app = Knack.account.applications.find(app => app._id === this.selectedAppId)

            // Normalize to existing copy app format from handleClickAppOptions
            return {
              name: app.name,
              slug: app.slug,
              description: app.description,
              id: app._id
            }
          }
        },
        methods: {
          onClickCopyApp () {

            Knack.closeModal()

            _this.renderCopyApp(this.selectedApp, true)
          }
        }
      })
    },

    renderAddApp: function(app_id, v3 = false) {

      var _this = this;

      if (this.render()) {

        log('renderAddApp!');

        // show and activate apps
        $('#menu-content a[href="#apps"]').addClass('active');

        // if direct link then render apps
        if (!$('#app-list').length) {
          this.renderYourApps();
        }

        // layover!
        var options = {
        };
        var data = {
          v3
        };
        Knack.renderOverlayTemplate('template_addApp', template_addApp, options, data);

        $('input[name=slug]').slugInput({
          type: 'app'
        });

        //$('#content-wrapper').html( Knack.render('template_addApp', template_addApp) );

        // app_id means this was coming from a link to automatically install a template app
        if (app_id) {

          // auto click
          var intro = '<p class="center">Loading Template...</p>';
          //$('.overlay-body').children().hide();
          $('#app-add-intro').html(intro);
          _this.renderTemplateList(app_id, v3);

        } else {

          // events
          $('#app-template-link').off().on('click', function(event) {

            _this.renderTemplateList(null, v3);
          });

          var app_scratch_click_handler = function() {

            $('.overlay-title .back').fadeIn().find('span.label').text('Start');
            $('.overlay-title .back').off().on('click', function() {
              _this.renderAddApp(app_id, v3);
            });

            _this.renderAddAppProperties(null, null, null, v3);
          };

          $('#app-scratch-link').off().on('click', app_scratch_click_handler);

          if (Knack.isHIPAA()) {

            app_scratch_click_handler();
          }
        }

        return this;
      }
    },

    renderTemplateList: function(app_id, v3 = false) {
      log('renderTemplateList()!');

      var _this = this;

      $('.overlay-title .back').fadeIn().find('span.label').text('Start');
      $('.overlay-title .back').off().on('click', function() {
        _this.renderAddApp();
      });

      /*
      $('#modal').html( '<h1><span class="title">Alert</span><span class="close-modal">close</span></h1><div class="wrapper"><p>Template apps coming soon!</p></div>' );
      $('#modal').addClass('small');
      $('#modal').reveal({animation: 'fade', animationspeed: 100, dismissModalClass: 'close-modal'});
      */

      var model = new Backbone.Model();
      model.url = Knack.api + '/applications/examples';

      Knack.showSpinner();

      model.fetch({
        success: function(model, data) {
          log('got apps!');
          log(arguments);

          Knack.hideSpinner();

          // render apps!
          //$('#content-wrapper').html( Knack.render('appTemplates_template', appTemplates_template, {apps: data.apps}) );
          //Knack.renderModalTemplate( 'appTemplates_template', appTemplates_template, {class:'ninetwenty'}, {apps: data.apps} );

          var $body = $('.overlay-body');

          $body.find('.active').removeClass('active').hide();

          $body.find('#app-add-templates').html(Knack.render('appTemplates_template', appTemplates_template, {
            apps: data.apps
          }));
          $body.find('#app-add-templates').addClass('active').show();

          // set height
          //$('#example-apps').height($(window).height() - 190)

          function filterTemplates(q) {
            q = q.toLowerCase();

            $.each($('#example-apps > li'), function() {
              var $this = $(this);
              var $app_title = $this.find('h3').text().toLowerCase();
              var $app_description = $this.find('p.small').first().text().trim().toLowerCase();

              $app_title.indexOf(q) < 0 && $app_description.indexOf(q) < 0 ? $this.hide() : $this.show();

            });
          }

          function resetTemplates() {
            $('#example-apps > li').show();
          }

          function categorySearch(q) {
            q = $(q.currentTarget).text();

            $('#app-template-filter > .current-filter').text(q);

            q = q.trim().toLowerCase();

            $.each($('#example-apps > li'), function() {
              var $this = $(this);
              var $app_categories = $this.attr('class').split(' ');

              $app_categories.indexOf(q) < 0 ? $this.hide() : $this.show();

            });

            if (q === 'show all') {
              $('#example-apps > li').show();
            }
          }

          // template search
          $('#app-template-search').knSearch({
            searchHandler: filterTemplates,
            cancelHandler: resetTemplates
          });

          $('#app-template-filter').knDropdown({
            attachment: 'top center',
            targetAttachment: 'bottom right',
            targetOffset: '9px -6px',
            linkHandler: categorySearch
          });

          // handle add
          $('#example-apps .select').click(function(event) {

            event.preventDefault();
            Knack.closeModal();

            var template_name = $(event.currentTarget).closest('li').find('h3').html();
            var template_slug = $(event.currentTarget).closest('li').attr('data-template-slug');

            // get id
            var app_id = $(event.currentTarget).attr('href').substr(1);
            var slug = Knack.account.slug;

            log('selected app: ' + app_id + '; slug: ' + slug + 'template_name: ' + template_name);

            $('.overlay-title .back span.label').text('Select template');
            $('.overlay-title .back').off().on('click', function() {
              _this.renderTemplateList();
            });

            _this.renderAddAppProperties(app_id, template_name, template_slug, v3);

          });

          // check if a template ID was included, if that's the case then let's click on it to proceed
          if (app_id) {
            log('click the app ID:::::::::: ' + app_id);
            $('#example-apps a[href="#' + app_id + '"]').click();

          }
        }
      });
    },

    renderAddAppProperties: function(app_id, template_name, template_slug, v3 = false) {

      var _this = this;

      if (template_name) {

        $(`#app-type p`).html(template_name)

        $(`input[name=name]`).val(template_name)
      } else {

        $(`#app-type`).remove()
      }

      var $body = $('.overlay-body');
      $body.find('.active').removeClass('active').hide();
      $body.find('#app-add-properties').addClass('active').show();
      $('input[name=slug]').css('padding-left', $('#url-overlay').width() + 17);
      var slug_set = false;

      $('input[name=name]').keyup(function() {
        if (slug_set) {
          return;
        }

        $('input[name=slug]').val(sanitization_helper.sanitizeSlug($('input[name=name]').val()));
      }).keyup();

      $('input[name=slug]').keyup(function() {
        slug_set = true;
      });

      $('input[name=name]').focus();

      // Submit add an app form handler
      $('#add-app-form').off().on('submit', function(event) {
        event.preventDefault();
        $(event.currentTarget).addClass('is-submitting'); // Disable the form
        _this.handleAddApp(app_id, template_slug, v3);
      });

    },

    handleAddApp: function(app_id, template_slug, v3) {

      log('handleAddApp()');
      var APP_AND_SLUG_LENGTH_MIN = 1;
      var APP_AND_SLUG_LENGTH_MAX = 128;

      $('#add-app-form').find('.kn-message').remove();

      var name = this.app_name = $.trim($('input[name=name]').val());
      var slug = this.app_slug = $.trim($('input[name=slug]').val());

      log('handleAddApp(); name: ' + name + '; slug: ' + slug);

      // validate
      var errors = [];

      // not blank
      if (!name) {
        errors.push({
          message: 'Give your app a name.'
        });
      }

      // validate name length
      if (name.length > APP_AND_SLUG_LENGTH_MAX) {
        errors.push({
          message: 'Your App Name cannot exceed 128 characters.'
        });
      }

      // not blank
      if (!slug) {
        errors.push({
          message: 'Give your app a URL.'
        });
      }

      // validate slug length
      if (slug.length > APP_AND_SLUG_LENGTH_MAX) {
        errors.push({
          message: 'Your App URL cannot exceed 128 characters.'
        });
      }

      // unique
      _.each(Knack.account.applications, function(app) {

        if ($.trim(app.name).toLowerCase() === $.trim(name).toLowerCase()) {
          errors.push({
            message: 'That App Name is already being used.'
          });
        }

        if ($.trim(app.slug).toLowerCase() === $.trim(slug).toLowerCase()) {
          errors.push({
            message: 'That App URL is already being used.'
          });
        }
      });

      if (!v3 && Knack.account.applications.filter(app => app.settings.v3_beta !== true).length >= Knack.account.plan_limits.applications) {

        errors.push({
          message: 'Uh oh, you have reached the maximum number of apps allowed with your plan.  You can upgrade your plan by clicking the Billing link from the left menu.'
        });
      }

      if (errors.length) {
        var error_msg = '';
        _.each(errors, function(err) {
          error_msg += '<p><strong>' + err.message + '</strong></p>';
        });

        $.utility_forms.renderMessage($('#add-app-form'), error_msg, 'error');
        $('#add-app-form').removeClass('is-submitting');

      } else {
        if (app_id) {
          this.copyTemplateApp(app_id, name, slug, template_slug, v3);
        } else {
          this.submitNewApp(name, slug, v3);
        }
      }
    },

    copyTemplateApp: function(app_id, app_name, slug, template_slug, v3 = false) {

      log('copyTemplateApp!!!!!!');

      // render the install
      $('.overlay-title .back').fadeOut();
      var $body = $('.overlay-body');
      $body.find('.active').removeClass('active').hide();
      $body.find('#app-installing').addClass('active').show();

      Knack.showSpinner();

      var model = new Backbone.Model();
      model.url = Knack.api + '/template?origin=template&template_slug=' + template_slug;
      model.fetch({
        data: {
          app_id: app_id,
          account_slug: Knack.account.slug,
          app_name: app_name,
          app_slug: slug,
          v3
        },
        success: function(model, response) {
          log('success!');
          log(arguments);

          Knack.hideSpinner();

          // render complete
          $body.find('.active').removeClass('active').hide();
          $body.find('#app-complete').addClass('active').show();

          // set URL:
          var url = 'http://builder.' + Knack.domain + '/' + Knack.account.slug + '/' + response.app.slug;
          $('#app-complete a').attr('href', url);

        },
        error: function(model, response) {
          log('error!');
          log(arguments);
          Knack.hideSpinner();
        }

      });
    },

    submitNewApp: function(name, slug, v3 = false) {

      log('submitNewApp(); name: ' + name + '; slug: ' + slug);

      Knack.showSpinner();

      log('submitting!');
      var model = new Backbone.Model();
      model.url = Knack.api + '/applications';

      model.attributes.settings = {
        v3_beta: v3
      }

      model.save({
        name: name,
        slug: slug
      }, {
        success: function(model, response) {
          const builderUrl = Knack.getBuilderUrl(Knack.account.slug, model.get(`slug`));
          window.location.href = builderUrl;
        },
        error: function(model, response) {

          if (response.responseText) {

            const responseText = response.responseText
            const extractedError = responseText.substring(responseText.lastIndexOf(`"message":"`) + 11, responseText.lastIndexOf(`"}]}`))
            const errorMessage = `<p><strong>${extractedError}</strong></p>`

            $.utility_forms.renderMessage($(`#add-app-form`), errorMessage, `error`)
            $(`#add-app-form`).removeClass(`is-submitting`)
            Knack.hideSpinner()
          }
        }
      })
    },

    handleClickFreezeAccount: function(event) {

      event.preventDefault();
      var _this = this;

      // render
      //Knack.renderModalTemplate('template_confirmFreeze', template_confirmFreeze, {'class': 'small'} );

      // layover!
      var options = {
      };
      var data = {
      };
      Knack.renderOverlayTemplate('template_confirmFreeze', template_confirmFreeze, options, data);

      // save handler
      $('#submit-freeze-account').off().on('click', _.debounce(function(event) {
        event.preventDefault();
        Knack.showSpinner();

        var model = new Backbone.Model({
          id: Knack.account.session.user.id
        });
        model.urlRoot = Knack.api + '/freeze';//+ '/applications';

        model.save({
        }, {
          success: function(model, response) {

            log('back from freeze()');
            log(arguments);

            Knack.hideSpinner();

            // close model
            Knack.closeOverlay();

            Knack.account.status = 'frozen';
            Knack.router.navigate('billing', false);
            _this.renderBilling();
          }
        });

      }, 1000));
    },

    renderDelete: function() {

      var _this = this;
      this.render();

      // if direct link then render apps
      if (!$('#account-settings').length) {
        var to = setTimeout(function() {
          _this.renderAccount();
        }, 800);
      }

      log('renderDelete!');

      // show and activate apps
      $('#menu-content a[href="#delete"]').addClass('active');
      //$('#content-wrapper').html( Knack.render('account_delete', account_delete) );

      // layover!
      var options = {
      };
      var data = {
      };
      Knack.renderOverlayTemplate('account_delete', account_delete, options, data);

      var status = Knack.user.getAccountStatus();
      var plan = Knack.account.product_plan;

      log('plan: ' + plan.id);
      log(plan);

      $('#delete-account .delete-active, #delete-account .delete-non').hide();

      if (Knack.account.billing.last_freeze_date) {
        var freeze_date = Date(Knack.account.billing.last_freeze_date);
      }

      var year = new Date(); year.setFullYear(year.getFullYear() - 1);

      log('freeze_date: ' + freeze_date + '; year: ' + year);

      if (plan && plan.id && plan.id != 'trial' && status != 'frozen' && (!freeze_date || freeze_date < year)) {
        $('#delete-account .delete-active').show();

        // add freeze pricing
        var level = plan.level;
        var freezes = [9,19,29,49,79,149];
        log('freezes for level: ' + level + ' is: ' + freezes[level-1]);
        $('.freeze-price').text('$' + freezes[level-1]);

      } else {
        $('#delete-account .delete-non').show();
      }

      // bind deletes
      $('#confirm-delete-account').off().on('click', $.proxy(this.handleClickDeleteAccount, this));

      // intercom
      $('.delete-review').off().on('click', function(event) {
        event.preventDefault();

        if (typeof Intercom != 'undefined') {
          Intercom('showNewMessage', 'Hi, I\'d like to get a quick review of my app before I delete it.');
        }
      });

      // bind freeze
      $('#freeze-account-delete-link').off().on('click', function(event) {

        _this.handleClickFreezeAccount(event);
        $('.overlay-title .back').fadeIn().find('span.label').text('Back');
        $('.overlay-title .back').off().on('click', function() {
          _this.renderDelete();
        });
      });

      const competitorSelect = $('#competitor-select');
      const reasonForDeletionRadios = $('input[name="delete-reason"]');
      const otherCompetitorInput = $('#other-competitor-input');
      const otherReasonTextarea = $('#delete-reason-other-text');

      // Ensure other competitor input is only shown if "Moved to competitor" is selected
      reasonForDeletionRadios.on('change', function() {
        const selectedReason = $('input[name="delete-reason"]:checked').val();
        if (selectedReason === 'moved-to-competitor' && competitorSelect.val() === 'other') {
          otherCompetitorInput.show();
        } else {
          otherCompetitorInput.hide();
        }
      });

      // Ensure other competitor input is shown if "Other" is selected for the "Moved to competitor" select
      competitorSelect.on('change', function() {
        if (this.value === 'other') {
          otherCompetitorInput.show();
        } else {
          otherCompetitorInput.hide();
        }
      });

      otherReasonTextarea.on('input', function() {
        $('.delete-account-feedback-form__radio--other-reason').removeClass('delete-account-feedback-form__radio--has-error');
      });

      return this;
    },

    handleClickDeleteAccount: function(event) {

      event.preventDefault();

      const reasonsForDeletion = {
        'price': 'Price',
        'project-ended': 'Project Ended',
        'job-change': 'Job Change/Left Company',
        'not-enough-support': 'Not Enough Support/Training',
        'not-what-expected': 'Not What I Was Expecting',
        'technical-issues': 'Technical Issues',
        'moved-to-competitor': 'Moved to Competitor',
        'other': 'Other',
      };

      const competitors = {
        'quickbase': 'QuickBase',
        'caspio': 'Caspio',
        'bubble': 'Bubble',
        'kintone': 'Kintone',
        'tadabase': 'TadaBase',
        'other': 'Other',
      };

      const reason = $('input[name="delete-reason"]:checked').val();

      if (!reason) {
        $('#required-message').show();
        return false;
      }

      const competitorChosen = reason === 'moved-to-competitor' ? $('#competitor-select').val() : null;
      const otherCompetitor = competitorChosen === 'other' ? $('#other-competitor-input').val() : null;
      const otherReason = reason === 'other' ? $('#delete-reason-other-text').val() : null;

      if (otherReason !== null && otherReason.length === 0) {
        $('.delete-account-feedback-form__radio--other-reason').addClass('delete-account-feedback-form__radio--has-error');
        return false;
      }

      const directFeedbackTrimmed = $('#direct-feedback').val().trim();
      const directFeedback = directFeedbackTrimmed.length > 0 ? directFeedbackTrimmed : null

      const accountDeletionFeedback = {
        reasonForAccountDeletion: reasonsForDeletion[reason],
        competitorChosen: competitorChosen ? competitors[competitorChosen] : null,
        otherCompetitor,
        otherReason,
        directFeedback,
      }

      Knack.showSpinner();

      var model = new Backbone.Model({
        id: Knack.account.session.user.id
      });
      model.urlRoot = Knack.api + '/delete';
      log('deleting! urL: ' + Knack.api);

      model.save({
        feedback: accountDeletionFeedback
      }, {
        success: function(model, response) {

          log('back from destroy()');
          log(arguments);

          Knack.hideSpinner();

          // close model
          Knack.closeModal();

          $('#knack-content').html(Knack.render('goodbye_template', goodbye_template));
        }
      });
    },

    handleClickEnable2fa: function(event) {

      event.preventDefault()

      const _this = this
      const baseUrl = `${Knack.api_url}/v1/accounts/session/2fa`
      const queryString = Knack.account.id ? `?account_id=${Knack.account.id}` : ``
      const requestBody = {user_id: Knack.account.session.user.id}

      Knack.showSpinner()

      $.post(`${baseUrl}/generate${queryString}`, requestBody)
       .success((data, result) => {

          const options = {}

          data = {
            secret: data.secret,
            data_uri: data.data_uri
          }

          Knack.renderOverlayTemplate(`template_enable2fa`, template_enable2fa, options, data)
          Knack.hideSpinner()

          $(`.enable-2fa-step form`).off().on(`submit`, function (event) {

            event.preventDefault()

            Knack.showSpinner()

            const token = $(`#totp-token`).val()

            $.post(`${baseUrl}/enable`, $.extend({}, requestBody, {totp_token: token}))
             .success(() => {

               Knack.closeOverlay()

               Knack.user.set(`using_2fa`, true)
               _this.renderUser()

               $(`#edit-2fa`).click()

               Knack.hideSpinner()
             }).fail((data) => {

               const msg = JSON.parse(data.responseText).errors[0].message

               $(`#2fa-error`).html(msg)
               $(`.kn-message.error`).show()

               Knack.hideSpinner()
             })
          })
        })
    },
    handleClickDisable2fa: function(event) {

      event.preventDefault()

      const _this = this

      Knack.renderModalTemplate(`template_disable2fa`, template_disable2fa, {class: `small`})

      $(`#submit-disable-2fa`).off().on(`click`, function (event) {

        event.preventDefault()

        const url = `${Knack.api_url}/v1/accounts/session/2fa/disable`

        const requestBody = {
          user_id: Knack.account.session.user.id,
          totp_token: $(`#totp-disable-token`).val()
        }

        Knack.showSpinner()

        $.post(url, requestBody)
         .success(() => {

           Knack.closeModal()

           Knack.user.set(`using_2fa`, false)
           _this.renderUser()

           $(`#edit-2fa`).click()

           Knack.hideSpinner()
         }).fail((data) => {

           const msg = JSON.parse(data.responseText).errors[0].message

           $(`#2fa-disable-error`).html(msg)
           $(`.kn-message.error`).show()

           Knack.hideSpinner()
         })
      })
    },
    handleClickReset2faSecretKey: function(event) {

      event.stopImmediatePropagation()

      const _this = this
      const $template = Knack.render(`template_reset2faSecretKey`, template_reset2faSecretKey)
      const $resetButton = $(`#reset-2fa-secret-key`)

      $resetButton.knPopover({
        content: $template
      });

      $(`#submit-reset-2fa-secret-key`).off().on(`click`, $.proxy(function(event) {

        Knack.clearPopovers()

        _this.handleClickEnable2fa(event)
      }))
    },
    renderBillingAddress: function () {

      this.render()

      $('#app-menu .active').removeClass('active')

      // This can be null on an account object, so set defaults to make templating easier
      const billingAddress = Object.assign({
        street: ``,
        city: ``,
        state: ``,
        zip: ``,
        country: ``
      }, Knack.account.billing.billing_address)

      $(`#body`).html(Knack.render(`updateBillingTemplate`, updateBillingTemplate, billingAddress))

      $(`#country`).val(billingAddress.country)

      const model = new Backbone.Model()

      // Force Backbone to treat this as an update instead of a creation, but without appending an id to the model url
      model.set(`id`, ``)

      model.url = Knack.api

      $(`#billing-address-submit`).off().on(`click`, () => {

        const street = $(`#street`).val()
        const city = $(`#city`).val()
        const state = $(`#state`).val()
        const zip = $(`#zip`).val()
        const country = $(`#country`).val()

        $(`.errors`).empty()

        if (!street || !city || !zip || !country || (country === `US` && !state)) {

          $(`.errors`).html('<div class="alert-message block-message error"><p><strong>Billing Address is required.</strong></p></div>')

          $(`:input, :input:submit`).prop(`disabled`, false)

          $(`.kn-spinner`).hide()

          return
        }

        const data = {
          billing_address: {
            street,
            city,
            state,
            zip,
            country
          }
        }

        model.save(data, {
          success: () => {

            $(`#billing-address-notice`).hide()

            $(`#content-wrapper`).html(`<div class="kn-message success"><p>Your billing information has been updated.</p></div>`)
          }
        })
      })
    },

    // MIGRATION DASHBOARD

    renderMigrationApps: function() {      
      var _this = this;
      
      if (this.render()) {
        _this.renderApps();

        // Allow zoom on mobile
        $('head meta[name="viewport"]').attr('content', 'width=1000');

        new Vue({
          el: '#dashboard-wrapper',
          components: {
            'migration-dashboard': MigrationDashboard.default,
          },
          render: function (createElement) {
            return createElement(
              'migration-dashboard', {
                props: {
                  accountId: Knack.account.id,
                  apiUrl: Knack.api_url
                }
              }
            );
          },
        });
      }
    },
  })
})
