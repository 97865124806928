define([
  'jquery', 'backbone',
  'core/backbone/views/AssetView',
  'core/utility/utility-forms',
  'core/ui/nailthumb',
  'core/ui/image-gallery',
  '@googlemaps/js-api-loader',
  'maplibre-gl',
], function($, Backbone, AssetView, UtilityForms, NailThumb, ImageGallery, { Loader: GoogleMapsLoader }, maplibregl) {
  return Backbone.View.extend({

    events: {
      'click .kn-view-asset': 'handleClickViewAsset',
      'click .kn-img-gallery': 'handleClickViewImageGallery'
    },

    handleClickViewAsset: function(event) {
      event.preventDefault();
      event.stopImmediatePropagation();

      var $anchor_data = $(event.target).data();
      var asset_view_data = {
        asset_id: $anchor_data.assetId
      };

      // this link contains no data so we cannot process it any further
      if (_.isEmpty($anchor_data)) {

        return
      }

      if (Knack.mode === 'renderer') {
        asset_view_data.scene_key = Knack.scenes.get(Knack.router.current_scene).get('key').split('_')[1];
        asset_view_data.view_key = this.model.view.key.split('_')[1];
        asset_view_data.field_key = $anchor_data.fieldKey.split('_')[1];
      }

      var asset_view = new AssetView(asset_view_data);
      asset_view.render();
    },

    handleClickViewImageGallery: function(event) {
      event.preventDefault();
      event.stopImmediatePropagation();

      var options = {
        multiple_images: true,
        image_family: 'view'
      };

      $(event.currentTarget).ImageGallery(options);
    },

    render: function() {
      return this;
    },

    triggerRendered: function() {
      var data = (this.model.data) ? this.model.data.toJSON() : this.model.toJSON();
      this.changeAssetDownloadLinks();
      $(document).trigger('knack-view-render.any', [this.model.view, data]);
      $(document).trigger('knack-view-render.' + this.model.view.type, [this.model.view, data]);
      $(document).trigger('knack-view-render.' + this.model.view.key, [this.model.view, data]);
    },

    triggerReady: function() {
      var data = (this.model.data) ? this.model.data.toJSON() : null;
      $(document).trigger('knack-view-ready.' + this.model.view.key, [this.model.view, data]);
    },

    showLoading: function() {
      $('#kn-loading-spinner').show();
    },

    hideLoading: function() {
      $('#kn-loading-spinner').hide();
    },

    renderRemoteImageThumbs: function() {

      var _this = this;

      var to = setTimeout(function() {
        _this.$('.nailthumb-container').css({
          'visibility': 'visible'
        }).nailthumb({
          method: 'resize',
          replaceAnimation: null
        });

      }, 20);

      var to = setTimeout(function() {
        _this.$('.nailthumb-container').css({
          'visibility': 'visible'
        }).nailthumb({
          method: 'resize',
          replaceAnimation: null
        });

      }, 200);

    },

    loadGoogleMapsLibrary: async function(callback) {

      var _this = this;

      if (Knack.google_lib) {

        return callback();
      }

      if (Knack.google_loading) {

        // this is already trying to load from another map, let that one load it
        var to = setTimeout(function() {
          _this.loadGoogleMapsLibrary(callback);
        }, 200);
        return;
      }

      Knack.google_loading = true;

      if (Knack.mode !== `dashboard` && Knack.app.get(`settings`).googleMapsApiKey) {

        const credentialModel = new Backbone.Model()

        credentialModel.url = `${Knack.api_url}/v1/credentials/${Knack.app.get(`settings`).googleMapsApiKey}`

        const result = await new Promise(resolve => credentialModel.fetch({
          success: (model, response) => resolve(response)
        }))

        key = result.key
      } else {
        key = Knack.getProductionMode() === 'development' ? 'AIzaSyDCgKgc5xTchMjijYN3-n4MrdoWzDjcLPQ' : 'AIzaSyDnNIykl1kVqrTCC9xK7HbiK8vt_P812_Y';
      }

      const loader = new GoogleMapsLoader({
        apiKey: key,
        libraries: ['core', 'marker', 'places']
      });

      loader.load().then(() => {
        Knack.google_lib = true;
        callback();
      });
    },

    loadHereMapsLibrary: async function (callback) {

      log(`loadHereMapsLibrary()`)

      // Use same script loading logic as Google Maps (above) as HERE requires loading from their CDN
      if (Knack.hereMapsLoaded === true) {

        return callback()
      }

      if (Knack.hereMapsLoading === true) {

        return setTimeout(() => this.loadHereMapsLibrary(callback), 200)
      }

      Knack.hereMapsLoading = true

      $('<link />', {
        rel: 'stylesheet',
        type: 'text/css',
        href: '//unpkg.com/maplibre-gl@latest/dist/maplibre-gl.css',
      }).appendTo(`head`);

      Knack.hereMapsLoaded = true

      callback()
    },

    // SVG marker icon from http://mapkeyicons.com/
    // LICENSE: CC0 1.0 Universal (Public Domain)
    getDefaultPinSvgString: function(color) {

      return `<svg xmlns="http://www.w3.org/2000/svg" x="1" y="1" width="32" height="32" viewBox="0 0 256 256"> <path fill="#333333" d="M128.18,249.042c-4.252,0-8.151-2.365-10.114-6.137L64.648,140.331c-0.082-0.156-0.159-0.313-0.233-0.474 C55.837,121.342,47.9,101.865,47.9,84.859c0-20.079,8.655-40.271,23.747-55.4c15.512-15.549,35.68-24.113,56.787-24.113 c21.099,0,41.188,8.579,56.57,24.155c14.904,15.093,23.453,35.271,23.454,55.358c0,18.868-9.282,38.867-16.062,53.47l-0.707,1.526 c-0.07,0.152-0.146,0.306-0.224,0.453l-53.159,102.574c-1.959,3.778-5.859,6.151-10.116,6.156 C128.188,249.042,128.184,249.042,128.18,249.042z"/><path fill="${color}" d="M128.052,16.75c-37.729,0-69.129,32.667-69.129,68.109c0,15.947,8.973,36.204,15.459,50.204l53.417,102.574 l53.162-102.574c6.484-13.999,15.711-33.242,15.711-50.203C196.671,49.418,165.773,16.75,128.052,16.75z M127.025,113.857 c-16.585,0-30.031-13.445-30.031-30.03s13.445-30.03,30.031-30.03c16.584,0,30.03,13.445,30.03,30.03 S143.609,113.857,127.025,113.857z"/></svg>`
    },

    renderKnackMaps: function (element, previousCoords) {

      let logMapLoadModel = new Backbone.Model()

      logMapLoadModel.url = `${Knack.api}/log/mapload`

      logMapLoadModel.save({
        view_key: this.model.view.key,
        hostname: window.location.hostname
      })

      const html = $(element).html()

      // map has already been rendered for this element
      if (html.includes(`xmlns`) && !previousCoords) {

        return
      }

      const coords = previousCoords || html.split(`X`);

      $(element).empty();

      const key = Knack.getProductionMode() === 'production' ? 'hQGQ2r2Y7RkO5a3YWDTA' : 'm56CFT1XTGpic3bc7Prx';
      const map = new maplibregl.Map({
        container: element,
        style: `https://api.maptiler.com/maps/streets-v2/style.json?key=${key}`,
        center: [coords[1], coords[0]],
        zoom: 13,
      });
      map.addControl(new maplibregl.NavigationControl());

      const marker = new maplibregl.Marker({
        color: '#FE7569',
      }).setLngLat([coords[1], coords[0]]);
      marker.addTo(map);

      $(element).css('visibility', 'visible')

      // Retry once after a delay if HERE has not successfully loaded canvas
      if (this.$(`canvas`).attr(`width`) === `1` && !previousCoords) {

        setTimeout(() => this.renderKnackMaps(element, coords), 5000)
      }
    },

    renderMaps: function() {

      const _this = this

      if (this.$('.kn-map').length === 0) {

        return
      }

      if (Knack.useKnackMaps()) {

        return this.loadHereMapsLibrary(() => {

          this.$(`.kn-map`).each(function() {

            _this.renderKnackMaps(this)
          })
        })
      }

      this.loadGoogleMapsLibrary(function() {

        _this.$('.kn-map').each(function() {

          let logMapLoadModel = new Backbone.Model()

          logMapLoadModel.url = `${Knack.api}/log/mapload`

          logMapLoadModel.save({
            view_key: _this.model.view.key,
            hostname: window.location.hostname
          })

          // adding map
          var lat_long = $(this).html().split('X');

          var map_canvas = this
          var map_options = {
            center: new google.maps.LatLng(lat_long[0], lat_long[1]),
            zoom: 15,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            mapId: Knack.app.get('settings').googleMapId || 'DEMO_MAP_ID' // fallback map id
          };
          var map = new google.maps.Map(map_canvas, map_options);

          var marker = new google.maps.Marker({
            position: new google.maps.LatLng(lat_long[0], lat_long[1]),
            map: map
            //, title: model.get('field_43') || model.get('field_5')
          });

          $(this).css('visibility', 'visible');
        });

      });

    },

    // this renders from the details template, table handles separately in the table view
    renderRatings: function() {

      var _this = this;
      var type = this.model.view.type;

      this.$('.kn-rating').each(function() {

        var key = $(this).find(':input[name=key]').val();
        var record_id = $(this).find(':input[name=id]').val();
        var field = Knack.objects.getField(key);
        var value_id = '#' + _this.model.view.key + '-' + key + '-' + record_id + '-value';

        var step = (field.get('format').allow_half) ? 0.5 : 1;
        var rate_vals = {
          max: 5,
          step: step,
          backingfld: value_id,
          resetable: false,
          max: field.get('format').max,
          ispreset: true,
          readonly: true,
          starwidth: 19,
          starheight: 20
        };

        $(this).find('.rating').rateit(rate_vals);

      }, this);
      this.$('.rating').bind('over', function(event,value) {
        $(this).attr('title', value);
      });

    },

    handleDeleteRecord: function(entry_id, toggle) {

      var _this = this;

      //var table = this;
      if (window.confirm('Are you sure you want to delete this?')) {
        Knack.showSpinner();

        var data = (_this.model.data) ? _this.model.data.get(entry_id) : _this.model;
        var view = (_this.model.view.toJSON) ? _this.model.view.toJSON() : _this.model.view;

        data.destroy({
          wait: true,
          success: function(model, response) {

            Knack.hideSpinner();

            $(document).trigger('knack-record-delete.any', [view, model]);
            $(document).trigger('knack-record-delete.' + view.type, [view, model.toJSON()]);
            $(document).trigger('knack-record-delete.' + view.key, [view, model.toJSON()]);

            if (toggle) {
              _this.$el.slideToggle();
            }
          }
        });
      }
    },

    changeAssetDownloadLinks: function() {

      var _this = this;

      if ((_this.model && _this.model.view && _this.model.view.type && _this.model.view.type === 'login')
        || Knack.mode === 'dashboard'
        || (Knack.mode === 'renderer' && _this.model && _this.model.view && !_this.model.view.key)) {

        return;
      }

      if (Knack.mode === 'builder') {

        _this.$el.find('a.kn-view-asset').each(function(index, link) {

          var $link = $(link);

          // If we are in builder > data > records, we don't want hyperlinks with kn-view-asset class to exist
          // or it will trigger asset viewer when clicking to do inline edits
          if (_this.$el[0].id === 'records-table') {

            $link.replaceWith($link.text());

            return;
          }

          var data = $link.data();

          $link.attr('href', '#kn-asset/' + data.assetId + '/' + data.fileName);
        });

        return;
      }

      var scene_key = Knack.router.current_scene_key ? Knack.router.current_scene_key.split('_')[1] : (Knack.router.current_scene) ? Knack.scenes.get(Knack.router.current_scene).get('key').split('_')[1] : '';

      // A scene can have multiple views, but we are using _this.$el in our selector so we can trust view_key to be correct
      var view_key = _this.model.view.key.split('_')[1];

      // We build the url using the base and hash in case the user utilized filters, search, pagination, etc.
      var new_url = Knack.url_base + Knack.scene_hash;

      if (new_url.substr(new_url.length-1) !== '/') {

        new_url += '/';

      }

      new_url += 'kn-asset/' + scene_key + '-' + view_key + '-';


      _this.$el.find('a.kn-view-asset').each(function(index, link) {

        var $link = $(link);
        var data = $link.data();

        // this link contains no data so we cannot process it any further
        if (_.isEmpty(data)) {

          return
        }

        var field_key = data.fieldKey.split('_')[1];
          $link.attr('href', new_url + field_key + '-' + data.assetId + '/' + data.fileName);
      });
    }
  });
});
