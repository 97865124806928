define(['jquery', 'underscore'], function($, _) {

// jquery plugin

  // connec Class
  var connectionPicker = {

    // properties
    options: {
    },
    $elem: null,
    available_entries: [],
    current_entries: [], // will be less than available_entries if dependent on another input
    field_key: '',

    ajax_trigger_amount: 500,
    // ajax_trigger_max: 10000,
    ajax: false, // if true autocomplete will be used.
    term: '',
    ajax_searching: true,
    filter: null,

    is_parent_connected: false, // if true then choices depend on a form parent,

    // if true, it means that this connection picker's parent connection picker had options changed
    // this is used to help determine whether or not we append previously selected values to THIS connection picker
    has_parent_id_changed: false,

    input_type: 'chosen', // can be checkboxes or radios
    default_type: 'first',

    values: {
    }, // identifieres indexed by ID
    has_values: false, // whether the connection is prepopulated with any values
    hasLoadedOptions: false,
    // init() ---------------
    init: async function(options, elem) {
      var _this = this;

      this.keepOldText = false;

      // set options
      this.options = $.extend({
      }, this.options, options);

      // set vars
      this.$elem = $(elem);

      this.field_key = this.$elem.find('.connection').attr('name');
      this.object_key = options.object_key || this.$elem.find(':input[name=object_key]').val();

      // add an attribute here for easier selections
      this.$elem.attr('data-is-connection', 'true');

      this.multiple = this.$elem.find('select').prop('multiple');

      // This div is a hack to allow the select element to render properly in the builder edit view mode
      // because it doesn't call init to go through full rendering process/logic so we have to have some default markup
      // since init IS called for rendered apps, we just go ahead and remove the element from the dom entirely
      this.$elem.find(`#builder-only-view-edit-display-${this.field_key}`).remove();

      // ajax
      var count = Knack.app.get('counts')[this.object_key];

      var filters = this.getFilters();

      var object = Knack.objects.get(this.object_key);

      this.identifier = object.get('identifier');

      if (!Knack.fields[this.identifier] || !this.identifier) {

        this.identifier = object.fields.at(0).id;
      }

      var type = Knack.fields[this.identifier].get('type');

      this.field_type = Knack.config.fields[type].field_type || false;

      // input type
      if (!this.ajax && options.input && options.input.format && options.input.format.input && options.input.format.input) {

        this.input_type = options.input.format.input;
      }

      // selected field
      this.select_fields = null;

      // check source for any connected options
      if (this.options.input && this.options.input.source && this.options.input.source.connections) {
        var conn = this.options.input.source.connections[0];

        if (conn) {

          this.select_fields = conn.field.key;

          // input source
          if (conn.source && conn.source.type == 'input') {

            this.is_parent_connected = true;

            // use this.elem to ensure you're not grabbing a field from another form
            var $parent = this.getParent();

            // in case fields are loaded "out of order", try getting the parent id again after ui has updated
            var parent_id = this.getParentId()

            if (!parent_id) {

              await new Promise(resolve => setTimeout(resolve, 0))

              parent_id = this.getParentId()
            }

            let hasChangeHandlerRun = false

            let parentField

            if (conn.source.field) {

              parentField = Knack.fields[conn.source.field.key]
            }

            const parentFieldIsRadio = parentField && parentField.get(`format`) && parentField.get(`format`).input === `radio`

            // if the parent is read-only we're going to add a filter so we're only selecting the child records for that parent. Read-only
            if ($parent.hasClass('kn-read-only')) {

              var f = Knack.fields[conn.field.key];

              // foreign connection
              var operator = 'connection';

              // local
              if (f.get('object_key') === this.object_key) {

                operator = (_.isArray(parent_id)) ? 'in' : 'is';
              }

              this.filter = {
                field: conn.field.key,
                operator: operator,
                value: parent_id
              };

              _this.previous_parent_id = parent_id;

              // check if the connection is formatted as a radio
            } else if ($parent.find(':input[type=radio]').length && parentFieldIsRadio) {

              _this.previous_parent_id = $parent.find(':input[type=radio]:checked').val() || '';

              // change function based on other radio buttons
              $parent.find(':input[name=' + this.options.view_key + '-' + conn.source.field.key + ']').change(function(event) {

                // var parent_id = $parent.find(':input[type=radio]:checked').val();
                var parent_id = $(this).val();

                let has_selected_option = false

                $(this).find(`option`).each(function() {

                  if (!this.selected) {

                    return
                  }

                  has_selected_option = true
                })

                if (!this.checked && !has_selected_option) {

                  return
                }


                if (parent_id.includes('%')) {

                  parent_id = JSON.parse(decodeURIComponent(parent_id))[0].id
                }

                // We ignore previous_parent_id being ' because that's how our code above initially sets it for add forms
                if (_this.previous_parent_id !== '' && _this.previous_parent_id !== parent_id || !hasChangeHandlerRun) {
                  _this.has_parent_id_changed = true;
                } else {
                  _this.has_parent_id_changed = false;
                }

                hasChangeHandlerRun = true

                _this.previous_parent_id = parent_id;

                _this.loadConnectedOptions(conn, parent_id, _this.has_parent_id_changed);
              });

              // otherwise it's chosen
            } else {

              // This initially gets set to undefined for add forms which screws things up as the page renders so we || it to ''
              _this.previous_parent_id = $parent.find('select').val() || '';

              // change function based on other chosen connections
              $parent.find('select').change(function(event) {
                var parent_id = $(this).val();

                // We ignore previous_parent_id being ' because that's how our code above initially sets it for add forms
                if (_this.previous_parent_id !== parent_id || !hasChangeHandlerRun) {
                  _this.has_parent_id_changed = true;
                } else {
                  _this.has_parent_id_changed = false;
                }

                hasChangeHandlerRun = true

                _this.previous_parent_id = parent_id;

                _this.loadConnectedOptions(conn, parent_id, _this.has_parent_id_changed);
              });
            }
          }
        }
      }

      let eager_fetch_choices_result_model;

      // we can only use the ajax lookup for connection fields if the identifier of the
      // connected object uses a type that supports "contains" operator
      this.identifierFieldTypesSupportingAjax = new Set([
        'address',
        'concatenation',
        'link',
        'name',
        'paragraph_text',
        'phone',
        'rich_text',
        'short_text',
        'email',
      ]);

      // if parent fetches (has a filter), then it uses that - no need to set filteredcount
      // if parent does not fetch, it has no filter, it should use the count from the app.
      // use ajax for large counts without filters and text types because we know our total result set is > 500.
      // don't use ajax if parent is connected, because we need to wait and run a request for connected choices to see total result set.
      if (count > this.ajax_trigger_amount && !filters.length && this.field_type == 'text') {
        this.ajax = this.identifierFieldTypesSupportingAjax.has(type);
      } else if ((filters.length > 0  && !this.is_parent_connected) || (!this.identifierFieldTypesSupportingAjax.has(type) && !this.is_parent_connected)) {
        // top level connections with filters will need the full result set regardless.
        eager_fetch_choices_result_model = await this.fetch_choices(null, true);
      }
      // children with filters will need the count of the filtered result set. We must know whether or not they should be ajax.
      // but we do not need to fetch the full result set. We will do that later when the parent changes.

      // Components without filters will not need this because they can use the count.
      // A parent with a filter will not need this because it will have the result model.
      // A child with a filter will need this because it will not have the result model, and we must know what to render.
      if (_.isUndefined(eager_fetch_choices_result_model) && filters.length > 0 && this.is_parent_connected) {
        if (!(Knack.app.get('settings').sql)) {
          try {
            resultModel = await this.fetch_choices(null, true);
            this.filteredCount = resultModel.get('total_records');
            if(!this.filteredCount) {
              this.filteredCount = count;
            }
          } catch (e) {
            this.filteredCount = count;
          }
        } else {
          try {
            this.filteredCount = await this.fetch_count(null);
            if(!this.filteredCount) {
              this.filteredCount = count;
            }
          } catch (e) {
            this.filteredCount = count;
          }
        }
      }
      // this is so that parents that aren't going to eager fetch can still have a count in the statement below.
      // children without filters will need the count set as well for the statement below.
      if(_.isUndefined(eager_fetch_choices_result_model) && filters.length === 0) {
        this.filteredCount = count;
      }
      // always use chosen for large counts
      // if eager_fetch_choices_result_model is undefined && count > this.ajax_trigger_amount
      // or if eager_fetch_choices_result_model is NOT undefined && its total_records is > ajax_trigger_amount && count > this.ajax_trigger_amount
      if (
        (_.isUndefined(eager_fetch_choices_result_model) && this.filteredCount && this.filteredCount > this.ajax_trigger_amount) ||
        (!_.isUndefined(eager_fetch_choices_result_model) && eager_fetch_choices_result_model.get('total_records') > this.ajax_trigger_amount && count > this.ajax_trigger_amount)
      ) {

        this.input_type = 'chosen';
        this.ajax = this.identifierFieldTypesSupportingAjax.has(type);
        this.keepOldText = true;
      }

      this.$elem.find(`#connection-picker-${this.input_type}-${this.field_key}`).show();

      // This removes the other connection-picker UI types because we have code buried somewhere else that relies on a generic selector
      // and having them hidden is not good enough. It will mess up getting the value and cause the picker to return an empty value
      this.$elem.find(`[id^="connection-picker-"]:not([id$="${this.input_type}-${this.field_key}"])`).remove();

      // default type?
      if (this.options.input && this.options.input.field && this.options.input.field.format && this.options.input.field.format.conn_default) {
        this.default_type = this.options.input.field.format.conn_default;
      } else if (this.options.input && this.options.input.field.conn_default) {
        this.default_type = this.options.input.field.conn_default;
      } else {
        this.default_type = (this.options.input && this.options.input.field && this.options.input.field.required) ? 'first' : 'none';
      }

      this.empty_text = Knack.trans('No results match');
      this.search_text = Knack.trans('Searching...');
      this.init_text = Knack.trans('Type to search');
      this.default_text = (this.ajax && !this.keepOldText) ? Knack.trans('Type to search') : Knack.trans('Select');

      // VALUES =================
      // check for values
      this.values = {
      };
      this.record_ids = [];

      var val = this.$elem.find('.connection').val();

      if ((val && val != 'undefined') || this.options.value) {
        var values = this.options.value || $.parseJSON(decodeURIComponent(val));

        this.has_values = true;

        if (typeof values != 'string') {

          _.each(values, function(value) {
            if (value && value.id) {
              if (value.identifier) {
                this.values[value.id] = value.identifier;
              } else {

                var identifier = Knack.trans('loading') + '...';
                if (this.ajax) {
                  this.record_ids.push(value.id);
                }
                this.values[value.id] = identifier; // this is from form submit rules where connection values aren't formatted
              }
            } else if (typeof value == 'string' && value) {
              var identifier = value;

              if (this.ajax) {
                this.record_ids.push(value);
                identifier = Knack.trans('loading') + '...';
              }
              this.values[value] = identifier; // this is from form submit rules where connection values aren't formatted

            }
          }, this);
        }
      }

      this.$elem.find('.kn-add-option').bind('click', $.proxy(this.handleClickAddOption, this));

      //
      if (!this.ajax && _.isUndefined(eager_fetch_choices_result_model)) {
        if(this.is_parent_connected && this.options.form_action !== "update") {
          this.triggerLoad();
        } else {
          this.fetch_choices();
        }
      } else if (!this.ajax && !_.isUndefined(eager_fetch_choices_result_model)) {
        if (this.input_type == 'chosen') {

          this.renderChosen();
        }

        return this.handleEntriesLoaded(eager_fetch_choices_result_model.attributes);
      }

      // render
      if (this.input_type == 'chosen') {

        this.renderChosen();
      }
    },

    getParent: function() {
      var $parent;

      // check source for any connected options
      if (this.options.input && this.options.input.source && this.options.input.source.connections) {

        var conn = this.options.input.source.connections[0];

        if (conn) {

          // input source
          if (conn.source && conn.source.type == 'input') {

            const $form = $(this.$elem.closest('form'));
            // find the parent based on hidden input "connection" field
            $parent = $form.find(`input.connection[name="${conn.source.field.key}"]`).parent();
            // search and form views render either v1 or v2 connection picker, in case of v2 we need to traverse up 1 more level
            if ($parent.hasClass('control')) {
              $parent = $parent.parent();
            }

            // if we dont have a hidden connection field, it is probably read only so select the kn-input-connection wrapper
            if ($parent.length === 0) {
              $parent = $($form.find('#kn-input-' + conn.source.field.key)[0]);
            }
          }
        }
      }

      return $parent;
    },

    getParentId: function() {
      var parent_id;
      var $parent = this.getParent();

      if (!$parent) {

        return parent_id;
      }

      if ($parent.hasClass('kn-read-only')) {

        parent_id = $parent.children('span').attr('class');

        if (parent_id && parent_id.indexOf(',') > -1) {

          parent_id = parent_id.split(',');
        }

        // check if the connection is formatted as a radio
      } else if ($parent.find(':input[type=radio]').length) {

        parent_id = $parent.find(':input[type=radio]:checked').val()

        // otherwise it's chosen and it's handled in the onChange event registered in init
      } else {

        parent_id = $parent.find('select').val()
      }

      return parent_id;
    },

    renderCheckboxes: function(chosen_text) {

    },

    renderChosen: function(chosen_text) {

      var _this = this;

      this.$elem.find('.chzn-select').chosen({
        search_contains: true,
        placeholder_text_multiple: _this.default_text,
        placeholder_text_single: _this.default_text,
        disabled: _this.options.disabled
      });
      this.chosen = this.$elem.find('.chzn-select').data('chosen');

      this.$elem.find('.chzn-select').on('change', function(evt, params) {

        // remove any deselected
        if (params && params.deselected) {
          delete _this.values[params.deselected];
        }

        var val = _this.$elem.find('.chzn-select').val();

        if (val) {

          if (_.isArray(val) && val.length) {

            _.each(val, function(id) {

              if (id && id != 'undefined') {

                _this.values[id] = _this.$elem.find('select option[value=' + id + ']').html();
              }
            });
          } else {

            _this.values[_this.$elem.find('.chzn-select option:selected').val()] = _this.$elem.find('.chzn-select option:selected').html();
          }
        }
      });

      // AJAX
      if (this.ajax) {

        this.renderAjax();
      }
    },

    setChosenText: function(chosen_text) {
      log(chosen_text);

      if (this.chosen && chosen_text) {

        this.chosen.results_none_found = chosen_text;
      }
    },

    renderAjax: function() {

      var _this = this;

      // add placeholder
      if (this.multiple) {

        this.$elem.find('.chzn-search input').prop('placeholder', this.init_text);
      } else {

        this.$elem.find('.chzn-search input').prop('placeholder', 'Type to search...');
      }

      this.$elem.find('.chzn-select').on('liszt:showing_dropdown', function(evt, params) {

        _this.setChosenText(_this.search_text);

        if (_this.term) {

          _this.$elem.find('.chzn-search input').val(_this.term);
        }
      });

      this.$elem.find('.chzn-search input, .chzn-choices input').autocomplete({
        minLength: 1,
        delay: 500,
        source: function(request, response) {

          _this.handleSearchAjax(request, response);
        }
      });

      if (this.record_ids.length) {

        this.selectRecordIdentifiers(this.record_ids);
      }


      setTimeout(() => {

        if (this.options.input.set_by_url_var) {

          return this.handleSearchAjax({
            term: _.isArray(this.options.input.set_by_url_var) ? this.options.input.set_by_url_var[0] : this.options.input.set_by_url_var
          })
        }

        this.triggerLoad();
        this.loadOptions();
        this.setChosenText(_this.search_text);
      }, 500)
    },

    handleSearchAjax: function(request) {
      var search = true;

      // don't search if terms are same
      if (request.term == this.term) {

        search = false;
      }

      // don't search if new term is longer with same root
      if (this.term && request.term.length > this.term.length && request.term.indexOf(this.term) > -1) {

        search = false;
      }

      if (request.term.length < 3) {

        this.setChosenText(this.search_text);
      }

      if (request.term.length < 2) {

        search = false;
      } else {

        this.setChosenText(this.empty_text);
      }

      if (search) {

        this.ajax_searching = true;

        this.term = request.term;

        var filter = {
          field: this.identifier,
          operator: 'contains',
          value: request.term
        };

        this.fetch_choices(filter);
      } else {

        this.ajax_searching = false;

        this.$elem.find('.ui-autocomplete-input').removeClass('ui-autocomplete-loading');
      }
    },

    handleClickAddOption: function(event) {
      event.preventDefault();
      event.stopImmediatePropagation();

      var _this = this;
      var $input = $(event.currentTarget).closest('.kn-input');

      // requires
      var FormView = require('core/backbone/views/FormView');
      var Form = require('core/backbone/models/Form');

      // model
      var current_scene_key = Knack.router.current_scene_key || Knack.scenes.getBySlug(Knack.hash_parts[0]).get('key');
      var current_scene = Knack.scenes.getByKey(current_scene_key);

      // check current active scene OR this may be on the parent or grandparent login scene
      let view_model = current_scene.views.get(this.options.input.view) || Knack.scenes.findViewBySceneKey(this.options.input.view, current_scene_key)

      if (!view_model) {

        return $.utility_forms.renderMessage($(event.currentTarget).closest('.kn-form'), '<p>Error adding new option.</p>', 'error')
      }

      var form_model = new Form();

      // render model
      var description = '';
      var title = view_model.get('title') || '&nbsp;';

      if (view_model.get('description')) {

        description = '<p>' + view_model.get('description') + '</p>';
      }

      if (Knack.getStyleEngine() === 'v2') {

        var html = '<header class="modal-card-head"><h1 class="modal-card-title">' + title + '</h1><button class="delete close-modal"></button></header><section class="modal-card-body">' + description + '<div class="kn-form kn-view" id="connection-form-view"></div></section>';
      } else {

        var html = '<h1>' + title + '<span class="close-modal">' + Knack.trans('close') + '</span></h1><div class="kn-modal-wrapper">' + description + '<div class="kn-form kn-view" id="connection-form-view"></div></div>';
      }

      Knack.renderModal(html);

      // form view
      form_model.setView(view_model.attributes);

      var form_view = new FormView({
        model: form_model,
        el: '#connection-form-view'
      });

      form_view.submit_options = {
        success: function(model, result) {

          var record = result.record;

          // add to options
          var record = {
            id: record.id,
            identifier: record[_this.identifier]
          };

          _this.available_entries.push(record);
          _this.current_entries.push(record);

          // add to selected
          _this.values[record.id] = record.identifier;

          _this.loadOptions(_this.current_entries);

          Knack.closeModal();
        }
      };

      form_view.render();

      $('#connection-form-view').find('.view-header').remove();

      form_view.postRender();
    },

    fetch_choices: function(filter, count_only) {

      var _this = this;

      return new Promise(function (resolve, reject) {

        var params = {
          rows_per_page: 2000
        };

        var connection_choices_model = new Backbone.Model();

        // check for normal builder connection lookups in data tab, and within rules
        if (Knack.mode === 'builder' && (_this.options.fake_view_key || (!_this.options.view_key || _this.options.view_key.indexOf('view_') === -1))) {

          connection_choices_model.url = Knack.api + '/connections/' + _this.object_key;
        } else {

          var scene = Knack.scenes.getBySlug(Knack.getCurrentScene().slug);

          // we are previewing a scene in the builder, need to set its scene differently
          if (!scene && Knack.mode === 'builder') {

            // in builder, Knack.getCurrentScene().slug returns a key...
            scene = Knack.scenes.getByKey(Knack.getCurrentScene().slug);
          } else if (scene.get('authenticated')) { // if we have a scene requiring authentication, we need to make sure we are actually on that scene and not a login/registration:

            var page_role_keys = scene.get('authentication_profiles');

            if (page_role_keys && page_role_keys.length) {

              if (!Knack.User.hasPermissions(page_role_keys)) {

                // get the parent login scene if we lack permissions to the child scene
                scene = Knack.scenes.getBySlug(scene.get('parent'));
              }
            }
          }

          var expected_view_key = _this.options.view_key;

          // if we are using the cell editor in an inline edit, we need to remove everything but the view key... from the view key
          if (expected_view_key && expected_view_key.indexOf('_celleditor') > -1) {

            expected_view_key = expected_view_key.split('_celleditor')[0];
          }

          var view = _.find(scene.get('views'), function(possible_view) {

            return possible_view.key === expected_view_key;
          });

          // we can't find the view on the current scene. walk up the scene list to the parent login view, and see if the view we want exists there
          if (!view) {

            let reachedParent = false

            while (!reachedParent && !view) {

              const parentSlug = scene.get(`parent`)

              if (!parentSlug) {

                reachedParent = true

                break
              }

              scene = Knack.scenes.getBySlug(parentSlug)

              view = _.find(scene.get(`views`), view => view.key === expected_view_key)
            }
          }

          if (!view) {
            return _this.handleEntriesLoaded([]);
          }

          connection_choices_model.url = Knack.api_dev + '/scenes/' + scene.get('key') + '/views/' + view.key + '/connections/' + _this.options.input.id;
        }

        var filters = _this.getFilters();

        // ensure that the original, passed in filter is also appended to preset filters. this is used by ajax searches
        if (filter) {

          if (!filters) {

            filters = [];
          }

          filters.push(filter);
        }

        if (filters) {

          params.filters = JSON.stringify(filters);
        }

        // this instructs the query to convert this to ajax if there are more than 500 records
        if (!_this.ajax && _this.field_type === 'text') {

          params.limit_return = true;
        }

        if (_this.options.input.set_by_url_var && _this.ajax) {

          params.search_by_id = _this.options.input.set_by_url_var[0]
        }

        params = $.param(params);

        log('params before fetching connection choices')
        log(params)

        connection_choices_model.url += '?' + params;

        return connection_choices_model.fetch({
          success: function(model) {

            log('result from fetching connection choices')
            log(model)

            if (count_only) {

              return resolve(model);
            }

            if (_this.options.input.set_by_url_var && _this.ajax) {

              model.attributes.identifier = model.attributes[_this.identifier]

              model.attributes = {
                records: [model.attributes]
              }

              delete _this.options.input.set_by_url_var
              delete _this.term
            }

            return _this.handleEntriesLoaded(model.attributes);
          }
        });
      });
    },

    fetch_count: async function(filter) {
      var _this = this;
      let params = {};
      var connection_count_model = new Backbone.Model();

      // check for normal builder connection lookups in data tab, and within rules
      if (Knack.mode === 'builder' && (_this.options.fake_view_key || (!_this.options.view_key || _this.options.view_key.indexOf('view_') === -1))) {
        return null;
      } else {
        var scene = Knack.scenes.getBySlug(Knack.getCurrentScene().slug);
        if (!scene && Knack.mode === 'builder') {
          scene = Knack.scenes.getByKey(Knack.getCurrentScene().slug);
        } else if (scene.get('authenticated')) { // if we have a scene requiring authentication, we need to make sure we are actually on that scene and not a login/registration:
          var page_role_keys = scene.get('authentication_profiles');
          if (page_role_keys && page_role_keys.length) {
            if (!Knack.User.hasPermissions(page_role_keys)) {
              // get the parent login scene if we lack permissions to the child scene
              scene = Knack.scenes.getBySlug(scene.get('parent'));
            }
          }
        }

        var expected_view_key = _this.options.view_key;

        // if we are using the cell editor in an inline edit, we need to remove everything but the view key... from the view key
        if (expected_view_key && expected_view_key.indexOf('_celleditor') > -1) {
          expected_view_key = expected_view_key.split('_celleditor')[0];
        }

        var view = _.find(scene.get('views'), function(possible_view) {
          return possible_view.key === expected_view_key;
        });

        // we can't find the view on the current scene. walk up the scene list to the parent login view, and see if the view we want exists there
        if (!view) {
          let reachedParent = false
          while (!reachedParent && !view) {
            const parentSlug = scene.get(`parent`)
            if (!parentSlug) {
              reachedParent = true
              break
            }
            scene = Knack.scenes.getBySlug(parentSlug)
            view = _.find(scene.get(`views`), view => view.key === expected_view_key)
          }
        }
        connection_count_model.url = Knack.api_dev + '/scenes/' + scene.get('key') + '/views/' + view.key + '/connections/' + _this.options.input.id + '/count';
      }

      var filters = _this.getFilters();
      if (filter) {
        if (!filters) {
          filters = [];
        }
        filters.push(filter);
      }
      if (filters) {
        params.filters = JSON.stringify(filters);
      }

      params = $.param(params);
      connection_count_model.url += '?' + params;

      return new Promise((resolve, reject) => {
        connection_count_model.fetch({
          success: function(model) {
            log('result from fetching connection choices');
            log(model);
            resolve(model.attributes.total_records);
            return;
          },
          error: function(error) {
            reject(error);
          }
        });
      });
    },

    getFilters: function() {

      var _this = this;

      var filters = [];

      // if we're in the builder we don't care about these
      if (Knack.mode == 'builder') {
        return filters;
      }

      // permanent filters? (connected parent)
      if (this.filter) {
        filters.push(this.filter);
      }

      if (this.options.filters) {
        filters = filters.concat(this.options.filters);
      }

      // check source for filtering options
      // source {filters:[{field:'',operator:'is',value:'Yes'}]}
      if (this.options.input && this.options.input.source) {

        // filters
        if (this.options.input.source.filters) {
          filters = filters.concat(this.options.input.source.filters);
        }

        if (this.options.input.source.connection_key) {

          var conn = Knack.fields[this.options.input.source.connection_key];
          var obj_key = (conn.get('relationship').object == this.options.input.field.relationship.object) ? conn.get('object_key') : conn.get('relationship').object;

          // check if a key for this obect exists in the hash scenes
          var scenes = Knack.getHashScenes();
          var scene = _.find(scenes, function(scene) {
            // log('checking scene: ' + scene.slug + '; object: ' + Knack.scenes.getBySlug(scene.slug).get('object') + '; object_key: ' + obj_key );
            if (scene && scene.slug && Knack.scenes.getBySlug(scene.slug)) {
              return Knack.scenes.getBySlug(scene.slug).get('object') == obj_key;
            }
            // if (conn.get('relationship').belongs_to == 'one') return Knack.scenes.getBySlug(scene.slug).get('object') == conn.get('relationship').object;
            // else if (conn.get('relationship').has == 'one') return Knack.scenes.getBySlug(scene.slug).get('object') == conn.get('object_key');
          });

          // we have a scene object
          if (scene) {
            value = scene.key;
            var operator = (conn.get('object_key') == this.options.input.field.relationship.object) ? 'is' : 'connection';
            filters.push({
              field: this.options.input.source.connection_key,
              operator: operator,
              value: value
            });

            // check for user connection
          } else if (this.options.input.source.type == 'user') {

            filters.push({
              field: this.options.input.source.connection_key,
              operator: 'from user',
              source: this.options.input.source,
              parent_source: this.options.form_source
            });

            // if edit then use connected record
          } else if (this.options.input.source.type == 'record') {

            filters.push({
              field: this.options.input.source.connection_key,
              operator: 'from record',
              source: this.options.input.source,
              value: this.options.record_id
            });

            // check for remote connection
          } else if (this.options.input.source.remote_key && this.options.input.source.remote_key != 'null') {

            conn = Knack.fields[this.options.input.source.remote_key];
            obj_key = (conn.get('relationship').object == this.options.input.field.relationship.object) ? conn.get('object_key') : conn.get('relationship').object;
            scene = _.find(scenes, function(scene) {
              return Knack.scenes.getBySlug(scene.slug).get('object') == obj_key;
            });

            if (scene) {
              value = scene.key;
              var remote_object_key = Knack.fields[this.options.input.source.remote_key].get('relationship').object;
              var remote_key;

              // this remote_key lookup could be wrong if there is more than one field connected to the desired object.
              _.each(Knack.objects.get(remote_object_key).get('conns'), function(field) {
                if (field.object === _this.options.input.field.relationship.object) {
                  remote_key = field.key;
                }
              });

              filters.push({
                field: remote_key,
                operator: 'connection',
                value: value
              });
            } else {

              filters.push({
                field: this.options.input.source.connection_key,
                operator: 'from user',
                source: this.options.input.source,
                parent_source: this.options.form_source
              });
            }

            // if edit then use connected record
          } else if (this.options.form_action == 'update') {

            filters.push({
              field: this.options.input.source.connection_key,
              operator: 'from record',
              source: this.options.input.source,
              value: this.options.record_id
            });
          }
        }

      }

      return filters;
    },

    /**
     * Translates the parent id for a connection field into its secure record id equivalent.
     *
     * @param {string | string[]} parentId
     * @returns {string | string[]}
     */
    getSecureParentConnectionId: function (parentId) {
      if (!parentId) {
        return parentId;
      }

      // Get the jquery element for the connection picker of the connected field (the parent).
      const $parent = this.getParent();
      if (!$parent) {
        return parentId;
      }

      // Get the connection picker object from the parent jquery object.
      const parentConnectionPicker = $parent.data('connectionPicker');

      // Available entries should have been populated during the init of the connection picker
      // when it calls fetch_choices to get the secure record ids.
      if (!Array.isArray(parentConnectionPicker.available_entries) || parentConnectionPicker.available_entries.length === 0) {
        return parentId;
      }

      return parentId;
    },

    loadConnectedOptions: function(conn, parent_id, runAjax) {
      log(`parent id is ${parent_id} and runAjax is ${runAjax}`)

      if (this.ajax || runAjax) {

        const secureParentId = this.getSecureParentConnectionId(parent_id);

        if (secureParentId) {

          this.term = '';

          var f = Knack.fields[conn.field.key];

          // it's a foreign connection
          if (f.get('object_key') !== this.object_key) {
            this.filter = {
              field: conn.field.key,
              operator: 'connection', // simulate a page parent solr filter
              value: secureParentId
            };

          }

          // local connection, treat like a regular filter!
          else {

            // set filter for future choices
            var operator = (_.isArray(secureParentId)) ? 'in' : 'is';
            this.filter = {
              field: conn.field.key,
              operator: operator,
              value: secureParentId
            };

          }

          return this.fetch_choices()
        }

        return this.loadOptions();
      }

      var records = [];

      _.each(this.available_entries, function(record) {
        record[conn.field.key + '_raw'] = parent_id;
        records.push(record);
      }, this);

      return this.loadOptions(records)
    },

    handleEntriesLoaded: function(result) {
      var type = Knack.fields[this.identifier].get('type');

      if (result && result.records) {

        this.available_entries = result.records;
      }

      // check source
      if (!this.ajax) {
        if (this.options.input && this.options.input.source && this.options.input.source.connections && result && result.total_records <= this.ajax_trigger_amount) {
          var conn = this.options.input.source.connections[0];

          if (conn.source && conn.source.type == 'input') {

            // change function based on other inputs
            var $parent = this.getParent(); //$('#kn-input-' + conn.source.field.key);
            var parent_id = this.getParentId(); //$parent.find('select').val();

            if (parent_id) {
              this.loadConnectedOptions(conn, parent_id);
              this.triggerLoad();
              return;
            }
          }
        }

      }

      //If connpicker is filtered and we get here, and result.totalrecords is < 500, it's
      //Important that we still render as ajax if the actual filtered_count > 500.
      //We also need to get result.total_records due to a bug wherein filtered_count is wrong.
      //Total records might actually be > 500 while filtered_count < 500
      if (!this.ajax && this.field_type == 'text' && result.total_records && result.total_records > this.ajax_trigger_amount && this.identifierFieldTypesSupportingAjax.has(type)) {
        this.ajax = this.identifierFieldTypesSupportingAjax.has(type);
        this.renderAjax();
      } else if (!this.ajax && this.field_type == 'text' && this.filteredCount && this.filteredCount > this.ajax_trigger_amount && this.identifierFieldTypesSupportingAjax.has(type)) {
        this.ajax = this.identifierFieldTypesSupportingAjax.has(type);
        this.renderAjax();
      } else {

        // otherwise load
        this.loadOptions(result.records);
        this.triggerLoad();

        if (this.ajax) {

          let $autocomplete = this.$elem.find('.ui-autocomplete-input')

          $autocomplete.removeClass('ui-autocomplete-loading');
        }

      }

    },

    triggerLoad: function() {
      // trigger
      if (this.options.view_key) {
        $(document).trigger('knack-connections-load.' + this.options.view_key);
      }

      // trigger any parent wrapper. Builder views use this to make sure all the connections have loaded
      if (this.$elem.closest('*[data-connection-event]').length) {
        var event = this.$elem.closest('*[data-connection-event]').attr('data-connection-event');

        $(document).trigger('knack-connections-load.' + event);
      }
    },

    loadOptions: function(records) {
      this.$elem.find('select').empty();
      this.$elem.find('select').attr('data-placeholder', '');

      // reset current entries
      this.current_entries = [];
      var options = [];

      // set default

      // if (this.options.input && !this.options.input.field.required && !this.multiple) {
      this.options.input || (this.options.input = {
        field: {
        }
      });

      const defaultValue = _.property([ `input`, `format`, `conn_default` ])(this.options)
      let shouldSetDefault = _.isEmpty(this.values)

      const isSearchConnectionInputWithEmptyDefault = this.options.view_type === 'search' && this.options.input.field.type === 'connection' && _.isEmpty(this.values);
      const isSearchConnectionChosenInputWithEmptyDefault = isSearchConnectionInputWithEmptyDefault && this.input_type === 'chosen';

      if (((
            this.options.form_action == 'insert' ||
            this.options.form_action == 'create' ||
            this.options.form_action == 'update' ||
            !this.options.input.field.required
          )
          // The is_parent_connected portion is for cascading dropdowns. We want the default blank value to show up first
          // There is still a bug w/ cascading drop down where other items in the child drop down will still show up before a parent option has been selected
          && this.input_type == 'chosen' && (this.default_type == 'none' || (this.is_parent_connected && !this.getParentId())) && !this.multiple) || isSearchConnectionChosenInputWithEmptyDefault) {

        options.push({
          value: '',
          label: this.default_text
        });
      }

      _.each(records, function(record) {

        // Don't push a record if the response is undefined
        if (_.isUndefined(record.id) || _.isNull(record.id)) {

          return
        }

        // This is a complete hack that prevents child connection pickers from being pre-populated
        // with values when their parent picker hasn't had a select made yet
        // Ideally we would do this way earlier to avoid even making the fetch_choices call
        // but the code is so gnarly that I couldn't figure out how to do that
        // Since the code was already making these calls, performance doesn't change but could be improved in future
        if (this.is_parent_connected && !this.getParentId()) {
          return;
        }

        if (!isSearchConnectionInputWithEmptyDefault && shouldSetDefault && defaultValue === `first`) {

          this.values[record.id] = record.identifier
          shouldSetDefault = false
        }

        var selected = (this.values[record.id]) ? ' selected' : '';

        // options += '<option value="' + record.id + '"' + selected + '>' + record.identifier + '</option>';
        options.push({
          value: record.id,
          label: record.identifier,
          selected: selected
        });

        // add to current entries
        this.current_entries.push(record);
      }, this);

      // if ajax, let's make sure we add any existing options to the bottom of the list so they stay selected
      var vals = [];

      // This is the scenario that can trigger the need to make an additional DB call.
      // The connection picker is setup for ajax searches,
      // so it's possible the active values aren't being returned from the DB.
      if (this.values && (!this.is_parent_connected || (this.is_parent_connected && !this.has_parent_id_changed))) {
        var options_str = JSON.stringify(options);

        vals = _.pairs(this.values);

        _.each(vals, function(val) {

          if (val[0] && val[1]) {

            // check if this option is already added.
            if (options_str.indexOf(val[0]) === -1) {

              if (this.options.input.set_by_url_var) {

                let is_in_records = _.find(records, function(record) {

                  return record.id === val[0];
                })

                if (!is_in_records && records && !this.hasLoadedOptions) {

                  return
                }
              }

              // Here we're adding options that can serve as parent records.
              // We're pushing directly from the values because they don't exist from the DB.
              // That means they don't have security IDs and aren't represented in this.available_entries
              options.push({
                value: val[0],
                label: val[1],
                selected: ' selected'
              });

            }
          }
        }, this);
      }

      if (this.is_parent_connected) {

        this.has_parent_id_changed = false;
      }

      // if no options and ajax
      if (!options.length && !this.multiple) {
        // options = '<option value="">' + this.default_text + '</option>';
        options.push({
          value: '',
          label: this.default_text
        });
      }

      // CHOSEN
      if (this.input_type == 'chosen') {
        this.loadChosenOptions(options);
      } else {
        this.loadInputOptions(options);
      }

      this.hasLoadedOptions = true

      //
    },

    loadChosenOptions: function(values) {
      var options = '';

      _.each(values, function(val) {

        var selected = (val.selected) ? ' selected' : '';

        options += '<option value="' + val.value + '"' + selected + '>' + val.label + '</option>';
      });

      if (this.ajax) {

        this.setChosenText(this.empty_text);
      }

      this.$elem.find('select').append(options);

      this.$elem.find('.chzn-select').trigger('liszt:updated');

      if (!this.values.length) {

        this.$elem.find('.default').val(Knack.trans('Select') + '...');
      }

      this.$elem.find('select').trigger('change');

      if (this.ajax && this.term) {

        if (this.multiple) {

          this.$elem.find('.chzn-choices input').val(this.term);
        } else {

          this.$elem.find('.chzn-search input').val(this.term);
          this.$elem.find('.chzn-search input').keyup();
          this.$elem.find('.chzn-search input').change();
        }
      }

      if (this.ajax && this.multiple && !this.term && !this.has_values) {

        this.$elem.find('.chzn-choices input').val(this.init_text);
      }
    },

    loadInputOptions: function(values) {
      var _this = this;
      let $checkbox = this.$elem.find(`#connection-picker-${this.input_type}-${this.field_key} label:first`)

      // Previous versions of connection-picker relied on an empty Select checkbox option which is no longer a thing
      // for insert forms when dealing with cascading pickers of any type
      // so as a hack we're building up the $checkbox that will be cloned for each value in values argument later
      // when there was no initial label (option) from the find() above
      if ($checkbox.length > 0) {

        $checkbox = $checkbox.detach();
      } else {

        $checkbox = $(
          `<label class="option">
            <input name="${this.field_key}" data-field-key="${this.field_key}" type="checkbox" value="">&nbsp;
          <span></span></label>`
        );
      }

      this.$elem.find(`#connection-picker-${this.input_type}-${this.field_key}`).empty();

      _.each(values, function(val) {

        var $cb = $checkbox.clone(true);

        $cb.find('input').prop('checked', val.selected);
        $cb.find('input').prop('value', val.value);
        $cb.on('change', function(evt) {
          if (evt.target.checked) {
            if (_this.input_type === 'radio') {
              _this.values = { [val.value]: val.label };
            } else {
              _this.values[val.value] = val.label;
            }
          } else {
            delete _this.values[val.value];
          }
        })
        $cb.find('span').html(val.label);

        if (Knack.getStyleEngine() === 'v2') { // Forms need controls in v2
          $cb.wrap('<div class="control"></div>');
          $cb = $cb.parent();
        }

        _this.$elem.find(`#connection-picker-${_this.input_type}-${_this.field_key}`).append($cb);
      });

      const isSearchConnectionRadioInputWithEmptyDefault = this.options.view_type === 'search' && this.input_type === 'radio' && this.options.input.field.type === 'connection' && _.isEmpty(this.values);

      if (!isSearchConnectionRadioInputWithEmptyDefault && (this.options.form_action === 'insert' || this.options.form_action === 'create' || !this.options.input.field.required) && this.input_type === 'radio' && this.default_type === 'first') {
        this.$elem.find(`#connection-picker-${this.input_type}-${this.field_key} input:first`).prop('checked', true);

        $(document).bind('knack-view-render.' + this.options.view_key, function() {
          _this.$elem.find(`#connection-picker-${this.input_type}-${this.field_key} input:first`).change();
        });
      }

      // trigger change
      setTimeout(() => {

        if (this.input_type === 'radio') {

          return this.$elem.find(':input[name=' + this.options.view_key + '-' + this.field_key + ']').change();
        }

        return this.$elem.find(':input[name=' + this.field_key + ']').change();
      }, 250)
    },

    selectRecordIdentifiers: function(record_ids) {
      // init
      var params = {
        context: this,
        success: this.handleIdentifiersLoaded,
        dataType: 'jsonp',
        crossDomain: true,
        url: Knack.api + '/objects/' + this.object_key + '/identifiers',
        data: {
          ids: record_ids
        }
      };

      $.ajax(params);
    },

    handleIdentifiersLoaded: function(result, success) {
      if (result.records && result.records.length) {

        _.each(result.records, function(record) {

          this.values[record.id] = record.identifier;
        }, this);
      }

      this.loadOptions();
    },

    getValue: function() {
      const values = [];

      if (this.input_type == 'chosen') {
        const $select = this.$elem.find(`#connection-picker-chosen-${this.field_key} > select`);
        values.push((typeof ($select.val()) == 'string') ? [$select.val()] : $select.val());
      } else if (this.input_type == 'radio') {
        values.push(this.$elem.find(':input[type=radio]:checked').val());
      } else {
        this.$elem.find(':input[type=checkbox]').each(function() {
          if ($(this).prop('checked')) {
            values.push($(this).val());
          }
        });
      }

      return values;
    },

    getValueWithIdentifiers: function() {
      return this.getValue().flat().map((value) => {
        if (typeof value === 'string' && value !== '') {
          return { id: value, identifier: this.values[value] };
        } else if (value?.id) {
          return {
            id: value.id,
            identifier: value.identifier || this.values[value.id],
          };
        }

        return '';
      }).filter((value) => !!value);
    },
  };

  $.fn.extend({
    connectionPicker: function(options) {
      return this.each(function() {
        // create object, init, and add bridge
        var myConnectionPicker = Object.create(connectionPicker);
        myConnectionPicker.init(options, this);
        $(this).data('connectionPicker', myConnectionPicker);

        return this;
      });
    }
  });

});
