define([
  'jquery', 'backbone', 'underscore'
], function($, Backbone, _) {
  return Backbone.Model.extend({
    view: null,
    form_object: null,
    dirty: null, // just save these attributes
    type: 'entry', // entry or user
    setView: function(view) {

      this.view = view;
      this.form_object = (this.view.source) ? Knack.objects.get(this.view.source.object) : null;

      // reset inputs into groups
      if (!this.view.groups) {

        this.view.groups = [
          {
            columns: [
              {
                inputs: []
              }
            ]
          }
        ];

        if (this.view.inputs) {

          _.each(this.view.inputs, function(input) {

            this.view.groups[0].columns[0].inputs.push(input);
          }, this);
        }
      }

      this.initInputs();

      return this.setAPI();
    },
    // set input values and form types
    initInputs: function() {

      var model = this;
      var view = this.view;

      // check for ULR vars
      try {

        var url_vars = Knack.getHashVar(this.view.key + '_vars');

        if (url_vars) {

          url_vars = $.parseJSON(decodeURIComponent(url_vars));
        } // this was stringified if in the URL
      } catch (err) {

        url_vars = false;

        console.log('URL Vars for view: ' + this.view.key + ' failed to parse correctly. Please encode vars in valid JSON, like wrapping all strings and field names in double quotes.');
      }

      // process each input
      _.each(this.view.groups, function(group) {

        _.each(group.columns, function(col) {

          _.each(col.inputs, function(input) {

            // process input
            input = this.processInput(input);

            // defaults!
            if (input.field && (view.action == 'create' || view.action == 'insert')) {

              var update = {
              };

              // URLS vars
              if (url_vars && url_vars[input.field.key]) {

                update[input.field.key] = url_vars[input.field.key];
                input.set_by_url_var = url_vars[input.field.key]
              // other default options
              } else if (input.field.format && typeof input.field.format.default != 'undefined') {

                update[input.field.key] = input.field.format.default;
              } else if (typeof input.field.default != 'undefined') {

                update[input.field.key] = input.field.default;
              } else if (typeof input.default != 'undefined') {

                update[input.field.key] = input.default;
              }

              // set the default as the link URL unless it's already set
              if (update[input.field.key] && input.field.type == 'link' && !update[input.field.key].url) {

                var val = update[input.field.key];

                update[input.field.key] = {
                  label: '',
                  url: val
                };
              }

              // set the default as the email URL unless it's already set
              if (update[input.field.key] && input.field.type == 'email' && !update[input.field.key].email) {

                var val = update[input.field.key];

                update[input.field.key] = {
                  email: val
                };
              }

              // updat the mdoel and input value
              if (typeof update[input.field.key] != 'undefined') {

                model.set(update);
                input.value = update[input.field.key];
              }
            }
          }, this);
        }, this);
      }, this);
    },

    setAPI: function() {
      const recordId = this.get('id');

      if (this.type == 'user') {

        this.url = Knack.api_dev + '/session';
      // registration form doesn't have a source
      } else if (this.view.source) {

        if (Knack.mode == 'builder') {

          var current_scene = Knack.router.current_scene;
        } else {

          var current_scene = Knack.scenes.getBySlug(Knack.getCurrentScene().slug).get('key');
        }

        this.url = Knack.api_dev + '/scenes/' + current_scene + '/views/' + this.view.key;

        if (this.type == 'user_registration') {

          this.url += '/profiles/' + this.view.source.object;
        }

        this.url += '/records';

        // this form is updating a record, add the record ID (from the URL hash)
        if (this.view.action == 'update' || !this.isNew()) {

          if (this.view.source.authenticated_user) {

            // do we have a record for this user?
            if (recordId) {

              this.url += '/' + recordId;
            } else {

              //this.url += '/';
            }
          } else {

            this.url += '/' + (recordId || Knack.hash_id);
          }
        }

        this.url += '/?format=both';
      } else if (this.view.object) {

        this.url = Knack.api_dev + '/objects/' + this.view.object + '/records';

        if (recordId) {

          this.url += '/' + recordId;
        }
      }
    },
    parse: function(result) {

      if (result.records) {

        // if empty, this is likely a "to-one" connection that hasn't been entered yet.  so let's trigger that
        if (!result.records[0]) {

          if (result.recast) {

            this.trigger('recast-insert');
          }

          return null;
        }

        return result.records[0];
      } else if (result.record) {

        return result.record;
      }

      return result;
    },
    addInput: function(input) {

      input = this.processInput(input);
      //this.view.inputs.push(input);
      this.view.groups[this.view.groups.length-1].columns[this.view.groups[this.view.groups.length-1].columns.length-1].inputs.push(input);

      return input;
    },
    processInput: function(input) {

      if (!input) {

        return;
      }

      if (!input.format) {

        input.format = {
        };
      }

      // override input formats with whatever is in the field's format, if present
      var key = (input.field && input.field.key) ? input.field.key : input.key;

      if (this.form_object && this.form_object.fields.get(key)) {

        var field = this.form_object.fields.get(key).toJSON();

        _.extend(input.format, field.format);

        input.field = field;
        input.name || (input.name = input.field.name);
        input.type = field.type;
      } else {

        input.type = (input.input_type || input.type);
      }

      if (input.type == 'user_roles') {

        // get profiles
        var profile_options = [];

        Knack.objects.each(function(obj) {

          if (obj.get('profile_key') && obj.get('profile_key') != 'all_users') {

            profile_options.push({
              'id': obj.get('profile_key'),
              'identifier': obj.get('name')
            });
          }
        });

        var default_profile = Knack.objects.get(this.view.source.object);

        if (profile_options.length) {

          //  add profile to the inputs
          input = _.extend(input, {
            type: 'multiple_choice',
            format: {
              type: 'multi',
              options: profile_options
            },
            default: [default_profile.get('profile_key')]
          });
        } else {
          // There aren't any user role options available to select from
          // Do not display an input nor a corresponding label in the UI
          input.type = 'skip';
          input.label = '';
        }
      }

      // set input type
      var type = '';

      switch (input.type) {

        case 'short_text': case 'number': case 'currency':

          type = 'text_input';
          break;
        case 'paragraph_text':

          type = 'text_area';
          break;
        case 'combo_box': case 'drop_down':

          type = 'multiple_choice';
          break;
        default:

          type = input.type;
          break;
      }

      input.input_type = type;

      // set type="none" to skip
      if (Knack.config.fields[input.type] && Knack.config.fields[input.type].input_type == 'none') {

        input.input_type = 'skip';
      }

      // reset input
      return input;
    },
    save: function(attrs, options) {

      // reset API
      this.setAPI(); // why is this here?

      if (attrs) {

        this.dirty = attrs;
      }

      Backbone.Model.prototype.save.call(this, attrs, options);
    },
    toJSON: function() {

      if (this.dirty) {

        var json = _.clone(this.dirty);
        this.dirty = null;
        return json;
      }

      return _.clone(this.attributes);
    }
  });
});
